import React, { useMemo, useState } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import Input from "../../common/Input";
import { SimpleTextError } from "./SimpleTextError";

export const PrescriptionNameInput = (props) => {
  const label = useTranslate(
    "steps.advancedPrescription.fromMyAccount.inputPrescriptionName"
  );

  const maxLetters = 35;

  const onChange = (e) => {
    let value = e.target.value;
    //delete all case
    if (value === "") {
      props.onChange(value);
      return;
    }
    if ([undefined, null].includes(value)) return;

    //Block more than one consecutive space
    if (/\s\s+/.test(value)) return;

    //Count the number of letters
    const letterCount = value.replace(/\s/g, "").length;
    if (letterCount > maxLetters) return;

    props.onChange(value);
  };

  return (
    <div className="PrescriptionNameInput">
      <Input
        {...props}
        onChange={onChange}
        placeholder=""
        label={
          <div>
            <span style={props.labelStyle}>{label}</span>
          </div>
        }
        type="text"
      />
    </div>
  );
};
