import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import getCookieByName from "../utils/getCookieByName";
import { useCurrentType } from "./useCurrentType";
import { useTranslate, useTranslation } from "../translations/TranslationHooks";
import { useCurrentBrand } from "./useCurrentBrand";

export function useLargeIcons() {
  const largeIconsCookie = getCookieByName("enableLargeIcons", true);
  const largeIconsConfig = useSelector(
    (state: any) => state.config?.layoutSettings?.enableLargeIcons
  );

  const content = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const currentType = useCurrentType();

  const translate = useTranslation();

  const imageFileName1 = translate("steps.treatmentsFamily.sun.imageFileName1");
  const imageFileName2 = translate("steps.treatmentsFamily.sun.imageFileName2");

  const currentStep = useSelector((state: any) => state.workflow?.currentStep.key);
  const brand = useCurrentBrand();

  const isLargeIconsActive = useMemo(() => {
    if (![null, undefined].includes(largeIconsCookie)) {
      return largeIconsCookie as boolean;
    }
    if (![null, undefined].includes(largeIconsConfig)) {
      return largeIconsConfig as boolean;
    }
    return false;
  }, [largeIconsCookie, largeIconsConfig]);

  const getIcons = useCallback(
    (id) => {
      const allContents = Object.entries<{ [prop: string]: any }>(
        content
      ).reduce((acc, [step, props]) => {
        if ((brand === "opsm" && currentStep === "AddOns") || (brand === "oakley" && currentStep === "Thickness")) {
          if (props) {
            // check for duplicates
            if (Object.keys(props).find((prop) => acc[prop])) {
              return { ...acc };
            }
          }
        }
        return { ...acc, ...props };
      }, {});

      //Clear case
      if (id.includes("clear"))
        return [
          "rxc-icon-treatments-clear.png",
          "rxc-icon-3d-treatments-clear.apng",
        ];

      //Proceed-as-is case
      if (id.includes("antiReflective$null")) {
        if (currentType === "EYEGLASSES") {
          return [
            "rxc-icon-finishing-proceed_as_is.png",
            "rxc-icon-3d-finishing-proceed_as_is.apng",
          ];
        } else {
          return [
            "rxc-icon-finishing-scratch_resistant_sun",
            "rxc-icon-3d-finishing-scratch_resistant_sun.apng",
          ];
        }
      }
      let info = id.includes("sun")
        ? allContents[id.split("$")[0]]
        : id.includes("transition")
          ? allContents[content["treatmentFamily"]?.imageFileName2]
          : allContents[content["treatmentFamily"]?.imageFileName1]
            ? allContents[content["treatmentFamily"]?.imageFileName1]
            : id.indexOf("$") >= 0
              ? allContents[id.split("$")[1]]
              : allContents[id];

      if (id.includes("sun") && !info) {
        info = {
          imageFileName1: imageFileName1,
          imageFileName2: imageFileName2,
        };
      }
      let contentKeys = Object.keys(content);
      if (contentKeys.find((key: string) => key === "treatmentFamily") || (contentKeys.find((key: string) => key === "treatmentFamily") && (contentKeys.find((key: string) => key === "transition")))) {
        let tKey = content["treatmentFamily"] ? Object.keys(content["treatmentFamily"]) : [];
        const transitions = tKey.find((key: string) => key === "Transitions");
        if (id.includes("transition") && tKey) {
          if (allContents[tKey[0]] && !transitions) {
            info = {
              imageFileName1: allContents[tKey[0]]?.imageFileName1,
              imageFileName2: allContents[tKey[0]]?.imageFileName2,
            };
          } else if (allContents[tKey[0]] && transitions) {
            info = {
              imageFileName1: allContents["Transitions"]?.imageFileName1,
              imageFileName2: allContents["Transitions"]?.imageFileName2,
            };
          }
        }
      }

      if (contentKeys.find((key: string) => key === "transition") && !contentKeys.find((key: string) => key === "treatmentFamily")) {
        let tKey = content["transition"] ? Object.keys(content["transition"]) : [];
        if (id.includes("transition") && tKey && allContents[tKey[0]]) {
          info = {
            imageFileName1: allContents[tKey[0]]?.imageFileName1,
            imageFileName2: allContents[tKey[0]]?.imageFileName2,
          };
        }
      }

      if (!info) return null; //TODO add log
      const images = [];

      if (info.imageFileName1 && info.imageFileName2) {
        // if (isMobile) return [`${info.imageFileName2}.apng`];
        // else {
        return [`${info.imageFileName1}.png`, `${info.imageFileName2}.apng`];
        //}
      } else if (info.imageFileName) {
        return [`${info.imageFileName}.apng`];
      }
      return images;
    },
    [
      content,
      currentType,
      translate(imageFileName1),
      imageFileName1,
      imageFileName2,
    ]
  );

  return {
    isLargeIconsActive,
    getIcons,
  };
}
