import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { formatDate, isMoreThanYear } from "../../utils/date";
import { BrandComponent } from "../common/BrandComponent";
import "./default.module.scss";
import { useCurrentBrand } from "@hooks";
import { Banner } from "./Banner/component";

type Props = {
  prescription: {
    id: string;
    name: string;
    lastUpdate: string;
    expired: boolean;
    issueDate?: string;
    origin?: string;
    default?: boolean;
  };
  onSelect: () => void;
  onShowPrescription: (name: string) => void;
};

export function PrescriptionFromMyAccountCard(props: Props) {
  const prescriptionNameLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.prescriptionName"
  );
  const uploadedOnLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.uploadedOn"
  );
  const showPrescriptionLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.showPrescription"
  );
  const olderThanYearBannerLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.olderThanYearBanner"
  );
  const expiredLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.expired"
  );
  const issueDateLabel = useTranslate(
    "steps.advancedPrescription.fromMyAccount.issueDate"
  );
  const brand = useCurrentBrand();
  return (
    <div
      className={`PrescriptionFromMyAccountCard${props.prescription.expired ? " expired" : ""
        }`}
      onClick={() => !props.prescription.expired && props.onSelect()}
    >
      <BrandComponent
        componentName="LastUpdate"
        path="PrescriptionFromMyAccountCard/LastUpdate"
        parameter={{
          lastUpdate: props.prescription.lastUpdate,
          origin: props.prescription.origin
            ? props.prescription.origin
            : "ONLINE",
        }}
      />
      {props.prescription.expired && <span>{expiredLabel}</span>}
      <p className="PrescriptionFromMyAccountCard__name">
        {prescriptionNameLabel}: <span>{props.prescription.name}</span>
      </p>
      {/* {props.prescription.issueDate ? (
        <span className="PrescriptionFromMyAccountCard__issueDate">
          {issueDateLabel}:{" "}
          {formatDate(new Date(props.prescription.issueDate), "MM.YYYY")}
        </span>
      ) : (
        <BrandComponent
          componentName="LastUpdate"
          path="PrescriptionFromMyAccountCard/LastUpdate"
          parameter={{ lastUpdate: props.prescription.lastUpdate }}
        />
      )} */}
      <p
        className="PrescriptionFromMyAccountCard__showPrescription"
        onClick={
          !props.prescription.expired
            ? (ev) => {
              props.onShowPrescription(props.prescription.name)
              ev.stopPropagation();
            }
            : () => { }
        }
      >
        {showPrescriptionLabel}
      </p>
      {!props.prescription.issueDate &&
        isMoreThanYear(new Date(), new Date(props.prescription.lastUpdate)) && (
          <p className="PrescriptionFromMyAccountCard__olderThanYearBanner">
            {brand === "framesdirect" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_541_22667)">
                  <path
                    d="M8.66766 5.33255H7.33432V10.6659H8.66766V5.33255Z"
                    fill="#DC9F03"
                  />
                  <path
                    d="M8.66766 11.9992H7.33432V13.3326H8.66766V11.9992Z"
                    fill="#DC9F03"
                  />
                  <path
                    d="M15.7643 12.9152L9.78332 1.16689C9.41232 0.438552 8.70666 0.0742188 8.00099 0.0742188C7.29532 0.0742188 6.58966 0.438552 6.21866 1.16689L0.237657 12.9152C-0.48101 14.3266 0.544324 15.9992 2.12832 15.9992H13.874C15.4577 15.9992 16.483 14.3266 15.7643 12.9152ZM14.5457 14.2896C14.4763 14.4029 14.272 14.6659 13.8737 14.6659H2.12832C1.72999 14.6659 1.52566 14.4029 1.45632 14.2896C1.38699 14.1766 1.24532 13.8749 1.42599 13.5202L7.40699 1.77189C7.57432 1.44322 7.87799 1.40755 8.00099 1.40755C8.12399 1.40755 8.42766 1.44289 8.59499 1.77189L14.576 13.5202C14.7567 13.8752 14.615 14.1766 14.5457 14.2896Z"
                    fill="#DC9F03"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_541_22667">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
            {brand === "targetoptical" && (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'>
                <g clip-path='url(%23clip0_1262_4587)'>
                  <path d='M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM8 14.6667C4.324 14.6667 1.33333 11.676 1.33333 8C1.33333 4.324 4.324 1.33333 8 1.33333C11.676 1.33333 14.6667 4.324 14.6667 8C14.6667 11.676 11.676 14.6667 8 14.6667Z' fill='%23222222' />
                  <path d='M8.66667 6.66667H7.33333V12H8.66667V6.66667Z' fill='%23222222' />
                  <path d='M8.66667 4H7.33333V5.33333H8.66667V4Z' fill='%23222222' />
                </g>
                <defs><clipPath id='clip0_1262_4587'><rect width='16' height='16' fill='white' /></clipPath></defs>
              </svg>
            )}
            {olderThanYearBannerLabel}
          </p>
        )}
      <div className="PrescriptionFromMyAccountCard__separator"></div>
      {props.prescription?.default === true && <Banner />}
    </div>
  );
}
