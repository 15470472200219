import axios from "axios";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkGVP, getFrameOnlyKey } from "../ConfigLoader";
import { useCurrentBrand } from "./useCurrentBrand";
import { useCurrentPackages } from "./useCurrentPackages";
import { useCurrentType } from "./useCurrentType";

export function useContextualMessage() {
  const ctxMsgBaseUrl = useSelector(
    (state: any) => state.config?.baseURLs?.contextualMessages
  );
  const language = useSelector(
    (state: any) => state.config?.translation?.language
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const currentBrand = useCurrentBrand();
  const currentType = useCurrentType();
  const currentPackages = useCurrentPackages();
  const [subtitle, setSubtitle] = useState(null);
  const callFallBack = (currentMode: any, brand: any) => {
    let url =
      ctxMsgBaseUrl +
      "/" +
      currentMode +
      "/" +
      brand +
      "/rxc-ctx-msg_en_US.json";
    axios.get(url).then((res) => {
      let data = null;
      let isEyeglasses = currentType === "EYEGLASSES";
      let isGVP = checkGVP(currentPackages);
      if (isEyeglasses && !isGVP) {
        data = res.data.eye;
      } else if (isEyeglasses && isGVP) {
        data = res.data["gvp-eye"];
      } else if (!isEyeglasses && !isGVP) {
        data = res.data.sun;
      } else {
        data = res.data["gvp-sun"];
      }
      setSubtitle(data);
    });
  };
  const getCorrectEnvironment = () => {
    const scripts = Array?.from(document.getElementsByTagName("script"));
    const rxcScript = scripts?.find((script) => {
      return script?.src?.includes("rxc.js");
    });
    const splittedScript = rxcScript?.src.split(/[//]/);
    const env = splittedScript?.[3];
    return env;
  };
  useEffect(() => {
    if (
      currentStep &&
      currentStep.progressive === 1 &&
      currentBrand &&
      currentType &&
      currentPackages &&
      ctxMsgBaseUrl &&
      language
    ) {
      if (process.env.DEV) return;
      // let currentMode = process.env.CURRENT_MODE;
      // if (currentMode === "development") currentMode = "dev";
      // else if (currentMode === "standalone" || currentMode === "uat")
      //   currentMode = "test";
      // else if (currentMode === "production") currentMode = "prod";
      let currentMode = getCorrectEnvironment()
      let brand = currentBrand === "default" ? "lenscrafters" : currentBrand;
      let url =
        ctxMsgBaseUrl +
        "/" +
        currentMode +
        "/" +
        brand +
        "/rxc-ctx-msg_" +
        language +
        ".json";
      axios
        .get(url)
        .then((res) => {
          let data = null;
          let isEyeglasses = currentType === "EYEGLASSES";
          let isGVP = checkGVP(currentPackages);
          if (isEyeglasses && !isGVP) {
            data = res.data.eye;
          } else if (isEyeglasses && isGVP) {
            data = res.data["gvp-eye"];
          } else if (!isEyeglasses && !isGVP) {
            data = res.data.sun;
          } else {
            data = res.data["gvp-sun"];
          }
          setSubtitle(data);
        })
        .catch((err) => {
          console.error("Error while retreiving contextual message: " + err);
          callFallBack(currentMode, brand);
        });
    }
  }, [
    currentStep,
    currentBrand,
    currentType,
    currentPackages,
    ctxMsgBaseUrl,
    language,
  ]);
  return subtitle;
}
