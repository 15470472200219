import { isNull, isNumber, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WarrantyOptionConfigModel } from "../../components/WarrantyOptions/component";
import { parameterToPlainAdapter } from "../adapters/ParameterToPlainAdapter";
import {
  checkGVP,
  filterExistingProperties,
  getCheapPackageFromList,
  isBundlePriceEnabled,
} from "../ConfigLoader";
import { workflow } from "../models/Workflow";
import { useCurrentPackages } from "./useCurrentPackages";
import { useProtectionPlan } from "./useProtectionPlan";
import { useBundlesPrices } from "./useBundlesPrices";
import { useShowFramePlusLensesPrice } from "./useShowFramePlusLensesPrice";
import { usePrismPrice } from "./usePrismPrice";
import { useEnablePrism } from "./useEnablePrism";
import { useCurrentBrand, useCurrentType } from "@hooks";

function useNonGVPTotalPrices() {
  const currentPackages = useCurrentPackages();
  const configFrame = useSelector((state: any) => state.config?.data?.frame);
  const currentTreatments = useSelector(
    (state: any) => state.workflow?.currentTreatments
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const protectionPlan = useProtectionPlan();
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const additionalParameters = useSelector(
    (state: any) => state.workflow?.additionalParameters
  );
  const cartMode = useSelector((state: any) => state.config?.cartMode);
  const warrantyByCart = useSelector(
    (state: any) => state.config?.data?.warranty?.catEntryId
  );
  const warrantyOptions: WarrantyOptionConfigModel[] = useSelector(
    (state: any) => state.config?.lensesData?.warrantyOptions
  );
  const bundlesEnabled = useBundlesPrices();
  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();

  const brand = useCurrentBrand();

  const castNumber = (p: string | number) => {
    let pNumber = null;

    if (!isNumber(p)) {
      if (!p) {
        pNumber = null;
      } else {
        pNumber = parseFloat(p.toString());
      }
    } else {
      pNumber = p;
    }
    return pNumber;
  };

  const getPrices = (
    list: string | number,
    offer: string | number,
    ins: string | number,
    defaultList?: string | number
  ) => {
    let listPrice = castNumber(list);
    let offerPrice = castNumber(offer);
    let insPrice = castNumber(ins);

    if (isUndefined(offerPrice) || isNull(offerPrice)) {
      let def = castNumber(defaultList);
      if (isUndefined(def) || isNull(def)) {
        offerPrice = 0;
      } else {
        offerPrice = def;
      }
    }

    if (isUndefined(listPrice) || isNull(listPrice)) {
      listPrice = 0;
    }

    /*  if (isUndefined(insPrice) || isNull(insPrice)) {
              insPrice = 0;
          } */

    return {
      price: listPrice,
      discount: listPrice - offerPrice,
      insPrice: insPrice,
    };
  };

  const getDiscountInsurance = (
    listPrice: number,
    discount: number,
    insPrice: number
  ) => {
    let discountInsurance = 0;

    if (insPrice) {
      discountInsurance = listPrice - insPrice;
    }
    return discountInsurance;
  };

  const [priceObj, setPriceObj] = useState({
    frame: getPrices(configFrame?.listPrice, configFrame?.offerPrice, 0),
    lens: { price: 0, discount: 0, insPrice: 0 },
    total: getPrices(configFrame?.listPrice, configFrame?.offerPrice, 0),
  });

  useEffect(() => {
    let packages = currentPackages;

    if (currentStep && currentTreatments) {
      let params = {};
      //CM fix set treatment family back
      //filter by treatment if navigation is > of treatment step
      if (
        currentStep.progressive >
        workflow.findByStepKey("TreatmentsFamily").progressive
      ) {
        currentTreatments.forEach((p: any) => {
          let mapped = parameterToPlainAdapter.adapt(p);
          if (mapped) {
            if (currentPackages.length) {
              if (currentPackages[0].frame.promoType === "GVP") {
                let newMapped = {};
                Object.keys(mapped).map((treatmentKey) => {
                  newMapped["treatment"] = mapped[treatmentKey];
                });
                mapped = newMapped;
              }
              params = { ...params, ...mapped };
            }
          }
        });
      }

      /* if (insuranceEnabled && workflow.isLastStep()) {
                params['insurable'] = true;
            } */
      packages = packages.filter((s) =>
        filterExistingProperties(s.lensPackage, params)
      );
    }

    let cheapPackage = null;

    if (additionalParameters) {
      packages = packages.filter((s) =>
        filterExistingProperties(s.lensPackage, additionalParameters)
      );
    }

    //If it's the first step in which we're making a selection, then we still didn't pick a lens
    if (
      !workflow.firstStepWithManualSelection ||
      (workflow.firstStepWithManualSelection &&
        workflow.firstStepWithManualSelection.progressive >=
        currentStep?.progressive)
    ) {
      cheapPackage = getCheapPackageFromList(currentPackages);
    } else {
      cheapPackage = getCheapPackageFromList(packages);
    }
    // filtering packages landing on lensColor step taking only valorized color packages
    // after clicking on sun tile
    if (
      (workflow
        ?.findByStepKey("LensColor")
        ?.params?.[2]?.value?.toLowerCase() === "sun" ||
        workflow
          ?.findByStepKey("LensColor")
          ?.params?.[2]?.value?.toLowerCase() === "sonne")
    ) {
      const colorPackages = currentPackages.filter(
        (cp: any) => cp?.lensPackage?.color && cp?.lensPackage?.color !== ""
      );
      cheapPackage = getCheapPackageFromList(colorPackages);
      console.log(cheapPackage);
    }

    if (cheapPackage && configFrame) {
      let frame = getPrices(
        cheapPackage?.frame?.listPrice || configFrame?.listPrice,
        cheapPackage?.frame?.offerPrice,
        cheapPackage?.frame?.insPrice,
        configFrame?.offerPrice
      );
      let lens = getPrices(
        cheapPackage?.lensPackage?.listPrice,
        cheapPackage?.lensPackage?.offerPrice,
        cheapPackage?.lensPackage?.insPrice
      );

      //it can happen that we don't have a plano package, so we should just set the lens price to 0
      //while we still didn't make a manual selection yet
      if (
        (!workflow.firstStepWithManualSelection && !bundlesEnabled && !showFramePlusLensesPrice) ||
        (workflow.firstStepWithManualSelection &&
          workflow.firstStepWithManualSelection.progressive >=
          currentStep?.progressive && !bundlesEnabled && !showFramePlusLensesPrice)
      ) {
        lens = { price: 0, discount: 0, insPrice: null };
      }

      if (insuranceEnabled && workflow.isLastStep()) {
        frame.discount = getDiscountInsurance(
          frame.price,
          frame.discount,
          cheapPackage?.frame?.insPrice
        );
        lens.discount = getDiscountInsurance(
          lens.price,
          lens.discount,
          cheapPackage?.lensPackage?.insPrice
        );
      }

      /* if (!frame.insPrice && frame.insPrice !== 0) {
                frame.insPrice = frame.price;
            }

            if (!lens.insPrice && lens.insPrice !== 0 && insuranceEnabled) {
                lens.insPrice = lens.price;
            } */

      // in case of bundles (if not frame only) dont't take in count the frame price

      if (bundlesEnabled) {
        if (cheapPackage?.lensPackage?.listPrice === cheapPackage?.lensPackage?.offerPrice) {
          // no promo applied to bundles
          lens = {
            price: parseFloat(cheapPackage?.lensPackage?.originalListPrice) + parseFloat(cheapPackage?.frame?.offerPrice),
            discount:
              parseFloat(cheapPackage?.lensPackage?.originalListPrice) + parseFloat(cheapPackage?.frame?.offerPrice) -
              parseFloat(cheapPackage?.lensPackage?.listPrice),
            insPrice: parseFloat(cheapPackage?.lensPackage?.insPrice)
          }
        }
        frame = { price: 0, discount: 0, insPrice: null };

        if (cheapPackage.lensPackage.type === "Frame Only") {
          lens = { price: 0, discount: 0, insPrice: null };
          frame = getPrices(
            cheapPackage?.frame?.listPrice || configFrame?.listPrice,
            cheapPackage?.frame?.offerPrice,
            cheapPackage?.frame?.insPrice,
            configFrame?.offerPrice
          );
        }
      }

      const total = {
        price: frame.price + lens.price,
        discount: frame.discount + lens.discount,
        insPrice:
          frame.insPrice !== null || lens.insPrice !== null
            ? frame.insPrice + lens.insPrice
            : null,
      };

      if (sessionWarranty) {
        const warrantyPrice = getPrices(
          sessionWarranty.price,
          sessionWarranty.price,
          0
        );
        total.price += warrantyPrice.price;
        total.insPrice += warrantyPrice.price;
      } else if (cartMode) {
        if (warrantyByCart && sessionWarranty) {
          const currentWarranty = warrantyOptions.find(
            (warranty) => warranty.id === warrantyByCart
          );
          const warrantyPrice = getPrices(
            currentWarranty.price,
            currentWarranty.price,
            0
          );
          total.price += warrantyPrice.price;
        }
      }

      setPriceObj({
        frame,
        lens,
        total,
      });
    }
  }, [
    currentPackages,
    configFrame,
    currentTreatments,
    sessionWarranty,
    additionalParameters,
    bundlesEnabled,
    showFramePlusLensesPrice
  ]);

  return priceObj;
}

function useGVPTotalPrices() {
  const currentPackages = useCurrentPackages();
  const configFrame = useSelector((state: any) => state.config?.data?.frame);
  const currentTreatments = useSelector(
    (state: any) => state.workflow?.currentTreatments
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const additionalParameters = useSelector(
    (state: any) => state.workflow?.additionalParameters
  );

  const castNumber = (p: string | number) => {
    let pNumber = null;

    if (!isNumber(p)) {
      if (!p) {
        pNumber = null;
      } else {
        pNumber = parseFloat(p.toString());
      }
    } else {
      pNumber = p;
    }
    return pNumber;
  };

  const getPrices = (
    list: string | number,
    offer: string | number,
    ins: string | number,
    defaultList?: string | number
  ) => {
    let listPrice = castNumber(list);
    let offerPrice = castNumber(offer);
    let insPrice = castNumber(ins);

    if (isUndefined(offerPrice) || isNull(offerPrice)) {
      let def = castNumber(defaultList);
      if (isUndefined(def) || isNull(def)) {
        offerPrice = 0;
      } else {
        offerPrice = def;
      }
    }

    if (isUndefined(listPrice) || isNull(listPrice)) {
      listPrice = 0;
    }

    /* if (isUndefined(insPrice) || isNull(insPrice)) {
            insPrice = 0;
        } */

    return {
      price: listPrice,
      discount: listPrice - offerPrice,
      insPrice: insPrice,
    };
  };

  const getDiscountInsurance = (
    listPrice: number,
    discount: number,
    insPrice: number
  ) => {
    let discountInsurance = 0;

    if (insPrice) {
      discountInsurance = listPrice - insPrice;
    }
    return discountInsurance;
  };

  const [priceObj, setPriceObj] = useState({
    frame: !currentTreatments
      ? getPrices(configFrame?.listPrice, configFrame?.offerPrice, 0)
      : {
        price: 0,
        discount: 0,
        insPrice: 0,
      },
    lens: { price: 0, discount: 0, insPrice: 0 },
    total: !currentTreatments
      ? getPrices(configFrame?.listPrice, configFrame?.offerPrice, 0)
      : {
        price: 0,
        discount: 0,
        insPrice: 0,
      },
  });

  useEffect(() => {
    let packages = currentPackages;

    if (currentStep && currentTreatments) {
      let params = {};
      //CM fix set treatment family back
      //filter by treatment if navigation is > of treatment step
      if (
        currentStep.progressive >
        workflow.findByStepKey("TreatmentsFamily").progressive
      ) {
        currentTreatments.forEach((p: any) => {
          let mapped = parameterToPlainAdapter.adapt(p);
          if (mapped) {
            if (currentPackages.length) {
              if (currentPackages[0].frame.promoType === "GVP") {
                let newMapped = {};
                Object.keys(mapped).map((treatmentKey) => {
                  newMapped["treatment"] = mapped[treatmentKey];
                });
                mapped = newMapped;
              }
              params = { ...params, ...mapped };
            }
          }
        });
      }

      /* if (insuranceEnabled && workflow.isLastStep()) {
                params['insurable'] = true;
            } */
      packages = packages.filter((s) =>
        filterExistingProperties(s.lensPackage, params)
      );
    }

    let cheapPackage = null;

    if (additionalParameters) {
      packages = packages.filter((s) =>
        filterExistingProperties(s.lensPackage, additionalParameters)
      );
    }

    //If it's the first step in which we're making a selection, then we still didn't pick a lens
    if (
      !workflow.firstStepWithManualSelection ||
      (workflow.firstStepWithManualSelection &&
        workflow.firstStepWithManualSelection.progressive >=
        currentStep?.progressive)
    ) {
      cheapPackage = getCheapPackageFromList(configPackages);
    } else {
      cheapPackage = getCheapPackageFromList(packages);
    }

    if (cheapPackage) {
      const frame =
        !workflow.firstStepWithManualSelection ||
          (workflow.firstStepWithManualSelection &&
            workflow.firstStepWithManualSelection.progressive >=
            currentStep?.progressive)
          ? getPrices(
            cheapPackage?.frame?.listPrice || configFrame?.listPrice,
            cheapPackage?.frame?.offerPrice,
            cheapPackage?.frame?.insPrice,
            configFrame?.offerPrice
          )
          : { price: 0, discount: 0, insPrice: null };

      let lens =
        !workflow.firstStepWithManualSelection ||
          (workflow.firstStepWithManualSelection &&
            workflow.firstStepWithManualSelection.progressive >=
            currentStep?.progressive)
          ? { price: 0, discount: 0, insPrice: null }
          : getPrices(
            cheapPackage?.lensPackage?.listPrice,
            cheapPackage?.lensPackage?.offerPrice,
            cheapPackage?.lensPackage?.insPrice
          );

      if (insuranceEnabled && workflow.isLastStep()) {
        frame.discount = getDiscountInsurance(
          frame.price,
          frame.discount,
          cheapPackage?.frame?.insPrice
        );
        lens.discount = getDiscountInsurance(
          lens.price,
          lens.discount,
          cheapPackage?.lensPackage?.insPrice
        );
      }

      /* if (!frame.insPrice && frame.insPrice !== 0) {
                frame.insPrice = frame.price;
            }

            if (!lens.insPrice && lens.insPrice !== 0 && insuranceEnabled) {
                lens.insPrice = lens.price;
            } */

      let total = null;

      if (!frame.price && !frame.discount) {
        total = {
          price: parseFloat(cheapPackage.lensPackage.listPrice),
          discount:
            parseFloat(cheapPackage.lensPackage.listPrice) -
            (parseFloat(cheapPackage.lensPackage.offerPrice) +
              parseFloat(cheapPackage.frame.offerPrice)),
          insPrice:
            cheapPackage.lensPackage.insPrice !== null
              ? parseFloat(cheapPackage.lensPackage.insPrice)
              : null,
        };
      } else {
        total = {
          price: frame.price + lens.price,
          discount: frame.discount + lens.discount,
          insPrice:
            frame.insPrice !== null || lens.insPrice !== null
              ? frame.insPrice + lens.insPrice
              : null,
        };
      }

      if (sessionWarranty) {
        const warrantyPrice = getPrices(
          sessionWarranty.price,
          sessionWarranty.price,
          0
        );
        total.price += warrantyPrice.price;
      }

      setPriceObj({
        frame,
        lens,
        total,
      });
    }
  }, [
    currentPackages,
    configFrame,
    currentTreatments,
    sessionWarranty,
    additionalParameters,
  ]);

  return priceObj;
}

export function useCurrentPrices() {
  const currentPackages = useCurrentPackages();
  const nonGVPTotalPrices = useNonGVPTotalPrices();
  const GVPTotalPrices = useGVPTotalPrices();
  const config = useSelector((state: any) => state.config);

  const [priceObj, setPriceObj] = useState({
    frame: { price: 0, discount: 0, insPrice: 0 },
    lens: { price: 0, discount: 0, insPrice: 0 },
    total: { price: 0, discount: 0, insPrice: 0 },
  });

  useEffect(() => {
    if (
      currentPackages &&
      !checkGVP(currentPackages) &&
      !isBundlePriceEnabled(config) &&
      nonGVPTotalPrices
    ) {
      setPriceObj(nonGVPTotalPrices);
    } else if (
      (currentPackages && checkGVP(currentPackages) && GVPTotalPrices) ||
      isBundlePriceEnabled(config)
    ) {
      setPriceObj(GVPTotalPrices);
    }
  }, [currentPackages, nonGVPTotalPrices, GVPTotalPrices]);
  return priceObj;
}
