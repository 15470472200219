import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workflowActions } from "../../redux/slices/workflow";
import { consoleActions } from "../../redux/slices/console";
import { parameterToPlainAdapter } from "../adapters/ParameterToPlainAdapter";
import {
  filterExistingProperties,
  hasValidPrescription,
} from "../ConfigLoader";
import { Parameter } from "../models/Parameter";
import { workflow } from "../models/Workflow";
import { useBundlesPrices } from "./useBundlesPrices";

export function useCurrentPackages(includeRXFiltered?: boolean) {
  const getCurrentPackage = (packages): any => {
    if (isArray(packages)) {
      let params = {};
      let pcps = workflow.getPrevCurrentParameterStep();
      let noPrescOpt = pcps.filter(
        (pcp: any) => pcp.value === "Non_prescription"
      );
      if (noPrescOpt.length > 0) {
        pcps = pcps.filter((pcp: any) => pcp.key !== "type");
        pcps.unshift(noPrescOpt[0]);
      }
      pcps.forEach((p) => {
        if (p.key === "thickness" && p.attributes && p.attributes.designType) {
          let paramToAdd = { designType: p.attributes.designType };
          const mapped = parameterToPlainAdapter.adapt(p);
          if (mapped) params = { ...params, ...mapped };
          params = { ...params, ...mapped, ...paramToAdd };
        } else if (p.key === "addOns") {
          let ret = new Parameter();
          Object.entries(p).forEach(([key, val]) => {
            if (key !== "key") {
              if (key === "value") {
                if (val) {
                  ret["key"] = val.type;
                  ret[key] = val.selection;
                } else {
                  ret["key"] = "addOns";
                  ret[key] = null;
                }
              } else {
                ret[key] = val;
              }
            }
          });
          const mapped = parameterToPlainAdapter.adapt(ret);
          if (mapped) params = { ...params, ...mapped };
        } else if (
          (p.key === "color" || p.key === "lensColor") &&
          p.attributes
        ) {
          //added colorCategory since colorId is not unique
          let ret = {};
          ret[p.key] = p.value;
          if (p.optionalAttributes.key !== "") {
            ret[p.optionalAttributes.key] = p.optionalAttributes.value;
          }

          if (
            p.attributes.colorCategory &&
            p.attributes.colorCategory.toLowerCase() !== "null"
          ) {
            ret["colorCategory"] = p.attributes.colorCategory;
          }
          params = { ...params, ...ret };
        } else if (p.key === "type") {
          let id = p.value;
          if (["reading", "distance"].includes(id.toLowerCase())) {
            id = packages.find((currentPackage) =>
              currentPackage.lensPackage.type.toLowerCase().includes("single")
            )?.lensPackage.type;
          }
          const mapped = parameterToPlainAdapter.adapt({ ...p, value: id });
          if (mapped) params = { ...params, ...mapped };
        } else {
          const mapped = parameterToPlainAdapter.adapt(p);
          //console.log("mapped", mapped);
          if (mapped) params = { ...params, ...mapped };
          //console.log("params", params);
        }
      });
      return packages.filter((s) =>
        filterExistingProperties(s.lensPackage, params)
      );
    }
  };

  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep); //
  const insEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  ); //
  const currentPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const promoType = useSelector((state: any) => state.workflow?.promoType); //
  const filterPromoAtStep = useSelector(
    (state: any) => state.config?.filterPromAtStep
  );
  const bundles = useSelector(
    (state: any) => state.config?.lensesData?.bundles
  );
  const bundlesPrices = useSelector(
    (state: any) => state.config?.lensesData?.bundlesPrices
  );

  const bundlesEnabled = useBundlesPrices();

  const [currentPackage, setCurrentPackage] = useState(
    getCurrentPackage(configPackages)
  );

  const reduxDispatch = useDispatch();

  useEffect(() => {
    let filtered = getCurrentPackage(configPackages);

    if (bundlesEnabled) {
      const frameOnly = filtered.find(
        (p) => p.lensPackage.type === "Frame Only"
      );

      filtered = filtered
        .filter((p) => {
          if (
            bundles.find(
              ({ lensPackageUPC }) => p.lensPackage.upc === lensPackageUPC
            )
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((p) => {
          const bundle = bundles.find(
            ({ lensPackageUPC }) => p.lensPackage.upc === lensPackageUPC
          );
          const { listPrice, offerPrice } = bundlesPrices.find(
            ({ bundleUPC }) => bundle.bundleUPC === bundleUPC
          );
          return {
            ...p,
            lensPackage: {
              ...p.lensPackage,
              listPrice: listPrice,
              offerPrice: offerPrice,
              originalListPrice: p.lensPackage.listPrice,
              originalOfferPrice: p.lensPackage.offerPrice,
              lensBundle: bundle.bundleName,
            },
          };
        });
      if (frameOnly) filtered.push(frameOnly);

      filtered = getCurrentPackage(filtered);
    }

    if (currentStep && promoType && filterPromoAtStep) {
      const filterGap = workflow.findByStepKey(filterPromoAtStep);
      if (filterGap && filterGap.progressive <= currentStep.progressive)
        filtered = filtered.filter((f) => f.frame.promoType === promoType);
    }
    //clean treatments selected when navigation is before treatments step
    if (
      currentStep &&
      (currentStep.progressive <=
        workflow.findByStepKey("TreatmentsFamily")?.progressive ||
        currentStep.progressive <=
        workflow.findByStepKey("GVPTreatment")?.progressive)
    ) {
      reduxDispatch(workflowActions.setParameterPrice(null));
    }

    if (!includeRXFiltered) {
      if (
        currentPrescription &&
        workflow.currentStep?.progressive >=
        workflow.findByStepKey("AdvancedPrescription")?.progressive
      ) {
        filtered = filtered.filter((f) =>
          hasValidPrescription(f, currentPrescription)
        );
      }
      setCurrentPackage(filtered);
    } else {
      let validRXPackages = [];
      let invalidRXPackages = [];
      filtered?.forEach((pkg) => {
        if (hasValidPrescription(pkg, currentPrescription)) {
          validRXPackages.push(pkg);
        } else {
          invalidRXPackages.push(pkg);
        }
      });
      setCurrentPackage({
        validRXPackages: validRXPackages,
        invalidRXPackages: invalidRXPackages,
      });
    }
  }, [
    configPackages,
    currentStep,
    insEnabled,
    currentPrescription,
    promoType,
    filterPromoAtStep,
  ]);

  useEffect(() => {
    if (currentPackage && currentStep) {
      reduxDispatch(consoleActions.setCurrentPackages(currentPackage));
    }
  }, [currentPackage, currentStep]);

  return currentPackage;
}
