import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";
import { modalsActions } from "../../redux/slices/modals";
import { workflow } from "../../configurator/models/Workflow";
import { BrandComponent } from "../common/BrandComponent";
import {
  hasValidPrescription,
  isFramePrescriptionCompatible,
} from "../../configurator/ConfigLoader";
import { advancedManualPrescriptionAdapter } from "../../configurator/adapters/AdvancedManualPrescriptionAdapter";
import { AdvancedManualPrescription } from "../../types";
import { createPortal } from "react-dom";
import {
  usePupillaryDistance,
  useEnablePrismComment,
  usePrescriptionIssueDate,
  useEnablePrism,
  useMandatoryManualInput,
  useEnableAgreement,
  useReleaseVersion,
  useEnableZeroSphere,
  useEnableNonPrescriptionRedirect,
} from "@hooks";
import ReferencedSection from "../common/ReferencedSection";
import { usePrescriptionsFromMyAccount } from "../../configurator/hooks/usePrescriptionsFromMyAccount";
import { CustomCheckbox } from "./components/CustomCheckbox";
import { MoreOptions } from "./components/MoreOptions";
import { PrescriptionModeEnum } from "../../configurator/steps/AdvancedPrescription/component";
import { SimpleTextError } from "./components/SimpleTextError";
import { IncompatibleFrame } from "./components/IncompatibleFrame";
import { IncompatibleLenses } from "./components/IncompatibleLenses";
import { IncompatibleLensType } from "./components/IncompatibleLensType";
import { Separator } from "./components/Separator";
import { IssueDateInput } from "./components/IssueDateInput";
import { AgreementCheckbox } from "./components/AgreementCheckbox";
import { PrescriptionModalNoAdd } from "../PrescriptionModalNoAdd/component";
import "./default.module.scss";
import { prescriptionActions } from "../../redux/slices/prescription";
import { none } from "fp-ts/lib/Option";
import { size } from "lodash";
import { getSimplePrescriptionValuesArray } from "../../utils/prescription";
import { PrescriptionNameInput } from "./components/PrescriptionNameInput";

export interface FullPrescriptionFormProps {
  hideMoreOptions: boolean;
  currentPackages: any[];
  onSuccessPrescription?: (
    prescriptionObject: AdvancedManualPrescription
    /* type: string */
  ) => void;
  prescriptionObject: any | null;
  brand?: string;
  userIsLoggedIn?: boolean;
  withIssueDate?: boolean;
  rxType: string;
  withPrescriptionsFromMyAccount: boolean;
  isEditMode: boolean;
  noPrescriptionsFound?: boolean;
}

export function FullPrescriptionForm({
  hideMoreOptions,
  currentPackages,
  onSuccessPrescription,
  prescriptionObject,
  brand,
  userIsLoggedIn,
  withIssueDate = true,
  rxType,
  withPrescriptionsFromMyAccount,
  isEditMode,
  noPrescriptionsFound,
}: FullPrescriptionFormProps) {
  const prescriptionExternalFunctions = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const translation = useSelector(
    (state: any) => state.config?.translation?.language
  );
  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );
  const frame = useSelector((state: any) => state.config?.data?.frame);

  //Modals
  const sendWithoutPrescriptionValues = useSelector(
    (state: any) => state.modals?.missingPrescriptionsValueResult
  );
  const showMissingReadingAddValueModal = useSelector(
    (state: any) => state.modals?.showMissingReadingAddValueModal
  );

  //State
  const [clicked, setClicked] = useState(false);
  const [showMissingAxisError, setShowMissingAxisError] = useState(false);
  const [visibilityButtonUploaded, setVisibilityButtonUploaded] =
    useState(true);
  const [isUploading, setIsUploading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFileTooBigError, setIsFileTooBigError] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [preloadedFileInfo, setPreloadedFileInfo] = useState(null);
  const [file, setFile] = useState(null);
  const [prescriptionIdForCalifornia, setPrescriptionIdForCalifornia] =
    useState(null);
  const [alreadyDone, setAlreadyDone] = useState(false);
  const [newPrescriptionName, setNewPrescriptionName] = useState("");
  const [isCalifornian, setIsCalifornian] = useState(false);
  const doctorSelected = useSelector(
    (state: any) => state?.modals?.doctorSelected
  );
  const [selectedNested, setSelectedNested] = useState(
    prescriptionExternalFunctions?.nestedFlows
      ? prescriptionExternalFunctions?.nestedFlows[0]
      : null
  );
  const [isMoreOptionsFormOpen, setIsMoreOptionsFormOpen] = useState(false);
  const [showPrismValuesForm, setShowPrismValuesForm] = useState(false);
  const [showSPHCYLWarning, setShowSPHCYLWarning] = useState(false);
  const [showAxisError, setShowAxisError] = useState(false);
  const [showAxisRangeError, setShowAxisRangeError] = useState(false);
  const [showNewPrescriptionNameError, setShowNewPrescriptionNameError] =
    useState(false);
  const [
    showPrescriptionMissingValuesError,
    setShowPrescriptionMissingValuesError,
  ] = useState(false);
  const [showPrismMissingValuesError, setShowPrismMissingValuesError] =
    useState(false);
  const [showIncompatibleLensTypeError, setShowIncompatibleLensTypeError] =
    useState<{ show: boolean; key: string }>({ show: false, key: "" });
  const [sphOD, setSphOD] = useState(0);
  const [sphOS, setSphOS] = useState(0);
  const [cylOD, setCylOD] = useState(0);
  const [cylOS, setCylOS] = useState(0);
  const [axisOD, setAxisOD] = useState(null);
  const [axisOS, setAxisOS] = useState(null);
  const [addOD, setAddOD] = useState(null);
  const [addOS, setAddOS] = useState(null);
  const [vertOD, setVertOD] = useState(null);
  const [vertOS, setVertOS] = useState(null);
  const [baseVertOD, setBaseVertOD] = useState(null);
  const [baseVertOS, setBaseVertOS] = useState(null);
  const [horOD, setHorOD] = useState(null);
  const [horOS, setHorOS] = useState(null);
  const [baseHorOD, setBaseHorOD] = useState(null);
  const [baseHorOS, setBaseHorOS] = useState(null);
  const [comment, setComment] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const [saveAccountPrescription, setSaveAccountPrescription] = useState(
    isEditMode || false
  );
  const [formErrors, setFormErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [prismErrorMessage, setPrismErrorMessage] = useState("");
  const [showIncompatibleFrameError, setShowIncompatibleFrameError] =
    useState(false);
  const [showIncompatiblesLensesError, setShowIncompatiblesLensesError] =
    useState(false);
  const [loadedExternally, setLoadedExternally] = useState(prescriptionObject);
  const [isPrescriptionModified, setIsPrescriptionModified] = useState(false);
  const [issueDate, setIssueDate] = useState("");
  const [compatibleFrame, setCompatibleFrame] = useState(null);
  const [samePrescriptionBothEyes, setSamePrescriptionBothEyes] =
    useState(false);
  const [selectedPower, setSelectedPower] = useState(null);
  const [noPowerSelectedError, setNoPowerSelectedError] = useState(false);

  const isComponentInitialised = useRef(false);

  const prescriptionName = useTranslate(
    "steps.advancedPrescription.fromMyAccount.prescriptionName"
  );
  const lastModified = useTranslate(
    "steps.advancedPrescription.fromMyAccount.lastModified"
  );
  const prescriptionNameError = useTranslate(
    "steps.advancedPrescription.fromMyAccount.newPrescriptionNameError"
  );

  const selectPlaceholder = useTranslate(
    "steps.advancedPrescription.manual.addPlaceholder"
  );

  const tooltipText = useTranslate(
    "steps.advancedPrescription.manual.issueDate.tooltipText"
  );
  const axisRangeError = useTranslate(
    "steps.advancedPrescription.manual.alerts.axisRangeError"
  );
  const axisZeroError = useTranslate(
    "steps.advancedPrescription.manual.alerts.axisZeroError"
  );
  const sphPositiveNegativeWarning = useTranslate(
    "steps.advancedPrescription.manual.alerts.sphPositiveNegativeWarning"
  );
  const missingValues = useTranslate(
    "steps.advancedPrescription.manual.alerts.missingValues"
  );

  const sphereName = useTranslate("steps.advancedPrescription.manual.sphere");
  const cylinderName = useTranslate(
    "steps.advancedPrescription.manual.cylinder"
  );
  const axisName = useTranslate("steps.advancedPrescription.manual.axis");
  const addName = useTranslate("steps.advancedPrescription.manual.add");
  const savePrescriptionInAccount = useTranslate(
    "steps.advancedPrescription.fromMyAccount.savePrescriptionInAccount"
  );
  const missingBaseDir = useTranslate(
    "steps.advancedPrescription.manual.alerts.missingBaseDir"
  );
  const missingPrismValues = useTranslate(
    "steps.advancedPrescription.manual.alerts.missingPrismValues"
  );

  const subtitle = useTranslate("steps.advancedPrescription.manual.subtitle");
  const howToRead = useTranslate("steps.advancedPrescription.manual.howToRead");
  const withoutPrescriptionFromMyAccountLabel = useTranslate(
    "steps.advancedPrescription.manual.withoutPrescriptionFromMyAccount"
  );
  const samePrescriptionBothEyesLabel = useTranslate(
    "steps.advancedPrescription.manual.samePrescriptionBothEyes"
  );

  const missingPowerError = useTranslate(
    "steps.advancedPrescription.manual.alerts.power"
  );

  const enableZeroSphere = useEnableZeroSphere();
  const enableNonPrescriptionRedirect = useEnableNonPrescriptionRedirect();
  const nonPrescriptionPkgs = useSelector(
    (state: any) => state?.config?.nonPrescriptionPkgs
  );
  const reduxDispatch = useDispatch();
  const PD = usePupillaryDistance({ prescription: prescriptionObject });
  const enablePrismComment = useEnablePrismComment();
  const enablePrism = useEnablePrism();
  const { saveToMyAccount } = usePrescriptionsFromMyAccount();
  const { isValidIssueDate, convertToISO, isExpiredPrescription } =
    usePrescriptionIssueDate();
  const enableMandatory = useMandatoryManualInput();
  const enableAgreementCheckbox = useEnableAgreement();
  const nestedFlow = prescriptionExternalFunctions?.nestedFlows;
  const isReading = rxType === "READING";

  const getFileTypeFromFullName = (fileName: string) => {
    let split = fileName.split(".");
    let lastElem = split[split.length - 1];
    return "." + lastElem;
  };

  const [samePrescriptionFrom, setSamePrescriptionFrom] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setVisibilityButtonUploaded(false);
    if (checkFileSizeAndSetErrors(file)) {
      setIsUploading(true);
      if (prescriptionModule && prescriptionModule.uploadExtendedPrescription) {
        const fileReader = new window.FileReader();
        fileReader.onload = (fileLoad) => {
          const { result } = fileLoad.target;
          let requestObj = {
            fileName: file.name,
            fileData: result,
          };
          prescriptionModule
            .uploadExtendedPrescription(requestObj)
            .then((res) => {
              setIsFileTooBigError(true);
              setIsSuccessful(true);

              setPreloadedFileInfo({
                name: file.name,
                size: file.size,
                type: getFileTypeFromFullName(file.name),
                savedFileName: res.savedFileName,
              });
              setPrescriptionIdForCalifornia(res.prescriptionId);
              setIsUploading(false);

              if (prescriptionModule.downloadExtendedPrescription) {
                prescriptionModule
                  .downloadExtendedPrescription({
                    savedFileName: res.savedFileName,
                  })
                  .then((res) => {
                    setFilePath(res.fileData);
                  })
                  .catch((err) => {
                    setFilePath("");
                  });
              } else {
                console.error("Missing downloadExtendedPrescriptionFunction");
                setFilePath("");
              }
            })
            .catch((err) => {
              setIsSuccessful(false);
              setIsUploading(false);
              setIsFileTooBigError(true);
            });
        };
        fileReader.readAsDataURL(file);
      }
    }
  };
  const checkFileSizeAndSetErrors = (file) => {
    if (file) {
      let configMaxSize = prescriptionModule?.maxFileSize
        ? prescriptionModule?.maxFileSize
        : 10;
      let maximumFileSize = configMaxSize * 1024 * 1024;
      if (file.size > maximumFileSize) {
        setIsSuccessful(false);
        setIsFileTooBigError(true);
        setIsUploading(false);
        try {
          //@ts-ignore
          window.tealium_data2track.push({
            id: "Error",
            Error_Source: "User",
            Error_Code: "RX Configurator: prescription upload",
            Error_Message: "File is too big",
          });
        } catch (error) {
          console.error(
            "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
          );
        }
        console.error("Error while saving prescription");
        return false;
      }
      return true;
    }
    return false;
  };
  const handleSwitch = () => {
    setIsCalifornian(!isCalifornian);
  };

  const formSetter = (setter) => (val) => {
    setter(val);
    setShowIncompatibleFrameError(false);
    setShowIncompatiblesLensesError(false);
  };

  const formValues = {
    prescription: {
      right_eye_sph: {
        value: sphOD,
        setter: formSetter(setSphOD),
        hasError: formErrors["right_eye_sph"],
      },
      left_eye_sph: {
        value: sphOS,
        setter: formSetter(setSphOS),
        hasError: formErrors["left_eye_sph"],
      },
      right_eye_cyl: {
        value: cylOD,
        setter: formSetter(setCylOD),
        hasError: formErrors["right_eye_cyl"],
      },
      left_eye_cyl: {
        value: cylOS,
        setter: formSetter(setCylOS),
        hasError: formErrors["left_eye_cyl"],
      },
      right_eye_axis: {
        value: axisOD,
        setter: formSetter(setAxisOD),
        hasError: formErrors["right_eye_axis"],
      },
      left_eye_axis: {
        value: axisOS,
        setter: formSetter(setAxisOS),
        hasError: formErrors["left_eye_axis"],
      },
      right_eye_add: {
        value: addOD,
        setter: formSetter(setAddOD),
        hasError: formErrors["right_eye_add"],
      },
      left_eye_add: {
        value: addOS,
        setter: formSetter(setAddOS),
        hasError: formErrors["left_eye_add"],
      },
    },
    prism: {
      right_eye_vertical: {
        value: vertOD,
        setter: formSetter(setVertOD),
        hasError: formErrors["right_eye_vertical"],
      },
      left_eye_vertical: {
        value: vertOS,
        setter: formSetter(setVertOS),
        hasError: formErrors["left_eye_vertical"],
      },
      right_eye_baseDirVert: {
        value: baseVertOD,
        setter: formSetter(setBaseVertOD),
        hasError: formErrors["right_eye_baseDirVert"],
      },
      left_eye_baseDirVert: {
        value: baseVertOS,
        setter: formSetter(setBaseVertOS),
        hasError: formErrors["left_eye_baseDirVert"],
      },
      right_eye_horizontal: {
        value: horOD,
        setter: formSetter(setHorOD),
        hasError: formErrors["right_eye_horizontal"],
      },
      left_eye_horizontal: {
        value: horOS,
        setter: formSetter(setHorOS),
        hasError: formErrors["left_eye_horizontal"],
      },
      right_eye_baseDirHor: {
        value: baseHorOD,
        setter: formSetter(setBaseHorOD),
        hasError: formErrors["right_eye_baseDirHor"],
      },
      left_eye_baseDirHor: {
        value: baseHorOS,
        setter: formSetter(setBaseHorOS),
        hasError: formErrors["left_eye_baseDirHor"],
      },
    },
  };

  const isAxisODZeroErrorCondition = () => {
    return axisOD !== null && cylOD !== 0 && axisOD === 0;
  };

  const isAxisODNullErrorCondition = () => {
    return (axisOD === null || Number.isNaN(axisOD)) && cylOD !== 0;
  };

  const isAddODNullErrorCondition = () => {
    return (
      !isAddDisabled() &&
      (addOD === null || isNaN(addOD)) &&
      (sphOD || cylOD || axisOD)
    );
  };

  const isAxisODRangeErrorCondition = () => {
    return axisOD !== null && cylOD !== 0 && (axisOD < 0 || axisOD > 180);
  };

  const isAxisOSZeroErrorCondition = () => {
    return axisOS !== null && cylOS !== 0 && axisOS === 0;
  };

  const isAxisOSNullErrorCondition = () => {
    return (axisOS === null || Number.isNaN(axisOS)) && cylOS !== 0;
  };

  const isAddOSNullErrorCondition = () => {
    return (
      !isAddDisabled() &&
      (addOS === null || isNaN(addOS)) &&
      (sphOS || cylOS || axisOS)
    );
  };

  const isAxisOSRangeErrorCondition = () => {
    return axisOS !== null && cylOS !== 0 && (axisOS < 0 || axisOS > 180);
  };

  const isNewPrescriptionNameErrorCondition = () => {
    return !newPrescriptionName && saveAccountPrescription ? true : false;
  };

  const isSavePrescriptionCheckboxEnabled = useMemo(() => {
    return true;
    return userIsLoggedIn && !isPrescriptionModified && prescriptionObject
      ? false
      : true;
  }, [userIsLoggedIn, isPrescriptionModified]);

  const isAgreementCheckboxEnabled = useMemo(
    () =>
      /* userIsLoggedIn && !isPrescriptionModified && prescriptionObject
        ? false
        :  */ true,
    [userIsLoggedIn, isPrescriptionModified]
  );

  const prescriptionTableColumns = [
    {
      id: "sph",
      type: "sph",
      placeholder: "Sphere SPH",
      required: true,
      name: sphereName,
      min: prescriptionDefaults.sphMin,
      max: prescriptionDefaults.sphMax,
      res: prescriptionDefaults.sphRes,
      rows: [
        {
          id: "right_eye_sph",
        },
        {
          id: "left_eye_sph",
        },
      ],
    },
    {
      id: "cyl",
      type: "cyl",
      placeholder: "Cylinder CYL",
      required: true,
      name: cylinderName,
      min: prescriptionDefaults.cylMin,
      max: prescriptionDefaults.cylMax,
      res: prescriptionDefaults.cylRes,
      rows: [
        {
          id: "right_eye_cyl",
        },
        {
          id: "left_eye_cyl",
        },
      ],
    },
    {
      id: "axis",
      type: "axis",
      placeholder: "",
      required: true,
      name: axisName,
      rows: [
        {
          id: "right_eye_axis",
        },
        {
          id: "left_eye_axis",
        },
      ],
    },
    {
      id: "add",
      type: "add",
      placeholder: selectPlaceholder,
      required: true,
      name: addName,
      min: prescriptionDefaults.addMin,
      max: prescriptionDefaults.addMax,
      res: prescriptionDefaults.addRes,
      rows: [
        {
          id: "right_eye_add",
        },
        {
          id: "left_eye_add",
        },
      ],
    },
  ];

  const getAxisError = () => {
    try {
      //@ts-ignore
      window.tealium_data2track.push({
        id: "Error",
        Error_Source: "User",
        Error_Code: "RX Configurator: prescription values",
        Error_Message: "Missing axes values",
      });
    } catch (error) {
      console.error(
        "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
      );
    }
    console.error("Error while saving prescription");
  };

  const resetAxisErrors = () => {
    let errorsToSet = {};

    if (formErrors["right_eye_axis"] && cylOD === 0) {
      errorsToSet["right_eye_axis"] = false;
    }
    if (formErrors["left_eye_axis"] && cylOS === 0) {
      errorsToSet["left_eye_axis"] = false;
    }

    if (!isAxisODRangeErrorCondition() && !isAxisOSRangeErrorCondition()) {
      setShowAxisRangeError(false);
      errorsToSet["right_eye_axis"] = false;
    }
    if (!isAxisODZeroErrorCondition() && !isAxisOSZeroErrorCondition()) {
      setShowAxisError(false);
      errorsToSet["left_eye_axis"] = false;
    }

    setFormErrors({
      ...formErrors,
      ...errorsToSet,
    });
  };

  const isAddDisabled = () => {
    if (isReading) return false;
    if (
      rxType !== "READING" &&
      rxType !== "PROGRESSIVE" &&
      rxType !== "BIFOCAL"
    ) {
      return true;
    }
    return false;
  };

  const toggleMoreOptionsOpen = () =>
    setIsMoreOptionsFormOpen(!isMoreOptionsFormOpen);

  const resetForm = () => {
    setSphOD(0);
    setSphOS(0);
    setCylOD(0);
    setCylOS(0);
    setAxisOD(null);
    setAxisOS(null);
    setAddOD(null);
    setAddOS(null);
    setVertOD(null);
    setVertOS(null);
    setBaseVertOD(null);
    setBaseVertOS(null);
    setHorOD(null);
    setHorOS(null);
    setBaseHorOD(null);
    setBaseHorOS(null);
    setComment(null);
    setAgreement(false);

    PD.state.resetPDState();

    setShowIncompatibleFrameError(false);
    setShowIncompatiblesLensesError(false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
  };

  const validateSubmittedData = () => {
    setClicked(true);

    //rxType === "READER"
    if (rxType === "READER") {
      if (selectedPower) {
        sendPrescriptionData();
        return;
      } else {
        setNoPowerSelectedError(true);
        return;
      }
    }

    let errorsToSet: { [key: string]: boolean } = {};
    if (isCalifornian || prescriptionModule.prescriptionType === "NESTED") {
      if (!isSuccessful) {
        errorsToSet["upload_error"] = true;
      }
    }
    if (
      sphOD === 0 &&
      sphOS === 0 &&
      cylOD === 0 &&
      cylOS === 0 &&
      (!isMoreOptionsFormOpen ||
        (isMoreOptionsFormOpen && !showPrismValuesForm))
    ) {
      if (!enableMandatory && !enableZeroSphere) {
        reduxDispatch(modalsActions.setShowPrescriptionModalContinue(true));
        return;
      }

      if (
        enableZeroSphere &&
        enableNonPrescriptionRedirect &&
        nonPrescriptionPkgs?.check
      ) {
        if (
          rxType.toLowerCase() === "reading" ||
          rxType.toLowerCase() === "progressive"
        ) {
          if (
            (addOD === null || isNaN(addOD)) &&
            (addOS === null || isNaN(addOS))
          ) {
            reduxDispatch(modalsActions.setShowProceedWithNoPowerModal(true));
            return;
          }
        } else {
          if (
            cylOD === 0 &&
            cylOS === 0 &&
            axisOD === null &&
            axisOS === null
          ) {
            reduxDispatch(modalsActions.setShowProceedWithNoPowerModal(true));
            return;
          }
        }
      }
      if (isCalifornian && !enableMandatory) {
        reduxDispatch(modalsActions.setShowMissingPrescriptionsValue(true));
        return;
      }
      if (!enableZeroSphere) {
        errorsToSet["left_eye_sph"] = true;
        errorsToSet["right_eye_sph"] = true;
      }
      // errorsToSet["left_eye_cyl"] = true;
      // errorsToSet["right_eye_cyl"] = true;
      if (
        !isAddDisabled() &&
        (addOD === null ||
          addOD === undefined ||
          addOS === null ||
          addOS === undefined ||
          isNaN(addOD) ||
          isNaN(addOS)) &&
        !enableZeroSphere
      ) {
        errorsToSet["right_eye_add"] = true;
        errorsToSet["left_eye_add"] = true;
      }
      setShowPrescriptionMissingValuesError(true);
    }
    if (sphOD !== 0 || cylOD !== 0) {
      if (!isAddDisabled() && (!addOD || isNaN(addOD)) && !isReading) {
        errorsToSet["right_eye_add"] = true;
      }
    }
    if (sphOS !== 0 || cylOS !== 0) {
      if (!isAddDisabled() && (!addOS || isNaN(addOS)) && !isReading) {
        errorsToSet["left_eye_add"] = true;
      }
    }
    if (
      enableZeroSphere &&
      (rxType.toLowerCase() === "single vision" ||
        rxType.toLowerCase() === "distance")
    ) {
      if (sphOD === 0 && sphOS === 0 && cylOD === 0 && cylOS === 0) {
        errorsToSet["right_eye_cyl"] = true;
      }
      // else if (sphOS === 0 && cylOS === 0 && cylOD === 0) {
      //   errorsToSet["left_eye_cyl"] = true;
      // }
    }
    if (
      enableZeroSphere &&
      (rxType.toLowerCase() === "reading" ||
        rxType.toLowerCase() === "progressive")
    ) {
      if (sphOD === 0 && sphOS === 0 && (!addOD || isNaN(addOD)) && (!addOS || isNaN(addOS))) {
        errorsToSet["right_eye_add"] = true;
      }
      // else if (
      //   sphOS === 0 &&
      //   (!addOS || isNaN(addOS)) &&
      //   (!addOD || isNaN(addOD))
      // ) {
      //   errorsToSet["left_eye_add"] = true;
      // }
    }
    Object.keys(formValues.prescription).map((field) => {
      if (field.indexOf("add") < 0) {
        if (
          formValues.prescription[field]["value"] === null ||
          Number.isNaN(formValues.prescription[field]["value"])
        ) {
          let skip =
            (field === "left_eye_axis" && cylOS === 0) ||
            (field === "right_eye_axis" && cylOD === 0) ||
            (field === "right_eye_add" && isAddDisabled()) ||
            (field === "left_eye_add" && isAddDisabled());

          if (!skip) {
            errorsToSet[field] = true;
            setShowMissingAxisError(true);
          }
        }
      }
    });

    //Axis range
    if (isAxisODRangeErrorCondition()) {
      errorsToSet["right_eye_axis"] = true;
      setShowAxisRangeError(true);
      setShowMissingAxisError(false);
    }
    if (isAxisOSRangeErrorCondition()) {
      errorsToSet["left_eye_axis"] = true;
      setShowAxisRangeError(true);
      setShowMissingAxisError(false);
    }
    //Add
    if (
      !isAddDisabled() &&
      (addOD === null ||
        addOD === undefined ||
        addOS === null ||
        addOS === undefined ||
        isNaN(addOD) ||
        isNaN(addOS))
    ) {
      if (
        (addOD === null || addOD === undefined || isNaN(addOD)) &&
        (sphOD || cylOD || axisOD) &&
        !isReading
      ) {
        errorsToSet["right_eye_add"] = true;
        setShowPrescriptionMissingValuesError(true);
      }
      if (
        !isReading &&
        (addOS !== null || addOS !== undefined) &&
        (sphOS !== 0 || cylOS !== 0) &&
        !enableZeroSphere
      ) {
        errorsToSet["left_eye_add"] = true;
        setShowPrescriptionMissingValuesError(true);
      }
    }

    //Prism
    if (showPrismValuesForm && (isMoreOptionsFormOpen || !enablePrismComment)) {
      errorsToSet["right_eye_vertical"] = !vertOD && !!baseVertOD;
      errorsToSet["left_eye_vertical"] = !vertOS && !!baseVertOS;
      errorsToSet["right_eye_horizontal"] = !horOD && !!baseHorOD;
      errorsToSet["left_eye_horizontal"] = !horOS && !!baseHorOS;

      errorsToSet["right_eye_baseDirVert"] = !!vertOD && !baseVertOD;
      errorsToSet["left_eye_baseDirVert"] = !!vertOS && !baseVertOS;
      errorsToSet["right_eye_baseDirHor"] = !!horOD && !baseHorOD;
      errorsToSet["left_eye_baseDirHor"] = !!horOS && !baseHorOS;

      const isAllValuesError = Object.values(formValues.prism).every(
        (field) => !field.value
      );
      const isSomeValuesError = Object.values(formValues.prism).some(
        (field) => !field.value
      );
      isAllValuesError &&
        Object.keys(formValues.prism).map((field) => {
          return (errorsToSet[field] = isAllValuesError);
        });
      const isError = isSomeValuesError || isAllValuesError;
      setShowPrismMissingValuesError(isError);
    }

    //New name prescription validation
    if (isNewPrescriptionNameErrorCondition() && formSubmitted) {
      errorsToSet["new_prescription_name"] = true;
      setShowNewPrescriptionNameError(true);
    }

    //Agreement validation
    if (enableAgreementCheckbox) {
      if (!isCalifornian && !agreement && isAgreementCheckboxEnabled) {
        errorsToSet["agreement"] = true;
      } else if (!isCalifornian && agreement && isAgreementCheckboxEnabled) {
        formErrors["agreement"] = false;
      }
    }

    //Issue Date
    if (
      withIssueDate &&
      (!isValidIssueDate(issueDate) || isExpiredPrescription(issueDate))
    ) {
      errorsToSet["issue_date"] = true;
    }
    if (Object.values(errorsToSet).some((error) => error)) {
      setFormErrors({
        ...formErrors,
        ...errorsToSet,
      });

      setTimeout(() => {
        const firstErrorElement = document.querySelector(
          ".SimpleTextError__message"
        );
        firstErrorElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 200);
    } else {
      if (
        !(showAxisRangeError || showAxisError || !PD.state.checkPDSelected())
      ) {
        if (isReading && ((sphOD && !addOD) || (sphOS && !addOS))) {
          reduxDispatch(modalsActions.setShowMissingReadingAddValueModal(true));
        } else sendPrescriptionData();
      }
    }
    //TODO verificare con Luca
    const isAllValuesError = Object.values(formValues.prism).every(
      (field) => !field.value
    );
    const checkMultipleErrors = () => {
      let count = 0;
      Object.values(errorsToSet).map((error: any) => {
        error === true && count++;
      });
      return count;
    };

    const errorCount = checkMultipleErrors();

    const checkEqualPrismErrors = () => {
      if (errorCount >= 2) {
        if (
          errorsToSet.right_eye_vertical === true &&
          errorsToSet.left_eye_vertical === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_baseDirVert === true &&
          errorsToSet.left_eye_baseDirVert === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_horizontal === true &&
          errorsToSet.left_eye_horizontal === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_baseDirHor === true &&
          errorsToSet.left_eye_baseDirHor === true
        ) {
          return true;
        } else if (
          errorsToSet.left_eye_baseDirVert === true &&
          errorsToSet.left_eye_baseDirHor === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_baseDirVert === true &&
          errorsToSet.right_eye_baseDirHor === true
        ) {
          return true;
        } else if (
          errorsToSet.right_eye_vertical === true &&
          errorsToSet.right_eye_horizontal === true
        ) {
          return true;
        } else if (
          errorsToSet.left_eye_vertical === true &&
          errorsToSet.left_eye_horizontal === true
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        true;
      }
    };
    const equalPrismErrors = checkEqualPrismErrors();
    if (isAllValuesError) {
      setPrismErrorMessage(missingValues);
    } else if (equalPrismErrors === false) {
      setPrismErrorMessage(missingValues);
    } else if (
      errorsToSet["right_eye_baseDirVert"] ||
      errorsToSet["left_eye_baseDirVert"] ||
      errorsToSet["left_eye_baseDirHor"] ||
      errorsToSet["right_eye_baseDirHor"]
    ) {
      setPrismErrorMessage(missingBaseDir);
    } else if (
      errorsToSet["right_eye_vertical"] ||
      errorsToSet["left_eye_vertical"] ||
      errorsToSet["right_eye_horizontal"] ||
      errorsToSet["left_eye_horizontal"]
    ) {
      setPrismErrorMessage(missingPrismValues);
    }
  };

  const preparePrescriptionToSubmit = () => {
    let prescription = advancedManualPrescriptionAdapter.adapt({
      ...prescriptionObject,
      prescriptionFlow: prescriptionObject?.myAccount
        ? PrescriptionModeEnum.ACCOUNT
        : PrescriptionModeEnum.MANUAL,
      prescriptionId:
        isEditMode && prescriptionObject?.prescriptionId
          ? prescriptionObject.prescriptionId
          : null,
      SPH_OD: sphOD,
      SPH_OS: sphOS,
      CYL_OD: cylOD,
      CYL_OS: cylOS,
      AX_OD: axisOD,
      AX_OS: axisOS,
      ADD_OD: addOD, //TODO testare se viene resettato in single vision
      ADD_OS: addOS,
      PD_OD: PD.state.PD1,
      PD_OS: PD.state.showBothPdOptions.value ? PD.state.PD2 : null,
      PRISM_ENABLED: showPrismValuesForm,
      VPRISM_OD: showPrismValuesForm ? vertOD : null,
      VPRISM_OS: showPrismValuesForm ? vertOS : null,
      VBASEDIR_OD: showPrismValuesForm ? baseVertOD : null,
      VBASEDIR_OS: showPrismValuesForm ? baseVertOS : null,
      HPRISM_OD: showPrismValuesForm ? horOD : null,
      HPRISM_OS: showPrismValuesForm ? horOS : null,
      HBASEDIR_OD: showPrismValuesForm ? baseHorOD : null,
      HBASEDIR_OS: showPrismValuesForm ? baseHorOS : null,
      COMMENT: comment,
      name: newPrescriptionName || prescriptionObject?.name,
      lastUpdate: prescriptionObject?.lastUpdate || new Date().toISOString(), //TOD
      issueDate: convertToISO(issueDate),
      rxType,
      POWER: selectedPower,
    });

    if (isCalifornian || prescriptionModule.prescriptionType === "NESTED") {
      if (selectedNested == "UPLOAD") {
        if (isSuccessful) {
          let fileSize = file?.size
            ? (parseInt(file.size) / 1024 / 1024).toFixed(2)
            : 0;
          prescription = {
            ...prescription,
            prescriptionId: prescriptionIdForCalifornia,
            prescriptionFlow: isCalifornian ? "NESTED_CA" : "NESTED",
            fileName: file?.name,
            savedFileName: preloadedFileInfo?.savedFileName,
            fileSize: fileSize,
          };
        }
      } else {
        if (doctorSelected) {
          prescription = {
            ...prescription,
            prescriptionFlow: "NESTED_CA",
            prescriptionId: prescriptionObject.prescriptionId
              ? prescriptionObject.prescriptionId
              : null,
            name: doctorSelected.name,
            clinic: doctorSelected.clinic,
            address: doctorSelected.address,
            phone: doctorSelected.phone,
            fax: doctorSelected.fax,
          };
        }
      }
    }
    if (
      isAddDisabled() &&
      prescription &&
      prescription.ADD &&
      (prescription.ADD.OD || prescription.ADD.OS)
    ) {
      prescription.ADD.OD = null;
      prescription.ADD.OS = null;
    }
    return prescription;
  };

  const sendPrescriptionData = () => {
    if (!prescriptionExternalFunctions) {
      console.log("prescriptionModule is missing");
      return;
    }
    if (!isFunction(prescriptionExternalFunctions.saveExtendedPrescription)) {
      console.log(
        "prescriptionModule.SaveExtendedPrescription is not a valid function"
      );
      return;
    }

    const prescription = preparePrescriptionToSubmit();
    // frame incompatible with prescription
    if (!isFramePrescriptionCompatible(frame.rxValues, prescription)) {
      try {
        //@ts-ignore
        window.tealium_data2track.push({
          id: "Error",
          Error_Source: "User",
          Error_Code: "RX Configurator: prescription values",
          Error_Details:
            "ADD:" +
            prescription.ADD +
            ",SPHOD:" +
            prescription.SPH.OD +
            ",SPHOS:" +
            prescription.SPH.OS,
          Error_Message:
            "Sorry! The frame you ve chosen isn t compatible with your prescription",
        });
      } catch (error) {
        console.error(
          "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
        );
      }
      setShowIncompatibleFrameError(true);
      return;
    }

    // lenses incompatibles with prescription
    if (
      !currentPackages.filter((f) => hasValidPrescription(f, prescription))
        .length
    ) {
      setShowIncompatiblesLensesError(true);
      return;
    }

    // save prescription
    prescriptionExternalFunctions
      .saveExtendedPrescription(prescription)
      .then((savedPrescription) => {
        onSuccessPrescription(savedPrescription);
        if (saveAccountPrescription && !isNewPrescriptionNameErrorCondition()) {
          saveToMyAccount(prescription);
          try {
            //@ts-ignore
            window.tealium_data2track.push({
              id: "Event",
              Rx_Release: useReleaseVersion(), // RX Panel release/version
              Events_SavePrescriptionEyewear: 1, // 1 if the user saved the prescription
              Event_Source: "RX Prescription Saved",
            });
          } catch (err) {
            console.error(
              "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
            );
          }
        }
      })
      .catch((err) => {
        console.log("Error while calling saveExtendedPrescription: " + err);
      });
  };

  const handleNewPrescriptionName = (e) => {
    e.preventDefault();
    setNewPrescriptionName(e.target.value);
  };

  /*  useEffect(() => {
    typeSelected = typeSelected.params[0]?.value;
    readingSelected = readingSelected.params[0]?.optionalAttributes?.value;
  }, []); */

  useEffect(() => {
    if (!isAxisOSZeroErrorCondition()) {
      setShowMissingAxisError(false);
    }
    if (!isAxisODZeroErrorCondition()) {
      setShowMissingAxisError(false);
    }
  }, [axisOD, axisOS]);

  useEffect(() => {
    if (axisOD === 0 || axisOS === 0) {
      setShowMissingAxisError(true);
    }
  }, [axisOD, axisOS]);

  useEffect(() => {
    if (sphOD !== 0 || sphOS !== 0) {
      formErrors["right_eye_sph"] = false;
      formErrors["left_eye_sph"] = false;
      setShowPrescriptionMissingValuesError(false);
    }
  }, [sphOD, sphOS]);

  // Use effect useful for load prescription object in case of nested flow
  useEffect(() => {
    if (
      prescriptionObject &&
      (prescriptionObject.prescriptionFlow == "NESTED_CA" ||
        prescriptionObject.prescriptionFlow == "NESTED" ||
        prescriptionObject?.isCalifornian) &&
      prescriptionObject.fileName &&
      !alreadyDone
    ) {
      setIsCalifornian(true);

      setVisibilityButtonUploaded(false);
      setSelectedNested(nestedFlow?.[0]);
      setIsUploading(false);
      setIsSuccessful(true);
      setFile({
        name: prescriptionObject.fileName,
        size: parseFloat(prescriptionObject.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(prescriptionObject.fileName),
      });
      setPreloadedFileInfo({
        name: prescriptionObject.fileName,
        size: parseFloat(prescriptionObject.fileSize) * 1024 * 1024,
        type: getFileTypeFromFullName(prescriptionObject.fileName),
        savedFileName: prescriptionObject.savedFileName,
      });
      if (prescriptionModule.downloadExtendedPrescription) {
        prescriptionModule
          .downloadExtendedPrescription({
            savedFileName: prescriptionObject.savedFileName,
          })
          .then((res) => {
            setFilePath(res.fileData);
          })
          .catch((err) => {
            setFilePath("");
          });
      } else {
        console.error("Missing downloadExtendedPrescriptionFunction");
        setFilePath("");
      }
    }
    if (
      prescriptionObject &&
      (prescriptionObject.prescriptionFlow == "NESTED_CA" ||
        prescriptionObject.prescriptionFlow == "NESTED") &&
      prescriptionObject.name &&
      prescriptionObject.phone &&
      !alreadyDone
    ) {
      setIsCalifornian(true);
      setSelectedNested(nestedFlow[1]);
      reduxDispatch(
        modalsActions.setDoctorSelected({
          name: prescriptionObject.name,
          clinic: prescriptionObject.clinic,
          address: prescriptionObject.address,
          phone: prescriptionObject.phone,
          fax: prescriptionObject.fax,
        })
      );
    }
    setAlreadyDone(true);
  }, [prescriptionObject]);

  //Initialize prescription state with props
  useEffect(() => {
    if (prescriptionObject) {
      let prescObj = prescriptionObject;
      if (prescObj.SPH) {
        if (prescObj.SPH.OD || prescObj.SPH.OD === 0) {
          setSphOD(prescObj.SPH.OD);
        }
        if (prescObj.SPH.OS || prescObj.SPH.OS === 0) {
          setSphOS(prescObj.SPH.OS);
        }
      }
      if (prescObj.CYL) {
        if (prescObj.CYL.OD || prescObj.CYL.OD === 0) {
          setCylOD(prescObj.CYL.OD);
        }
        if (prescObj.CYL.OS || prescObj.CYL.OS === 0) {
          setCylOS(prescObj.CYL.OS);
        }
      }
      if (prescObj.AX) {
        if (prescObj.AX.OD === 0) {
          setAxisOD(null);
        } else {
          setAxisOD(parseInt(prescObj.AX.OD));
        }
        if (prescObj.AX.OS === 0) {
          setAxisOS(null);
        } else {
          setAxisOS(parseInt(prescObj.AX.OS));
        }
      }
      if (prescObj.ADD) {
        if (prescObj.ADD.OD !== null && prescObj.ADD.OD !== undefined) {
          setAddOD(
            parseFloat(
              rxType?.toLowerCase().includes("single") ? null : prescObj.ADD.OD
            )
          );
        }
        if (prescObj.ADD.OS !== null && prescObj.ADD.OS !== undefined) {
          setAddOS(
            parseFloat(
              rxType?.toLowerCase().includes("single") ? null : prescObj.ADD.OS
            )
          );
        }
      }

      if (prescObj.issueDate && withIssueDate) {
        const month = String(new Date(prescObj.issueDate).getMonth() + 1);
        const year = new Date(prescObj.issueDate).getFullYear();
        setIssueDate(`${month.length === 1 ? `0${month}` : month}/${year}`);
      }

      if (prescriptionObject.name && isEditMode) {
        setNewPrescriptionName(prescriptionObject.name);
      }
      let isPrismEnabled =
        typeof prescObj.PRISM_ENABLED === "string"
          ? prescObj.PRISM_ENABLED.toLowerCase() === "true"
          : prescObj.PRISM_ENABLED;
      if (isPrismEnabled) {
        toggleMoreOptionsOpen();
        if (prescObj.HPRISM) {
          if (prescObj.HPRISM.OD) {
            setHorOD(parseFloat(prescObj.HPRISM.OD));
          }
          if (prescObj.HPRISM.OS) {
            setHorOS(parseFloat(prescObj.HPRISM.OS));
          }
        }
        if (prescObj.HBASEDIR) {
          if (prescObj.HBASEDIR.OD) {
            setBaseHorOD(prescObj.HBASEDIR.OD);
          }
          if (prescObj.HBASEDIR.OS) {
            setBaseHorOS(prescObj.HBASEDIR.OS);
          }
        }
        if (prescObj.VPRISM) {
          if (prescObj.VPRISM.OD) {
            setVertOD(parseFloat(prescObj.VPRISM.OD));
          }
          if (prescObj.VPRISM.OS) {
            setVertOS(parseFloat(prescObj.VPRISM.OS));
          }
        }
        if (prescObj.VBASEDIR) {
          if (prescObj.VBASEDIR.OD) {
            setBaseVertOD(prescObj.VBASEDIR.OD);
          }
          if (prescObj.VBASEDIR.OS) {
            setBaseVertOS(prescObj.VBASEDIR.OS);
          }
        }

        if (prescObj.COMMENT) {
          setComment(prescObj.COMMENT);
        }

        setShowPrismValuesForm(true);
        reduxDispatch(prescriptionActions.setIsPrismPriceAdded(true));
      }
      setLoadedExternally(false);
      setTimeout(() => (isComponentInitialised.current = true), 100);
    }
  }, [prescriptionObject, isEditMode]);

  useEffect(() => {
    if (
      (rxType.toLowerCase().includes("progressive") ||
        rxType.toLowerCase().includes("reading")) &&
      prescriptionObject?.myAccount &&
      (!prescriptionObject?.ADD ||
        ([0, null, NaN].includes(prescriptionObject.ADD?.OD) &&
          [0, null, NaN].includes(prescriptionObject.ADD?.OS)))
    ) {
      setShowIncompatibleLensTypeError({ show: true, key: "Progressive" });
    } else if (
      (rxType?.toLowerCase().includes("single") ||
        rxType?.toLowerCase().includes("distance")) &&
      prescriptionObject?.myAccount &&
      prescriptionObject?.ADD &&
      (prescriptionObject?.ADD.OD > 0 || prescriptionObject?.ADD.OS > 0)
    ) {
      setShowIncompatibleLensTypeError({ show: true, key: "Single" });
    }
  }, [prescriptionObject]);

  useEffect(() => {
    if (
      (sphOD > 0 && sphOS < 0) ||
      (sphOD < 0 && sphOS > 0) ||
      (cylOD > 0 && cylOS < 0) ||
      (cylOD < 0 && cylOS > 0)
    ) {
      setShowSPHCYLWarning(true);
    } else {
      if (showSPHCYLWarning) {
        setShowSPHCYLWarning(false);
      }
    }
  }, [sphOD, sphOS, cylOD, cylOS]);

  useEffect(() => {
    let errorsToSet = {};
    if (isAxisODZeroErrorCondition()) {
      errorsToSet["right_eye_axis"] = true;
      setShowAxisError(true);
    }
    if (isAxisOSZeroErrorCondition()) {
      errorsToSet["left_eye_axis"] = true;
      setShowAxisError(true);
    }
    if (isAxisODRangeErrorCondition()) {
      errorsToSet["right_eye_axis"] = true;
      setShowMissingAxisError(false);
      setShowAxisRangeError(true);
    }
    if (isAxisOSRangeErrorCondition()) {
      errorsToSet["left_eye_axis"] = true;
      setShowAxisRangeError(true);
      setShowMissingAxisError(false);
    }

    resetAxisErrors();

    if (Object.keys(errorsToSet).length) {
      setFormErrors({
        ...formErrors,
        ...errorsToSet,
      });
    } else {
      resetAxisErrors();
    }
  }, [axisOD, axisOS]);

  useEffect(() => {
    if (!loadedExternally) {
      if (cylOD === 0) {
        setAxisOD(null);
      }
      if (cylOS === 0) {
        setAxisOS(null);
      }
      resetAxisErrors();
    }
  }, [cylOD, cylOS]);

  /* useEffect(() => {
    if (prescriptionObject) {
      if (cylOD === 0) {
        setAxisOD(null);
      }
      if (cylOS === 0) {
        setAxisOS(null);
      }
      resetAxisErrors();
    }
  }, []); */

  useEffect(() => {
    let errorsToSet = {};
    if (isNewPrescriptionNameErrorCondition() && formSubmitted) {
      errorsToSet["new_prescription_name"] = true;
      setShowNewPrescriptionNameError(true);
    }
  }, [formSubmitted]);

  useEffect(() => {
    let errorsToSet = {};
    if (addOD !== null && addOD !== undefined && !isNaN(addOD)) {
      if (formValues.prescription.right_eye_add.hasError) {
        errorsToSet["right_eye_add"] = false;
      }
    }
    if (addOS !== null && addOS !== undefined && !isNaN(addOS)) {
      if (formValues.prescription.left_eye_add.hasError) {
        errorsToSet["left_eye_add"] = false;
      }
    }
    if (enableZeroSphere) {
      if (
        (addOD !== null && !isNaN(addOD)) ||
        (addOS !== null && !isNaN(addOS))
      ) {
        errorsToSet["right_eye_add"] = false;
        errorsToSet["left_eye_add"] = false;
        setShowPrescriptionMissingValuesError(false);
      }
    }
    if (Object.keys(errorsToSet).length) {
      setFormErrors({
        ...formErrors,
        ...errorsToSet,
      });
    }
  }, [addOD, addOS]);

  //TODO chiedere se va modificato il coportamento
  useEffect(() => {
    function checkIfPropertyChanged(property, nameProp) {
      return property != get(prescriptionObject, nameProp);
    }

    if (!isComponentInitialised.current) return;

    if (
      checkIfPropertyChanged(sphOS, "SPH.OS") ||
      checkIfPropertyChanged(sphOD, "SPH.OD") ||
      checkIfPropertyChanged(cylOS, "CYL.OS") ||
      checkIfPropertyChanged(cylOD, "CYL.OD") ||
      checkIfPropertyChanged(axisOD, "AX.OD") ||
      checkIfPropertyChanged(axisOS, "AX.OS") ||
      checkIfPropertyChanged(addOD, "ADD.OD") ||
      checkIfPropertyChanged(addOS, "ADD.OS") ||
      checkIfPropertyChanged(PD.state.PD1, "PD.OD") ||
      checkIfPropertyChanged(PD.state.PD2, "PD.OS")

      //TODO add Prism Values
    )
      setIsPrescriptionModified(true);
  }, [
    sphOS,
    sphOD,
    cylOD,
    cylOS,
    axisOD,
    axisOS,
    addOD,
    addOS,
    vertOD,
    vertOS,
    baseVertOD,
    baseVertOS,
    horOD,
    horOS,
    baseHorOD,
    baseHorOS,
    PD.state.PD1,
    PD.state.PD2,
  ]);

  // useEffect to unset errors on prism values
  useEffect(() => {
    if (vertOD) {
      formErrors["right_eye_vertical"] = false;
    }
    if (vertOS) {
      formErrors["left_eye_vertical"] = false;
    }
    if (baseVertOD) {
      formErrors["right_eye_baseDirVert"] = false;
    }
    if (baseVertOS) {
      formErrors["left_eye_baseDirVert"] = false;
    }
    if (horOD) {
      formErrors["right_eye_horizontal"] = false;
    }
    if (horOS) {
      formErrors["left_eye_horizontal"] = false;
    }
    if (baseHorOD) {
      formErrors["right_eye_baseDirHor"] = false;
    }
    if (baseHorOS) {
      formErrors["left_eye_baseDirHor"] = false;
    }
    var count = 0;
    for (const [key, value] of Object.entries(formErrors)) {
      value === true ? (count += 1) : "";
    }
    count === 0 ? setShowPrismMissingValuesError(false) : "";
  }, [
    vertOD,
    vertOS,
    baseVertOD,
    baseVertOS,
    horOD,
    horOS,
    baseHorOD,
    baseHorOS,
  ]);

  useEffect(() => {
    if (formSubmitted) {
      validateSubmittedData();
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  useEffect(() => {
    if (sendWithoutPrescriptionValues) {
      reduxDispatch(modalsActions.setMissingPrescriptionsValueResult(false));
      let newPres: any = {
        PD: {
          OD: PD.state.PD1,
          OS: PD.state.PD2,
        },
      };
      if (selectedNested == "UPLOAD") {
        if (isSuccessful) {
          let fileSize = file?.size
            ? (parseInt(file.size) / 1024 / 1024).toFixed(2)
            : 0;
          newPres = {
            ...newPres,
            prescriptionId: prescriptionIdForCalifornia,
            prescriptionFlow: "NESTED_CA",
            fileName: file?.name,
            savedFileName: preloadedFileInfo?.savedFileName,
            fileSize: fileSize,
          };
        }
      } else {
        if (doctorSelected) {
          newPres = {
            ...newPres,
            prescriptionFlow: "NESTED_CA",
            prescriptionId: 12345,
            name: doctorSelected.name,
            clinic: doctorSelected.clinic,
            address: doctorSelected.address,
            phone: doctorSelected.phone,
            fax: doctorSelected.fax,
          };
        }
      }
      if (
        typeof prescriptionExternalFunctions.saveExtendedPrescription ===
        "function"
      ) {
        prescriptionExternalFunctions
          .saveExtendedPrescription(newPres)
          .then((res) => {
            onSuccessPrescription(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [sendWithoutPrescriptionValues]);

  useEffect(() => {
    if (showPrescriptionMissingValuesError) {
      getAxisError();
    }
  }, [showPrescriptionMissingValuesError]);

  useEffect(() => {
    if (isEditMode && prescriptionObject?.SPH) {
      if (!isFramePrescriptionCompatible(frame.rxValues, prescriptionObject)) {
        setShowIncompatibleFrameError(true);
        return;
      }
      if (
        !currentPackages.filter((f) =>
          hasValidPrescription(f, prescriptionObject)
        ).length
      ) {
        setShowIncompatiblesLensesError(true);
        return;
      }
    }
  }, [isEditMode]);

  useEffect(() => {
    const load = async () => {
      const compatibleFramesResult =
        prescriptionExternalFunctions?.checkAvailableFrames(
          frame,
          prescriptionObject
        );

      if (!compatibleFramesResult) {
        //TODO log something
        return;
      }
      if (!(compatibleFramesResult instanceof Promise)) {
        setCompatibleFrame(compatibleFramesResult);
      } else if (compatibleFramesResult instanceof Promise) {
        const compatibleFrameUrl = await compatibleFramesResult;
        if (compatibleFrameUrl) {
          setCompatibleFrame(compatibleFrameUrl);
        }
      }
    };

    prescriptionExternalFunctions?.checkAvailableFrames && load();
  }, [prescriptionObject, prescriptionExternalFunctions?.checkAvailableFrames]);

  // same prescription for both eyes
  useEffect(() => {
    if (samePrescriptionBothEyes) {
      if (sphOD && samePrescriptionFrom !== "OS") {
        setSphOS(sphOD);
        setCylOS(cylOD);
        setAxisOS(axisOD);
        setAddOS(addOD);
        setSamePrescriptionFrom("OD");
        console.log(samePrescriptionFrom);
      } else if (sphOS && samePrescriptionFrom !== "OD") {
        setSphOD(sphOS);
        setCylOD(cylOS);
        setAxisOD(axisOS);
        setAddOD(addOS);
        setSamePrescriptionFrom("OS");
        console.log(samePrescriptionFrom);
      } else {
        setSamePrescriptionFrom("");
      }
    }
  }, [
    sphOD,
    cylOD,
    axisOD,
    addOD,
    sphOS,
    cylOS,
    axisOS,
    addOS,
    samePrescriptionBothEyes,
  ]);

  const ApplyButton = (props: { isSaving?: boolean; disabled?: boolean }) => {
    const applyButtonLabel = useTranslate(
      "steps.advancedPrescription.manual.applyButton"
    );
    const applyButtonSaveLabel = useTranslate(
      "steps.advancedPrescription.manual.applyButtonSave"
    );
    return (
      <div className="FullPrescriptionForm__innerContainer__apply">
        <GenericButton
          id={"submitButtonId"}
          className={"FullPrescriptionForm__innerContainer__applyButton"}
          noArrow
          type="submit"
          dataElementId="X_X_LensPanel_Type-ValuesApply"
          dataAnalyticsAvailableCall="0"
          title={
            props.isSaving && !props.disabled
              ? applyButtonSaveLabel
              : applyButtonLabel
          }
          tabIndex={0}
          disabled={props.disabled}
        >
          {props.isSaving && !props.disabled
            ? applyButtonSaveLabel
            : applyButtonLabel}
        </GenericButton>
      </div>
    );
  };
  console.log(samePrescriptionFrom);
  return (
    <div className="FullPrescriptionForm">
      {rxType === "READER" ? (
        <>
          <form
            className={"FullPrescriptionForm__innerContainer__form"}
            onSubmit={onSubmit}
          >
            <div className="FullPrescriptionForm__innerContainer__form__powersTable">
              {getSimplePrescriptionValuesArray(1, 3, 0.25).map((p) => (
                <div
                  onClick={() => {
                    setNoPowerSelectedError(false);
                    setSelectedPower(p.value);
                  }}
                  className={
                    selectedPower === p.value
                      ? "selected"
                      : noPowerSelectedError
                        ? "error"
                        : ""
                  }
                >
                  +{p.value}
                </div>
              ))}
            </div>

            {noPowerSelectedError && (
              <SimpleTextError message={missingPowerError} />
            )}

            <ApplyButton />
          </form>
        </>
      ) : (
        <>
          {withPrescriptionsFromMyAccount === false &&
            userIsLoggedIn &&
            noPrescriptionsFound && (
              <p className="FullPrescriptionForm__withoutPrescriptionWarning">
                <div className="FullPrescriptionForm__withoutPrescriptionWarning__tooltip"></div>
                {withoutPrescriptionFromMyAccountLabel}
              </p>
            )}
          {showIncompatibleFrameError && (
            <IncompatibleFrame
              withSelectNewFrame={compatibleFrame}
              withClearAll={!compatibleFrame && !prescriptionObject?.myAccount}
              onClick={
                !compatibleFrame && !prescriptionObject?.myAccount
                  ? resetForm
                  : () => {
                    window.location.replace(compatibleFrame || "");
                  }
              }
            />
          )}
          {showIncompatiblesLensesError && <IncompatibleLenses />}
          {showIncompatibleLensTypeError.show && (
            <IncompatibleLensType
              type={
                showIncompatibleLensTypeError?.key &&
                showIncompatibleLensTypeError?.key
              }
            />
          )}
          {isEditMode && userIsLoggedIn && (
            <div className="SaveAccountPrescription__inputContainer">
              {brand === "framesdirect" && (
                <label className="SaveAccountPrescription__inputContainer__label">
                  {prescriptionName}*
                </label>
              )}
              {brand === "targetoptical" ?
                (<PrescriptionNameInput
                  value={newPrescriptionName}
                  onChange={(value) => setNewPrescriptionName(value)}
                  labelStyle={{ top: "-14px", position: "absolute", whiteSpace: "pre", left: "-17px" }}
                />) :
                (<input
                  className={
                    !showNewPrescriptionNameError
                      ? "SaveAccountPrescription__inputContainer__input"
                      : "SaveAccountPrescription__inputContainer__input redBorder"
                  }
                  type="text"
                  placeholder={prescriptionName + "*"}
                  value={newPrescriptionName}
                  onChange={(e) => handleNewPrescriptionName(e)}
                />)}
              {showNewPrescriptionNameError && (
                <>
                  <div className="SaveAccountPrescription__inputContainer__errorIcon" />
                  <div className="SaveAccountPrescription__inputContainer__error">
                    {prescriptionNameError}
                  </div>
                </>
              )}
            </div>
          )}

          {isEditMode && withIssueDate && (
            <IssueDateInput
              value={issueDate}
              onChange={(value) => setIssueDate(value)}
              missingError={formErrors["issue_date"]}
            />
          )}
          {brand !== "targetoptical" && !showIncompatiblesLensesError && !showIncompatibleFrameError && (
            <div className={"FullPrescriptionForm__subtitle"}>{subtitle}</div>
          )}
          {brand === "targetoptical" && !showIncompatiblesLensesError && !showIncompatibleFrameError && !isEditMode && (
            <div className={"FullPrescriptionForm__subtitle"}>{subtitle}</div>
          )}
          {brand !== "targetoptical" && <a
            tabIndex={0}
            className={"FullPrescriptionForm__readInfo"}
            data-element-id="X_X_LensPanel_Type-ValuesHowToRead"
            onClick={() => {
              // temporary commment
              //reduxDispatch(modalsActions.setShowPrescriptionModalInfo(true))
              reduxDispatch(
                prescriptionActions.setCurrentPrescription({
                  ...preparePrescriptionToSubmit(),
                  samePrescriptionBothEyes: samePrescriptionBothEyes,
                })
              );
              reduxDispatch(
                modalsActions.setShowPrescriptionModalHowToRead(true)
              );
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                reduxDispatch(
                  prescriptionActions.setCurrentPrescription({
                    ...preparePrescriptionToSubmit(),
                    samePrescriptionBothEyes: samePrescriptionBothEyes,
                  })
                );
                reduxDispatch(modalsActions.setShowPrescriptionModalHowToRead(true));
              }
              if (e.key == "Escape") {
                reduxDispatch(
                  prescriptionActions.setCurrentPrescription({
                    ...preparePrescriptionToSubmit(),
                    samePrescriptionBothEyes: samePrescriptionBothEyes,
                  })
                );
                reduxDispatch(modalsActions.setShowPrescriptionModalHowToRead(false));
              }
            }}
          >
            {howToRead}
          </a>}
          {brand === "targetoptical" && !isEditMode && <a
            tabIndex={0}
            className={"FullPrescriptionForm__readInfo"}
            data-element-id="X_X_LensPanel_Type-ValuesHowToRead"
            onClick={() => {
              // temporary commment
              //reduxDispatch(modalsActions.setShowPrescriptionModalInfo(true))
              reduxDispatch(
                prescriptionActions.setCurrentPrescription({
                  ...preparePrescriptionToSubmit(),
                  samePrescriptionBothEyes: samePrescriptionBothEyes,
                })
              );
              reduxDispatch(
                modalsActions.setShowPrescriptionModalHowToRead(true)
              );
            }}
          >
            {howToRead}
          </a>}
          {brand !== "targetoptical" && <div className="FullPrescriptionForm__samePrescriptionBothEyes__checkboxContainer">
            <CustomCheckbox
              value={samePrescriptionBothEyes}
              setter={setSamePrescriptionBothEyes}
              checkboxLabel={samePrescriptionBothEyesLabel}
            />

            <span className="FullPrescriptionForm__samePrescriptionBothEyes__checkBoxText">
              {samePrescriptionBothEyesLabel}
            </span>
          </div>}
          <form
            className={"FullPrescriptionForm__innerContainer__form"}
            onSubmit={onSubmit}
          >
            <BrandComponent
              componentName="FormTable"
              parameter={{
                columns: prescriptionTableColumns,
                selector: "prescription",
                cylOD: cylOD,
                cylOS: cylOS,
                isAddDisabled: isAddDisabled,
                onSubmit: onSubmit,
                formErrors: formErrors,
                selectPlaceholder: selectPlaceholder,
                formValues: formValues,
                samePrescriptionBothEyes: samePrescriptionBothEyes,
                samePrescriptionFrom: samePrescriptionFrom,
              }}
            />
            {showPrescriptionMissingValuesError && (
              <SimpleTextError message={missingValues} />
            )}
            {showMissingAxisError && (
              <SimpleTextError message={axisZeroError} />
            )}
            {showAxisRangeError && <SimpleTextError message={axisRangeError} />}
            {showSPHCYLWarning && (
              <BrandComponent
                componentName="WarningBanner"
                parameter={{
                  text: sphPositiveNegativeWarning,
                }}
              />
            )}
            {!hideMoreOptions && (enablePrismComment || enablePrism) && (
              <MoreOptions
                onSubmit={onSubmit}
                isMoreOptionsFormOpen={isMoreOptionsFormOpen}
                toggleMoreOptionsOpen={toggleMoreOptionsOpen}
                showPrismValuesForm={showPrismValuesForm}
                showPrismMissingValuesError={showPrismMissingValuesError}
                comment={comment}
                setComment={setComment}
                errorMessage={<SimpleTextError message={prismErrorMessage} />}
                checkbox={
                  <CustomCheckbox
                    value={showPrismValuesForm}
                    setter={(v) => {
                      setShowPrismValuesForm(v);
                      if (enablePrism && !enablePrismComment) {
                        setIsMoreOptionsFormOpen(v);
                      }
                      reduxDispatch(
                        prescriptionActions.setIsPrismPriceAdded(v)
                      );
                    }}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                  />
                }
                prescriptionDefaults={prescriptionDefaults}
                cylOD={cylOD}
                cylOS={cylOS}
                isAddDisabled={isAddDisabled}
                formErrors={formErrors}
                formValues={formValues}
                selectPlaceholder={selectPlaceholder}
              />
            )}
            {showMissingReadingAddValueModal &&
              createPortal(
                <PrescriptionModalNoAdd onContinue={sendPrescriptionData} />,
                //@ts-ignore
                document.querySelector("#RxcModals")
              )}
            <Separator />
            <BrandComponent
              componentName="PupillaryDistance"
              parameter={{
                PD1: PD.state.PD1,
                PD2: PD.state.PD2,
                showBothPdOptions: PD.state.showBothPdOptions,
                showPDWarning: PD.state.showPDWarning,
                onPD1Change: PD.state.setPD1,
                onPD2Change: PD.state.setPD2,
                setShowBothPdOptions: PD.state.setShowBothPdOptions,
                pdValueNotSelectedError:
                  (brand === "opsm" || enableDigitalOptometry) &&
                    !PD.state.PD1 &&
                    clicked
                    ? true
                    : false,
                CustomCheckbox: CustomCheckbox,
              }}
            />
            <Separator />
            {
              //California
              translation == "en_US" &&
              prescriptionModule?.prescriptionType === "NESTED_CA" && (
                <BrandComponent
                  componentName="CaliforniaManualInput"
                  path="FullPrescriptionForm/CaliforniaManualInput"
                  parameter={{
                    doctorSelected,
                    nestedFlow,
                    isCalifornian,
                    handleSwitch,
                    visibilityButtonUploaded,
                    onUploadChange: handleFileChange,
                    isUploading,
                    file,
                    selectedNested,
                    isSuccessful,
                    isFileTooBigError,
                    filePath,
                    preloadedFileInfo,
                    prescriptionModule,
                    prescriptionExternalFunctions,
                    onSelectNested: (el) => setSelectedNested(el),
                    openModal: () =>
                      reduxDispatch(
                        modalsActions.setShowCallMyDoctorCalifornianModal(
                          true
                        )
                      ),
                  }}
                />
              )
            }
            {prescriptionModule?.prescriptionType === "NESTED" && (
              <BrandComponent
                componentName="CanadaManualInput"
                path="FullPrescriptionForm/CanadaManualInput"
                parameter={{
                  doctorSelected,
                  nestedFlow,
                  visibilityButtonUploaded,
                  onUploadChange: handleFileChange,
                  isUploading,
                  file,
                  selectedNested,
                  isSuccessful,
                  isFileTooBigError,
                  filePath,
                  preloadedFileInfo,
                  prescriptionModule,
                  prescriptionExternalFunctions,
                  onSelectNested: (el) => setSelectedNested(el),
                  openModal: () =>
                    reduxDispatch(
                      modalsActions.setShowCallMyDoctorCalifornianModal(true)
                    ),
                }}
              />
            )}
            {!isEditMode && withIssueDate && (
              <IssueDateInput
                value={issueDate}
                onChange={(value) => setIssueDate(value)}
                missingError={formErrors["issue_date"]}
              />
            )}
            {userIsLoggedIn && !isEditMode && (
              //MyAccount
              <div className="SaveAccountPrescription">
                <div className="SaveAccountPrescription__checkbox">
                  <CustomCheckbox
                    value={saveAccountPrescription}
                    setter={setSaveAccountPrescription}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    disabled={!isSavePrescriptionCheckboxEnabled}
                  />
                  <label
                    className={`SaveAccountPrescription__checkbox__label ${isSavePrescriptionCheckboxEnabled ? "" : "disabled"
                      }`}
                    onClick={() =>
                      isSavePrescriptionCheckboxEnabled &&
                      setSaveAccountPrescription(!saveAccountPrescription)
                    }
                  >
                    {savePrescriptionInAccount}
                  </label>
                </div>
                {saveAccountPrescription && (
                  <div
                    className="SaveAccountPrescription__inputContainer"
                    style={
                      prescriptionObject?.myAccount ? { marginTop: "40px" } : {}
                    }
                  >
                    {/* <label className="SaveAccountPrescription__inputContainer__label">
                  {prescriptionName}*
                </label> */}
                    {brand === "targetoptical" ?
                      (<PrescriptionNameInput
                        value={newPrescriptionName}
                        onChange={(value) => setNewPrescriptionName(value)}
                      />) :
                      (<input
                        className={
                          !showNewPrescriptionNameError
                            ? "SaveAccountPrescription__inputContainer__input"
                            : "SaveAccountPrescription__inputContainer__input redBorder"
                        }
                        type="text"
                        placeholder={prescriptionName + "*"}
                        value={newPrescriptionName}
                        onChange={(e) => handleNewPrescriptionName(e)}
                      />)}

                    {showNewPrescriptionNameError && (
                      <>
                        <div className="SaveAccountPrescription__inputContainer__errorIcon" />
                        <div className="SaveAccountPrescription__inputContainer__error">
                          {prescriptionNameError}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
            {!isCalifornian && enableAgreementCheckbox && (
              <AgreementCheckbox
                agreement={agreement}
                setAgreement={setAgreement}
                formErrors={formErrors}
                disabled={!isAgreementCheckboxEnabled}
                tooltipText={tooltipText}
              />
            )}
            {isCalifornian ||
              prescriptionModule.prescriptionType === "NESTED" ? (
              (selectedNested == "UPLOAD" && isSuccessful) ||
                (selectedNested == "CMD" && doctorSelected) ? (
                <ApplyButton />
              ) : (
                <></>
              )
            ) : (
              <ApplyButton
                isSaving={saveAccountPrescription || isEditMode}
                disabled={
                  showIncompatibleFrameError || showIncompatiblesLensesError
                }
              />
            )}
          </form>

          {brand === "arnette" && (
            <ReferencedSection name={"ScrollView__" + workflow.currentStep} />
          )}
        </>
      )}
    </div>
  );
}
