import React from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";

export const Banner = () => {
  const text = useTranslate(
    "steps.advancedPrescription.account.defaultPrescriptionBanner"
  );
  return (
    <div className="Banner__container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
      >
        <g clip-path="url(#clip0_5043_234)">
          <path
            d="M7.98282 3.02867C7.94299 2.91 7.83915 2.823 7.71532 2.80433L5.35982 2.4445L4.30199 0.191667C4.24699 0.0746667 4.12949 0 4.00015 0C3.87099 0 3.75332 0.0746667 3.69849 0.191667L2.64049 2.44467L0.283987 2.80467C0.160654 2.8235 0.0571537 2.9105 0.017487 3.02883C-0.0223463 3.1475 0.00782038 3.27967 0.0953204 3.36917L1.81015 5.12583L1.40465 7.61117C1.38899 7.70767 1.41649 7.807 1.47999 7.8815C1.54332 7.956 1.63682 7.99917 1.73465 7.99917C1.79132 7.99917 1.84749 7.98467 1.89699 7.95717L4.00015 6.795L6.10549 7.9585C6.14332 7.9795 6.18499 7.99283 6.22799 7.99783C6.24099 7.99917 6.25382 8 6.26665 8C6.35665 8 6.44365 7.9635 6.50699 7.89783C6.57982 7.82217 6.61282 7.7165 6.59599 7.61283L6.19015 5.12583L7.90499 3.36867C7.99232 3.27933 8.02249 3.14733 7.98282 3.02867Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_5043_234">
            <rect width="8" height="8" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <p>{text}</p>
    </div>
  );
};
