import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "./components/common/BrandComponent";
import {
  loadBrandConfig,
  mapConfigStepToStep,
  mixAndMatchasFirstStep,
  remapStepChainOnBrand,
  sunRule,
} from "./configurator/ConfigLoader";
import { useStepAnalytics } from "./configurator/hooks/useStepAnalytics";
import { WorkflowStep } from "./configurator/views/WorkflowStep";
import { configActions } from "./redux/slices/config";
import { modalsActions } from "./redux/slices/modals";
import "./index.scss";
import {
  loadTranslationConfig,
  useTranslate,
  useTranslation,
} from "./configurator/translations/TranslationHooks";
import { translationActions } from "./redux/slices/translation";
import { cloneDeep, merge } from "lodash";
import { prescriptionActions } from "./redux/slices/prescription";
import { workflow } from "./configurator/models/Workflow";
import { WarrantyOptionConfigModel } from "./components/WarrantyOptions/component";
import { insuranceActions } from "./redux/slices/insurance";
import { Prescription } from "./types";
import { useRIA } from "./configurator/modules/RIA/hooks/useRIA";
import { workflowActions } from "./redux/slices/workflow";
import { ErrorBoundary } from "./components/ErrorBoundary/component";
import {
  useContextualMessage,
  useEnableBrandLastStep,
  useMixAndMatch,
  usePreCheck,
  useProtectionPlan,
  useReviewSteps,
  useVirtualMirror,
} from "./configurator/hooks";
import useDebuggerUtils from "./configurator/hooks/useDebuggerUtils";
import {
  useCurrentBrand,
  useCurrentType,
  useExternalListener,
  useImagery,
} from "@hooks";
import { sendMonetateEvent } from "./configurator/utils/sendMonetateEvent";
import { mixAndMatchActions } from "./redux/slices/mixAndMatch";
import DebugConsole, {
  DebugConsoleProvider,
} from "./components/DebugConsole/DebugConsole";
import useGetReviewObjectLensAdvisor from "./configurator/hooks/useGetReviewObjectLensAdvisor";
import { useCookieLensAdvisor } from "./configurator/hooks/useCookieLensAdvisor";
import useLensAdvisor from "./configurator/hooks/useLensAdvisor";

export const CURRENT_BRAND =
  process.env.CURRENT_BRAND === "default" ? null : process.env.CURRENT_BRAND;

interface AppProps {
  config?: any;
}
/**
 * This is the app component.
 */

export function App({ config }: AppProps) {
  const [showInsurancePopup, setShowInsurancePopup] = useState(false);

  const showDigitalOptometryModal = useSelector(
    (state: any) => state.modals?.showDigitalOptometryModal
  );
  // deprecated data.frame.category !!!
  // const currentCategory = useSelector(
  //   (state: any) => state?.config?.data?.frame?.category
  // );
  const cookieLA = useCookieLensAdvisor();
  const currentCategory = () => {
    const pkgs = config?.lensesData?.packages?.filter(
      (pkg: any) => pkg.lensPackage.type.toLowerCase() !== "frame only"
    );
    return (
      (pkgs?.every((p) => sunRule(p.lensPackage)) && "SUNGLASSES") ||
      "EYEGLASSES"
    );
  };
  const showHowToReadModal = useSelector(
    (state: any) => state.modals?.showPrescriptionModalHowToRead
  );
  const showVirtualMirror = useSelector(
    (state: any) => state.virtualMirror?.showVirtualMirror
  );
  const exitModalState = useSelector(
    (state: any) => state.modals?.showExitModal
  );
  const showInsuranceErrorPopup = useSelector(
    (state: any) => state.modals?.showInsuranceErrorPopup
  );
  const prescriptionIncompatibleModalState = useSelector(
    (state: any) => state.modals?.showPrescriptionModal
  );
  const proceedWithNoPowerModalState = useSelector(
    (state: any) => state.modals?.showProceedWithNoPowerModal
  );
  const openPrescriptionInputModal = useSelector(
    (state: any) => state.modals?.openPrescriptionInputModal
  );
  const modalLearnMoreData = useSelector(
    (state: any) => state.modals?.learnMoreFocus
  );
  const addMyDoctorModalState = useSelector(
    (state: any) => state.modals?.showAddMyDoctorModal
  );
  const uploadFilePreviewModalState = useSelector(
    (state: any) => state.modals?.showPrescriptionUploadFilePreviewModal
  );
  const showSeeDetailsPrescriptionModal = useSelector(
    (state: any) => state.modals?.showPrescriptionReviewSeeDetails
  );
  const showBundleReviewSeeDetailsModal = useSelector(
    (state: any) => state.modals?.showBundleReviewSeeDetails
  );
  const showPaymentInstallmentsModal = useSelector(
    (state: any) => state.modals?.showPaymentInstallmentsModal
  );
  const showConfiguratorError = useSelector(
    (state: any) => state.modals?.showConfigError
  );
  const configAction = useSelector((state: any) => state.config?.actionsModule);
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const warrantyOptions: WarrantyOptionConfigModel[] = useSelector(
    (state: any) => state.config?.lensesData?.warrantyOptions
  );
  const cartMode = useSelector((state: any) => state.config?.cartMode);
  const warrantyByCart = useSelector(
    (state: any) => state.config?.data?.warranty?.catEntryId
  );
  const advancedPresIsShow = useSelector(
    (state: any) => state.workflow?.selectedPrescriptionMode
  );
  const reviewObjectForCart = useSelector(
    (state: any) => state.workflow?.reviewObjectForCart
  );
  const flag = useRef(false);
  const reduxDispatch = useDispatch();
  const reviewSteps = useReviewSteps();
  const enableEPPStep = useProtectionPlan();
  const brandLastStep = useEnableBrandLastStep();
  const mixAndMatch = useMixAndMatch();
  const imageryObject = useImagery();
  const ctxMessages = useContextualMessage();

  useExternalListener();
  useStepAnalytics();
  useRIA();
  usePreCheck(config);
  useDebuggerUtils(config);
  useVirtualMirror();

  const currentBrand = useCurrentBrand();
  const currentType = useCurrentType(config.lensesData?.packages);
  const [loaded, setLoaded] = useState(false);
  const [stepChainLoaded, setStepChainLoaded] = useState(false);
  const [standAloneLA, setStandAloneLA] = useState(false);
  let newConfig = {
    ...config,
    useGetReviewObjectLensAdvisor,
  };
  const toggleLockPageScroll = (lock) => {
    document.querySelectorAll("html,body").forEach((elem: HTMLElement) => {
      elem.style.overflow = lock ? "hidden" : "";
      if (lock) {
        elem.classList.add("RXC__is-locked");
      } else {
        elem.classList.remove("RXC__is-locked");
      }
    });
  };

  const lensAdvisor = useLensAdvisor();

  const loadLAScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.type = "application/javascript";
      script.onload = () => resolve(script);
      script.onerror = () => reject(new Error(`Script load error for ${src}`));
      document.head.append(script);
    });
  };
  let tempLAModule = { ...config.lensAdvisorModule };
  const openLensAdvisor = () => {
    let laDiv = document.createElement("div");
    laDiv.id = "laApp"; //put la selector
    document.body.appendChild(laDiv);
    if (newConfig) {
      if (cookieLA?.laContent !== null && cookieLA?.laContent !== undefined) {
        tempLAModule = {
          ...tempLAModule,
          content: cookieLA?.laContent,
        };
      }
      if (cookieLA?.laStyle !== null && cookieLA?.laStyle !== undefined) {
        tempLAModule = {
          ...tempLAModule,
          style: cookieLA?.laStyle,
        };
      }
      if (cookieLA?.laSurvey !== null && cookieLA?.laSurvey !== undefined) {
        tempLAModule = {
          ...tempLAModule,
          surveyData: {
            ...tempLAModule.surveyData,
            survey: cookieLA?.laSurvey,
          },
        };
      }
      if (cookieLA?.laRules !== null && cookieLA?.laRules !== undefined) {
        tempLAModule = {
          ...tempLAModule,
          surveyData: {
            ...tempLAModule.surveyData,
            rules: cookieLA?.laRules,
          },
        };
      }
      newConfig = {
        ...newConfig,
        lensAdvisorModule: tempLAModule,
      };
      var laApp = window?.LensAdvisor?.widget.new(newConfig);
      laApp.init();
    } else {
      if (cookieLA?.laContent !== null && cookieLA?.laContent !== undefined) {
        tempLAModule = {
          ...tempLAModule,
          content: cookieLA?.laContent,
        };
      }
      if (cookieLA?.laStyle !== null && cookieLA?.laStyle !== undefined) {
        tempLAModule = {
          ...tempLAModule,
          style: cookieLA?.laStyle,
        };
      }
      if (cookieLA?.laSurvey !== null && cookieLA?.laSurvey !== undefined) {
        tempLAModule = {
          ...tempLAModule,
          surveyData: {
            ...tempLAModule.surveyData,
            survey: cookieLA?.laSurvey,
          },
        };
      }
      if (cookieLA?.laRules !== null && cookieLA?.laRules !== undefined) {
        tempLAModule = {
          ...tempLAModule,
          surveyData: {
            ...tempLAModule.surveyData,
            rules: cookieLA?.laRules,
          },
        };
      }
      const Rconfig = {
        ...config,
        lensAdvisorModule: tempLAModule,
      };
      var laApp = window?.LensAdvisor?.widget.new(Rconfig);
      laApp.init();
    }
  };
  useLayoutEffect(() => {
    if (lensAdvisor) {
      loadLAScript(
        `https://rxc.luxottica.com/rxc3/la/${config?.lensAdvisorModule?.laEnv?.toLowerCase()}/${config?.lensAdvisorModule?.laVersion}/build/lens_advisor.js`
      )
        .then(() => {
          if (
            config?.lensAdvisorModule?.standAloneMode === true ||
            config?.lensAdvisorModule?.laCartMode?.branded?.length > 0 ||
            config?.lensAdvisorModule?.laCartMode?.unbranded?.length > 0
          ) {
            setStandAloneLA(true);
            openLensAdvisor();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [lensAdvisor]);

  useEffect(() => {
    if (currentStep?.key.toLowerCase() === "review" && !hasRecordedImpression) {
      reduxDispatch(workflowActions.setLoadingStep(false));
      reduxDispatch(workflowActions.setHasRecordedImpression(true));
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep?.key.toLowerCase() !== "review") {
      reduxDispatch(workflowActions.setHasRecordedImpression(true));
    }
  }, [currentStep]);

  //RXPANEL-1452
  useEffect(() => {
    if (cartMode && !flag.current && warrantyOptions) {
      const currentWarranty = warrantyOptions.find(
        (warranty) => warranty.id === warrantyByCart
      );
      reduxDispatch(insuranceActions.setWarranty(currentWarranty));
      flag.current = true;
    }
  }, [cartMode]);

  //Remove warranty option if eppstep is not navigated yet
  useEffect(() => {
    if (reviewSteps && enableEPPStep) {
      let EPPStep = [...reviewSteps];
      if (
        EPPStep &&
        !EPPStep?.find(
          (step: any) => step?.key?.toLowerCase() === "protectionplan"
        )
      ) {
        reduxDispatch(insuranceActions.setWarranty(null));
      }
    }
  }, [reviewSteps]);

  // remapping stepchain if brand is last step
  useEffect(() => {
    if (workflow.stepChain) {
      setStepChainLoaded(true);
    }
  }, [workflow.stepChain]);

  useEffect(() => {
    remapStepChainOnBrand(brandLastStep, reduxDispatch, currentCategory());
    if (mixAndMatch) {
      mixAndMatchasFirstStep(mixAndMatch, reduxDispatch);
    }
  }, [stepChainLoaded, mixAndMatch, brandLastStep]);

  const selectedPrescriptionMode = useSelector(
    (state: any) => state.workflow?.selectedPrescriptionMode
  );
  useEffect(() => {
    // MONETATE HOOK EVENT
    if (workflow.currentStep?.key) {
      // send custom event every time user lands on new step
      sendMonetateEvent(workflow.currentStep.key);
    }

    if (
      workflow.currentStep?.key !== "AdvancedPrescription" &&
      advancedPresIsShow
    ) {
      reduxDispatch(workflowActions.setSelectedPrescriptionMode(false));
    }
  }, [workflow.currentStep, selectedPrescriptionMode]);

  useEffect(() => {
    let configo: any = {};

    if (config.layoutSettings?.showGrayoutTile) {
      let arrayWithGrayOutProperties: [string, string][] = Object.entries(
        config.layoutSettings.showGrayoutTile
      );
      arrayWithGrayOutProperties.forEach((el: any) => {
        config.lensesData.content[el[0]][el[1]].badge = "coming soon";
      });
    }

    if (currentType && !loaded) {
      let parentElement = document.querySelector(config.selector);
      if (parentElement) {
        parentElement.classList.remove("rxc-sunbox-wrapper");
        if (currentType === "SUNGLASSES") {
          parentElement.classList.add("rxc-sunbox-wrapper");
        }
      }

      console.log("lc received config:", config);
      config.lensesData.packages.forEach((pkg) => {
        if (!pkg?.lensPackage?.insurable) {
          if (pkg?.frame) {
            pkg.frame = {
              ...pkg.frame,
              insPrice: pkg.frame.offerPrice,
            };
          }
          if (pkg?.lensPackage) {
            pkg.lensPackage = {
              ...pkg.lensPackage,
              insPrice: pkg.lensPackage.offerPrice,
            };
          }
        }
        // pkg.frame.offerPrice = "0.00";
      });

      // check currencyFormat

      if (config?.currencyFormat) {
        if (
          config.currencyFormat?.decimalSeparator ===
          config.currencyFormat?.thousandSeparator
        ) {
          // avoid error if they have same value or if they are not specified ("")
          config.currencyFormat.decimalSeparator = ".";
          config.currencyFormat.thousandSeparator = ",";
        }
      }

      loadBrandConfig(
        config.brand || config.data.brand || currentBrand,
        currentType
      ).then((x) => {
        let constClone = cloneDeep(config);
        let configuratorConfig = cloneDeep(x.default);
        constClone.brand =
          config.brand ||
          constClone.data.brand ||
          currentBrand ||
          x.default.brand;

        let mergedConfigs = merge(configuratorConfig, constClone);
        //we don't want the array of prescriptionFlows to be merged, we want it replaced
        mergedConfigs.prescripitonModule = constClone.prescriptionModule;

        configo = {
          ...mergedConfigs,
          steps: mapConfigStepToStep(mergedConfigs.steps),
        };

        // if there is no package with type frameOnly create a default one
        if (
          !configo.lensesData.packages.find(
            (pkg) => pkg.lensPackage.type === "Frame Only"
          )
        ) {
          const defaultFrameOnlyPackage = {
            frame: {
              offerPrice: configo.data.frame.offerPrice,
              insPrice: configo.data.frame.insPrice,
            },
            lensPackage: {
              upc: configo.data.frameOnlyLensUPC,
              type: "Frame Only",
              listPrice: "0.00",
              offerPrice: "0.00",
              insPrice: "0.00",
              productUPC: "PLANO",
            },
          };
          configo.lensesData.packages.push(defaultFrameOnlyPackage);
        }

        reduxDispatch(configActions.changeOptions(configo));

        if (configo.dynamicPromoModule?.getDiscounts) {
          reduxDispatch(workflowActions.setLoadingDynamicDiscounts(true));

          configo.dynamicPromoModule
            .getDiscounts(configo.data.frame, configo.lensesData)
            .then((res) => {
              let newData = { ...configo.data, frame: res.frame };
              let newLensesData = { ...res.lensesData };
              let newConfigo = {
                ...configo,
                data: newData,
                lensesData: newLensesData,
              };
              reduxDispatch(configActions.changeOptions(newConfigo));
              reduxDispatch(workflowActions.setLoadingDynamicDiscounts(false));
            });
        } else {
          reduxDispatch(workflowActions.setLoadingDynamicDiscounts(false));
        }

        loadTranslationConfig(constClone.brand, currentType).then(
          (translationFallBack) => {
            reduxDispatch(
              translationActions.setTranslations(translationFallBack)
            );
          }
        );
        setLoaded(true);
        toggleLockPageScroll(true);
      });
    }
  }, []);

  //saving globaly ctxMessages
  useEffect(() => {
    if (ctxMessages) {
      reduxDispatch(configActions.setCtxMessages(ctxMessages));
    }
  }, [ctxMessages, currentStep]);

  const handleExit = () => {
    toggleLockPageScroll(false);
    workflow.cleanAllStepChain();
    reduxDispatch(mixAndMatchActions.setSelectedTreatment(null));
    reduxDispatch(mixAndMatchActions.setSelectedLens(null));
    window.dispatchEvent(new CustomEvent("BackToPdp"));
  };

  const handleSave = () => {
    window.dispatchEvent(
      new CustomEvent("SaveAndExit", {
        detail: {
          selectedWarranty: sessionWarranty,
          reviewObjectForCart: reviewObjectForCart,
          imagery: imageryObject,
        },
      })
    );
    handleExit();
  };

  useEffect(() => {
    try {
      (window as any).utag_data.Page_Platform = "RX";
      (window as any).utag_data.Page_DeviceType = "X";
    } catch (error) {
      console.error("utag_data is not defined");
    }
  }, []);
  // const lensAdvisorConfig = useSelector((state: any) => state?.config);
  const confirmedTitlesMap = {
    type: useTranslate("steps.type.confirmedTitle"),
    brand: useTranslate("steps.brand.confirmedTitle"),
    advancedPrescription: useTranslate(
      "steps.advancedPrescription.confirmedTitle"
    ),
    lensBundle: useTranslate("steps.lensBundle.confirmedTitle"),
    treatmentsFamily: useTranslate("steps.treatmentsFamily.confirmedTitle"),
    gvpTreatment: useTranslate("steps.gvpTreatment.confirmedTitle"),
    thickness: useTranslate("steps.thickness.confirmedTitle"),
    treatments: useTranslate("steps.treatments.confirmedTitle"),
    transitionColor: useTranslate("steps.transitionColor.confirmedTitle"),
    lensColor: useTranslate("steps.lensColor.confirmedTitle"),
    color: useTranslate("steps.color.confirmedTitle"),
    addOns: useTranslate("steps.addOns.confirmedTitle"),
    alreadyIncluded: "alreadyIncluded",
  };
  const funzione = useGetReviewObjectLensAdvisor(
    config,
    {},
    confirmedTitlesMap,
    true
  );
  const hasRecordedImpression = useSelector(
    (state) => state?.workflow?.hasRecordedImpression
  );

  return !loaded ? (
    <p>loading...</p>
  ) : !standAloneLA ? (
    <BrandComponent componentName="FullPage">
      <ErrorBoundary dispatch={reduxDispatch} modalsActions={modalsActions}>
        {/* <BrandComponent componentName="Skeleton" /> */}
        <BrandComponent componentName="YourSelection" />
        <BrandComponent componentName="MainHeader" />
        <BrandComponent componentName="MainContent">
          <BrandComponent componentName="ProductContainer" />
          <BrandComponent componentName="ScrollView">
            <WorkflowStep />
          </BrandComponent>
        </BrandComponent>
        {showInsurancePopup && (
          <BrandComponent
            componentName="InsurancePopup"
            parameter={{
              onModalClose: () => setShowInsurancePopup(false),
            }}
          />
        )}
        {showInsuranceErrorPopup && (
          <BrandComponent
            componentName="InsuranceErrorPopup"
            parameter={{
              onModalClose: () =>
                reduxDispatch(modalsActions.setShowInsuranceErrorPopup(false)),
            }}
          />
        )}
        {exitModalState && (
          <BrandComponent
            componentName="ExitModal"
            parameter={{
              onContinueEditing: () => {
                reduxDispatch(modalsActions.setShowExitModal(false));
              },
              onExit: handleExit,
              onSave: configAction.genericSaveLensSelection ? handleSave : null,
            }}
          />
        )}
        <BrandComponent componentName="InsuranceBundlesModal" parameter={{}} />
        {prescriptionIncompatibleModalState && (
          <BrandComponent componentName="IncompatiblePrescriptionModal" />
        )}
        {proceedWithNoPowerModalState && (
          <BrandComponent componentName="ProceedWithNoPowerModal" />
        )}
        {modalLearnMoreData && (
          <BrandComponent componentName="LearnMoreModal" />
        )}
        {addMyDoctorModalState && (
          <BrandComponent componentName="AddMyDoctorModal" />
        )}
        {uploadFilePreviewModalState && (
          <BrandComponent componentName="PrescriptionUploadFilePreviewModal" />
        )}
        {showDigitalOptometryModal && (
          <BrandComponent componentName="DigitalOptometryModal" />
        )}
        {showSeeDetailsPrescriptionModal && (
          <BrandComponent componentName="PrescriptionReviewSeeDetails" />
        )}
        {showBundleReviewSeeDetailsModal?.show && (
          <BrandComponent
            componentName="BundleReviewSeeDetailsModal"
            parameter={{ id: showBundleReviewSeeDetailsModal?.id }}
          />
        )}
        {showPaymentInstallmentsModal && (
          <BrandComponent componentName="PaymentInstallmentsModal" />
        )}
        {/* {showMissingReadingAddValueModal && (
          <BrandComponent componentName="PrescriptionModalNoAdd" />
        )} */}
        {showHowToReadModal && (
          <BrandComponent componentName="HowToReadModal" />
        )}
        {showVirtualMirror && <BrandComponent componentName="VirtualMirror" />}
        <BrandComponent componentName="PrescriptionModal" />
        <BrandComponent componentName="PrescriptionModalContinue" />
        <BrandComponent componentName="BottomBar" />
        <BrandComponent componentName="CallMyDoctorModal" />
        <BrandComponent componentName="MissingPrescriptionValueModal" />
      </ErrorBoundary>
      {showConfiguratorError && <BrandComponent componentName="ErrorModal" />}
      {location.search.includes("debug=on") && (
        <DebugConsoleProvider>
          <DebugConsole />
        </DebugConsoleProvider>
      )}
      <div id="RxcModals" />
      {/* <BrandComponent
        componentName="NoPrescriptionFromMyAccountModal"
        parameter={{}}
      /> */}
    </BrandComponent>
  ) : (
    <></>
  );
}
