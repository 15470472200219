export const configuration = {
  brand: "costa",
  brandName: "Costa Del Mar",
  steps: [
    "Type",
    "AdvancedPrescription",
    "Brand",
    "LensBundle",
    "GVPTreatment",
    "TreatmentsFamily",
    "Treatments",
    "LensColor",
    "TransitionColor",
    "DesignType",
    "Thickness",
    "AddOns",
    "ProtectionPlan",
    "Review",
  ],
  aliasSteps: {
    lensColor: "color",
    color: "lensColor",
    transitionColor: "color",
  },
  prescriptionTypes: {
    SIMPLE: "SIMPLE",
    FULL: "FULL",
    NESTED: "NESTED",
  },
  layoutSettings: {
    enableSendItLater: true,
    activateMoneySavingsBadge: false,
    delegatingLearnMore: false,
    enableDesignTypeStep: false,
    enableEPPStep: false,
    enableBrandLastStep: false,
    targetDigitalOptometry: [],
  },
  prescriptionModule: {
    hideMoreOptions: false,
    enablePrismComment: true,
    enablePrism: true,
    mandatory: false,
    enableAgreementCheckbox: true,
    prismPrice: 30.0,
  },
  reviewConfiguration: {
    Brand: "HIDE",
  },
  prescriptionFlow: ["MANUAL"], //'MANUAL','ACCOUNT','UPLOAD','DOCTOR'
  showLensPriceAtStep: "Thickness",
  filterPromAtStep: "GVPTreatment",
  treatmentsOptions: ["clear", "blueLight", "transition"],
  addonsOptions: ["antiReflective", "antiScratch"],
  reviewOptions: ["blueLight", "uvProtection", "antiScratch", "antiReflective"],
  imageSrcsetTemplate:
    "{url}{filename}@2x.{format} 2x, {url}{filename}@3x.{format} 3x",
  imageFrameLayer:
    "##URL##/extra/image/rxc/frames/##MODEL##__##COLOR##__RXP__FRAME__qt.png",
  imageLensNoLogoLayer:
    "?impolicy=CompositeNoLogo&Lenses=##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLensLogoLayer:
    "?impolicy=CompositeLogo&Lenses=##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLens: "##URL##/extra/image/rxc/lenses/##MODEL##__RXP__##CLEN##__qt.png",
  imageLogo: "##URL##/extra/image/rxc/logo/##MODEL##__RXP__##LOGO##__qt.png",
  imageLogoLayer:
    "&Logo=##URL##/extra/image/rxc/logo/##MODEL##__RXP__##LOGO##__qt.png",
  imageTransitionLogo: "?impolicy=CompositeLogo&Lenses=",
  logoMap: {
    "Costa Del Mar": "LOGO01",
    "Ray-Ban_C": "LOGO02",
    "Ray-Ban_P": "LOGO03",
    "Ray-Ban": "LOGO04",
    Oakley: "LOGO05",
    Arnette: "LOGO08",
    Arnette_P: "LOGO09",
  },
  prescriptionDefaults: {
    sphMin: -14,
    sphMax: 14,
    sphRes: 0.25,
    cylMin: -6,
    cylMax: 6,
    cylRes: 0.25,
    addMin: 0.75,
    addMax: 4,
    addRes: 0.25,
    pdHalfMin: 25,
    pdHalfMax: 38,
    pdHalfRes: 1,
    pdMin: 51,
    pdMax: 77,
    pdRes: 1,
    pdSphRangeMin: -3,
    pdSphRangeMax: 3,
    prismVertMin: 0.5,
    prismVertMax: 5,
    prismVertRes: 0.25,
    prismBaseVert: ["Up", "Down"],
    prismHorMin: 0.5,
    prismHorMax: 5,
    prismHorRes: 0.25,
    prismBaseHor: ["In", "Out"],
  },
  baseURLs: {
    genericImage: "https://media.costadelmar.com/images/RXconfigurator/",
    projectImage: "https://media.costadelmar.com/images/RXconfigurator/",
    framesImage: "https://assets.costadelmar.com/is/image/CostaDelMar",
    assetsCDN: "https://assets.costadelmar.com",
    contextualMessages: "https://assets.costadelmar.com/extra/image/rxc/ctxmsg",
    RIAConfig:
      "https://assets.lenscrafters.com/extra/image/rxc/ria/settings.json",
    learnMoreBaseEndpoint: "/wcs/resources/store/10851/espot/",
  },
  currencyFormat: {
    thousandSeparator: ",",
    decimalSeparator: ".",
    prefix: "$",
  },
  translation: {
    language: "en_US",
  },
  linksData: {
    warrantyLearnMore: "/lc-us/purchase-care/details",
  },
  paymentInstallmentsTypes: {
    AFFIRM: "affirm",
  },
  paymentInstallment: {
    installments: 4,
  },
  RIAFlowConfiguration: {
    eyeglasses: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "Brand",
          },
        ],
      },
      Brand: {
        prev: "Type",
        key: "brand",
        next: [
          {
            step: "TreatmentsFamily",
          },
        ],
      },
      TreatmentsFamily: {
        prev: "Brand",
        key: null,
        next: [
          {
            key: null,
            exclude: ["blueLight", "transition", "color"],
            step: "Thickness",
          },
          {
            key: "blueLight",
            exclude: ["transition"],
            step: "Thickness",
          },
          {
            key: "transition",
            step: "TransitionColor",
          },
          {
            key: "color",
            exclude: ["transition"],
            step: "LensColor",
          },
        ],
      },
      LensColor: {
        prev: "TreatmentsFamily",
        key: "color",
        exclude: ["transition"],
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      TransitionColor: {
        prev: "TreatmentsFamily",
        key: "color",
        include: ["transition"],
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: null,
        key: "thickness",
        prevMultiple: [
          {
            included: ["transition"],
            excluded: null,
            step: "TransitionColor",
          },
          {
            included: ["color"],
            excluded: ["transition"],
            step: "LensColor",
          },
          {
            included: null,
            excluded: ["transition", "color"],
            step: "TreatmentsFamily",
          },
        ],
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: null,
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    eyeglassesV5: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "Brand",
            include: { value: "enableBrandLastStep", equals: false },
          },
          {
            step: "TreatmentsFamily",
            include: { value: "enableBrandLastStep", equals: true },
          },
        ],
      },
      Brand: {
        prev: "Type",
        key: "brand",
        next: [
          {
            step: "TreatmentsFamily",
          },
        ],
        include: { value: "enableBrandLastStep", equals: false },
      },
      TreatmentsFamily: {
        prev: "Brand",
        key: "treatmentFamily",
        next: [
          {
            key: null,
            exclude: ["blueLight", "transition", "color"],
            step: "Thickness",
          },
          {
            key: "blueLight",
            exclude: ["transition"],
            step: "Treatment",
          },
          {
            key: "transition",
            step: "Treatment",
          },
          {
            key: "color",
            exclude: ["transition"],
            step: "LensColor",
          },
        ],
      },
      Treatment: {
        prev: "TreatmentsFamily",
        next: [
          {
            key: "blueLight",
            exclude: ["trasition"],
            step: "Thickness",
          },
          {
            key: "transition",
            step: "TransitionColor",
          },
        ],
      },
      LensColor: {
        prev: "TreatmentsFamily",
        key: "color",
        exclude: ["transition"],
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      TransitionColor: {
        prev: "Treatment",
        key: "color",
        include: ["transition"],
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: null,
        key: "thickness",
        prevMultiple: [
          {
            included: ["transition"],
            excluded: null,
            step: "TransitionColor",
          },
          {
            included: ["color"],
            excluded: ["transition"],
            step: "LensColor",
          },
          {
            included: null,
            excluded: ["transition", "color"],
            step: "Treatment",
          },
        ],
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: [
          {
            step: "LastStepBrand",
            include: { value: "enableBrandLastStep", equals: true },
          },
        ],
      },
      LastStepBrand: {
        prev: "AddOns",
        key: "brand",
        next: null,
        include: { value: "enableBrandLastStep", equals: true },
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    eyeglassesV5DT: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "Brand",
          },
        ],
      },
      Brand: {
        prev: "Type",
        key: "brand",
        next: [
          {
            step: "TreatmentsFamily",
          },
        ],
      },
      TreatmentsFamily: {
        prev: "Brand",
        key: "treatmentFamily",
        next: [
          {
            key: null,
            exclude: ["blueLight", "transition", "color"],
            step: "DesignType",
          },
          {
            key: "blueLight",
            exclude: ["transition"],
            step: "Treatment",
          },
          {
            key: "transition",
            step: "Treatment",
          },
          {
            key: "color",
            exclude: ["transition"],
            step: "LensColor",
          },
        ],
      },
      Treatment: {
        prev: "TreatmentsFamily",
        next: [
          {
            key: "blueLight",
            exclude: ["trasition"],
            step: "DesignType",
          },
          {
            key: "transition",
            step: "TransitionColor",
          },
        ],
      },
      LensColor: {
        prev: "TreatmentsFamily",
        key: "color",
        exclude: ["transition"],
        next: [
          {
            step: "DesignType",
          },
        ],
      },
      TransitionColor: {
        prev: "Treatment",
        key: "color",
        include: ["transition"],
        next: [
          {
            step: "DesignType",
          },
        ],
      },
      DesignType: {
        prev: null,
        key: "designType",
        prevMultiple: [
          {
            included: ["transition"],
            excluded: null,
            step: "TransitionColor",
          },
          {
            included: ["color"],
            excluded: ["transition"],
            step: "LensColor",
          },
          {
            included: null,
            excluded: ["transition", "color"],
            step: "Treatment",
          },
        ],
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: "DesignType",
        key: "thickness",
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: null,
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    eyeglassesV5DTBrandLastStep: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "TreatmentsFamily",
          },
        ],
      },
      TreatmentsFamily: {
        prev: "type",
        key: "treatmentFamily",
        next: [
          {
            key: null,
            exclude: ["blueLight", "transition", "color"],
            step: "DesignType",
          },
          {
            key: "blueLight",
            exclude: ["transition"],
            step: "Treatment",
          },
          {
            key: "transition",
            step: "Treatment",
          },
          {
            key: "color",
            exclude: ["transition"],
            step: "LensColor",
          },
        ],
      },
      Treatment: {
        prev: "TreatmentsFamily",
        next: [
          {
            key: "blueLight",
            exclude: ["trasition"],
            step: "DesignType",
          },
          {
            key: "transition",
            step: "TransitionColor",
          },
        ],
      },
      LensColor: {
        prev: "TreatmentsFamily",
        key: "color",
        exclude: ["transition"],
        next: [
          {
            step: "DesignType",
          },
        ],
      },
      TransitionColor: {
        prev: "Treatment",
        key: "color",
        include: ["transition"],
        next: [
          {
            step: "DesignType",
          },
        ],
      },
      DesignType: {
        prev: null,
        key: "designType",
        prevMultiple: [
          {
            included: ["transition"],
            excluded: null,
            step: "TransitionColor",
          },
          {
            included: ["color"],
            excluded: ["transition"],
            step: "LensColor",
          },
          {
            included: null,
            excluded: ["transition", "color"],
            step: "Treatment",
          },
        ],
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: "DesignType",
        key: "thickness",
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: "Brand",
      },
      Brand: {
        prev: "AddOns",
        key: "brand",
        next: null,
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    eyeglassesGVP: {
      Type: {
        prev: null,
        key: "type",
        next: [
          {
            step: "Brand",
          },
        ],
      },
      Brand: {
        prev: "Type",
        key: "brand",
        next: [
          {
            step: "GVPTreatment",
          },
        ],
      },
      GVPTreatment: {
        prev: "Brand",
        key: null,
        next: [
          {
            exclude: ["transition"],
            step: "Thickness",
          },
          {
            include: ["transition"],
            step: "TransitionColor",
          },
        ],
      },
      LensColor: {
        prev: "GVPTreatment",
        key: "color",
        exclude: ["transition"],
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      TransitionColor: {
        prev: "GVPTreatment",
        key: "color",
        include: ["transition"],
        next: [
          {
            step: "Thickness",
          },
        ],
      },
      Thickness: {
        prev: null,
        key: "thickness",
        prevMultiple: [
          {
            included: ["transition"],
            excluded: null,
            step: "TransitionColor",
          },
          {
            included: ["color"],
            excluded: ["transition"],
            step: "LensColor",
          },
          {
            included: null,
            excluded: ["transition", "color"],
            step: "GVPTreatment",
          },
        ],
        next: [
          {
            step: "AddOns",
          },
        ],
      },
      AddOns: {
        prev: "Thickness",
        key: "antiReflective",
        next: null,
      },
      Review: {
        prev: "AddOns",
        key: null,
        next: null,
      },
    },
    eyeFrameOnly: {
      Type: {
        prev: null,
        key: "type",
        next: null,
      },
      Review: {
        prev: "Type",
        key: null,
        next: null,
      },
    },
    sunFrameOnly: {
      Type: {
        prev: null,
        key: "type",
        next: null,
      },
      Review: {
        prev: "Type",
        key: null,
        next: null,
      },
    },
  },
};
