import { sortBy, spread } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../../../../docFiles";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { workflowActions } from "../../../redux/slices/workflow";
import {
  checkLayoutConfigDesignType,
  getStepBadgeType,
  getCorrectKey,
  cleanRule,
  transitionRule,
  getCheapPackageFromList,
  callInsuranceService,
  sunRule,
} from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useCheapCurrentStep,
  useCheckPhotocromic,
  useContextualMessage,
  useMoreInfo,
  useTreatmentsFamilyMapper,
  useGetDiscount,
  useCurrentType,
  useRetriveContextualMessage,
  usePriceStepItem,
  useTransitionColors,
} from "@hooks";
import { ParameterWithTitle } from "../../models/Parameter";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import "./default.module.scss";
import GenericButton from "../../../components/common/GenericButton";
import { colorActions } from "../../../redux/slices/color";
import { useBundlesPrices } from "../../hooks";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";

export function TreatmentsFamily() {
  const translate = useTranslation();
  const ctxMessage = useRetriveContextualMessage();
  const currentPackage = useCheapCurrentStep();
  const currentPackages = useAllCurrentPackages();
  const loadTreatmentsFamily = useTreatmentsFamilyMapper();
  const reduxDispatch = useDispatch();
  const mapperMoreInfo = useMoreInfo("treatmentsFamily");
  const { isNestedTransitionSwatchEnabled } = useTransitionColors(null);
  const [tfamilyOptions, setTfamilyOptions] = useState([]);
  const isPhotocromic = useCheckPhotocromic();
  const { getDiscount, isLoading } = useGetDiscount();
  const config = useSelector((state: any) => state.config);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const c = usePriceStepItem();
  const bundlesEnabled = useBundlesPrices();
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const currentType = useCurrentType();
  const learnMoreButton = useTranslate(
    "steps.treatmentsFamily.contentIdentifier"
  );
  const moreInfoLabel = useTranslate("steps.treatmentsFamily.learnMoreCTA");
  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );

  const frame = useSelector((state: any) => state.config?.data?.frame);

  const getSelectedLensPackage = (selectedTfamily: any) => {
    const filteredPackages = config.lensesData.packages.filter((item) => {
      return item.lensPackage.catEntryId === config?.data?.lens.catEntryId;
    });

    let selectedPackage = filteredPackages[0]?.lensPackage;
    let configColor =
      config?.lensesData?.content?.color[selectedPackage?.color];

    const key = Object.keys(config?.lensesData?.content?.color).find(
      (key) => key === selectedPackage?.color
    );

    let ret;
    if (selectedTfamily) {
      let selectedTfamilyOptions = tfamilyOptions?.filter((tfo) => {
        return tfo.id === selectedTfamily;
      });
      ret = {
        ...selectedTfamilyOptions?.[0],
        ...configColor,
        clen: selectedPackage?.clen,
        id: key,
      };
    }
    return ret;
  };

  useEffect(() => {
    if (
      currentPackages &&
      currentPackage &&
      loadTreatmentsFamily &&
      isPhotocromic != null
    ) {
      let options = loadTreatmentsFamily(currentPackages, currentPackage);
      options = mapperMoreInfo(options);
      options = options.map((opt) => ({
        ...opt,
        discountLoading: isLoading(opt.id),
      }));
      if (isInsuranceEnabled && tfamilyOptions.length === 0) {
        options.forEach((t) => {
          if (!t.insPrice || isNaN(t.insPrice)) {
            const id = t.id.split("$")[0];
            const value = t.id.split("$")[1];
            let filteredPackages = currentPackages.validRXPackages.filter(
              (p) => {
                if (id === "clear") {
                  return cleanRule(p.lensPackage);
                }
                if (id === "blueLight") {
                  return p.lensPackage[id] === value;
                }
                if (id === "transition") return transitionRule(p.lensPackage);
                if (id === "sun") return sunRule(p.lensPackage);
              }
            );

            let cheapestPackages = null;
            if (filteredPackages.length > 1) {
              cheapestPackages = [getCheapPackageFromList(filteredPackages)];
            } else {
              cheapestPackages = filteredPackages;
            }

            let insurablePackages = cheapestPackages.filter(
              (pkg) => pkg.lensPackage.insurable
            );

            if (insurablePackages.length) {
              callInsuranceService(
                cheapestPackages,
                configInsurance,
                frame,
                reduxDispatch
              );
            }
          }
        });
      }
      setTfamilyOptions(sortBy(options, ["order"]));
    }
  }, [currentPackages, currentPackage, loadTreatmentsFamily, isPhotocromic]);

  useEffect(() => {
    for (const options of tfamilyOptions) {
      getDiscount(options.id, currentPackages.validRXPackages, options.filter);
    }
  }, [tfamilyOptions]);

  /* useEffect(() => {
        if (currentPackages) {
            let cheapestPackages = [
                getCheapPackageFromList(currentPackages.validRXPackages.filter(p => cleanRule(p.lensPackage))),
                getCheapPackageFromList(currentPackages.validRXPackages.filter(p => blueLightRule(p.lensPackage))),
                getCheapPackageFromList(currentPackages.validRXPackages.filter(p => transitionRule(p.lensPackage))),
                getCheapPackageFromList(currentPackages.validRXPackages.filter(p => sunRule(p.lensPackage)))
            ]
 
            cheapestPackages = cheapestPackages.filter(pkg => pkg);
            
            for( const cheapPackage of cheapestPackages){
                getPackageDiscount()
            }
        }
    }, [currentPackages]) */

  const onTreatmentsSelected = (tfamily, skipped?, color?) => {
    let selectedTfamilyOptions = tfamilyOptions.filter((tfo) => {
      return tfo.id === tfamily;
    });

    if (tfamily.indexOf("$") >= 0) {
      onClickTreatment(selectedTfamilyOptions[0], skipped, tfamily, color);
    } else {
      let dontSendPrice = selectedTfamilyOptions[0].thereAreNonIncludedOptions;

      if (tfamily === "transition") {
        reduxDispatch(colorActions.setClearClen(null));

        if (color) {
          const colorPrice = c(
            { color: color?.id },
            currentPackages.validRXPackages
          );

          let truthValue = false;

          Object.keys(colorPrice).forEach((e) => {
            truthValue =
              truthValue || (colorPrice[e] !== 0 && colorPrice[e] !== null);
          });

          let spread = truthValue ? colorPrice : {};

          let params = [
            {
              key: "photocromic",
              value: isPhotocromic,
            },
            {
              key: "familyFilter",
              value: {
                required: ["transition"],
                additional: [],
                excluded: [],
                nextStep: "TransitionColor",
              },
            },
            {
              key: "treatmentsFamily",
              value: selectedTfamilyOptions[0].title,
              price: dontSendPrice ? 0 : selectedTfamilyOptions[0].price,
              discount: dontSendPrice ? 0 : selectedTfamilyOptions[0].discount,
              insPrice: dontSendPrice
                ? 0
                : skipped && selectedTfamilyOptions[0].price == 0
                  ? 0
                  : selectedTfamilyOptions[0].insPrice,
            },
            {
              key: "color",
              value: color?.id,
            },
            {
              key: "transitionColor",
              value: color?.id,
            },
          ];

          workflow.goToStep("Thickness", params);
        } else {
          workflow.goToStep(
            "Treatments",
            [
              {
                key: "familyFilter",
                value: {
                  required: ["transition"],
                  additional: [],
                  excluded: [],
                  nextStep: "TransitionColor",
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTfamilyOptions[0].title,
                price: dontSendPrice ? 0 : selectedTfamilyOptions[0].price,
                discount: dontSendPrice
                  ? 0
                  : selectedTfamilyOptions[0].discount,
                insPrice: dontSendPrice
                  ? 0
                  : skipped && selectedTfamilyOptions[0].price == 0
                    ? 0
                    : selectedTfamilyOptions[0].insPrice,
              },
              {
                key: "photocromic",
                value: isPhotocromic,
              },
            ],
            skipped
          );
        }
      } else if (tfamily === "sun") {
        reduxDispatch(colorActions.setClearClen(null));
        workflow.goToStep(
          "LensColor",
          [
            {
              key: "familyFilter",
              value: {
                required: ["color"],
                excluded: ["blueLight", "transition"],
                additional: [],
                nextStep: "LensColor",
              },
            },
            {
              key: "blueLight",
              value: null,
              price: dontSendPrice ? 0 : selectedTfamilyOptions[0].price,
              discount: dontSendPrice ? 0 : selectedTfamilyOptions[0].discount,
              insPrice: dontSendPrice
                ? 0
                : skipped && selectedTfamilyOptions[0].price == 0
                  ? 0
                  : selectedTfamilyOptions[0].insPrice,
            },
            {
              key: "transition",
              value: null,
              price: dontSendPrice ? 0 : selectedTfamilyOptions[0].price,
              discount: dontSendPrice ? 0 : selectedTfamilyOptions[0].discount,
              insPrice: dontSendPrice
                ? 0
                : skipped && selectedTfamilyOptions[0].price == 0
                  ? 0
                  : selectedTfamilyOptions[0].insPrice,
            },
            {
              key: "treatmentsFamily",
              value: selectedTfamilyOptions[0].title,
              price: dontSendPrice ? 0 : selectedTfamilyOptions[0].price,
              discount: dontSendPrice ? 0 : selectedTfamilyOptions[0].discount,
              insPrice: dontSendPrice
                ? 0
                : skipped && selectedTfamilyOptions[0].price == 0
                  ? 0
                  : selectedTfamilyOptions[0].insPrice,
            },
          ],
          skipped
        );
      } else if (tfamily === "blueLight") {
        reduxDispatch(colorActions.setClearClen("CLEAR"));
        workflow.goToStep(
          "Treatments",
          [
            {
              key: "familyFilter",
              value: {
                required: ["blueLight"],
                excluded: ["transition"],
                additional: [],
                nextStep: "Thickness",
              },
            },
            {
              key: "treatmentsFamily",
              value: selectedTfamilyOptions[0].title,
              price: dontSendPrice ? 0 : selectedTfamilyOptions[0].price,
              discount: dontSendPrice ? 0 : selectedTfamilyOptions[0].discount,
              insPrice: dontSendPrice
                ? 0
                : skipped && selectedTfamilyOptions[0].price == 0
                  ? 0
                  : selectedTfamilyOptions[0].insPrice,
            },
          ],
          skipped
        );
      } else {
        reduxDispatch(colorActions.setClearClen("CLEAR"));
        checkLayoutConfigDesignType(config)
          ? workflow.goToStep(
            "DesignType",
            [
              {
                key: selectedTfamilyOptions[0].title,
                value: selectedTfamilyOptions[0].value,
              },
              {
                key: "familyFilter",
                value: {
                  required: [],
                  excluded: ["blueLight", "transition", "color"],
                  additional: [],
                  nextStep: "DesignType",
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTfamilyOptions[0].title,
                price: selectedTfamilyOptions[0].price,
                discount: selectedTfamilyOptions[0].discount,
                insPrice: selectedTfamilyOptions[0].insPrice,
                attributes: {
                  hideTreatmentsInReview: true,
                  skipped: skipped,
                },
              },
            ],
            skipped
          )
          : workflow.goToStep(
            "Thickness",
            [
              {
                key: selectedTfamilyOptions[0].title,
                value: selectedTfamilyOptions[0].value,
              },
              {
                key: "familyFilter",
                value: {
                  required: [],
                  excluded: ["blueLight", "transition", "color"],
                  additional: [],
                  nextStep: "Thickness",
                },
                price: selectedTfamilyOptions[0]?.price,
                discount: selectedTfamilyOptions[0]?.discount,
                insPrice: selectedTfamilyOptions[0]?.insPrice,
                attributes: {
                  hideTreatmentsInReview: true,
                  skipped: skipped,
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTfamilyOptions[0].title,
                price: selectedTfamilyOptions[0].price,
                discount: selectedTfamilyOptions[0].discount,
                insPrice: selectedTfamilyOptions[0].insPrice,
                attributes: {
                  hideTreatmentsInReview: true,
                  skipped: skipped,
                },
              },
            ],
            skipped
          );
      }
    }
  };

  const enableNestedTransitionSwatch = useSelector(
    (state: any) => state.config?.layoutSettings?.enableNestedTransitionSwatch
  );
  const onClickTreatment = (
    obj: {
      id: string;
      title: string;
      price: number;
      discount: number;
      insPrice: number;
    },
    skipped?,
    tfamily?,
    color?
  ) => {
    const idT = obj.id.split("$")[0];
    const valueT = obj.id.split("$")[1];

    //to reload price
    const onlySelected = new ParameterWithTitle();
    const idTa = idT;
    const valueTa = valueT;
    onlySelected.key = idTa;
    onlySelected.value = valueTa;
    onlySelected.title = obj.title;
    onlySelected.price = obj.price;
    onlySelected.discount = obj.discount;
    onlySelected.insPrice = obj.insPrice;

    let selectedTfamilyOptions = tfamilyOptions.filter((tfo) => {
      return tfo.id === tfamily;
    });
    reduxDispatch(workflowActions.setParameterPrice([onlySelected]));
    reduxDispatch(colorActions.setClearClen(null));
    if (idT === "transition") {
      if (!enableNestedTransitionSwatch) {
        workflow.goToStep(
          "TransitionColor",
          [
            {
              key: "transition",
              value: valueT,
            },
            {
              key: "familyFilter",
              value: {
                required: ["transition"],
                additional: [],
                excluded: [],
                nextStep: "TransitionColor",
              },
            },
            {
              key: "treatmentsFamily",
              value: obj.title,
              price: obj.price,
              discount: obj.discount,
              insPrice: obj.insPrice,
              attributes: {
                hideTreatmentsInReview: true,
                skipped: skipped,
              },
            },
            {
              key: "photocromic",
              value: isPhotocromic,
            },
          ],
          skipped
        );
      } else {
        if (color) {
          let colorPrice;

          if (config?.data?.lens?.catEntryId) {
            const lensPackageSelected = getSelectedLensPackage(
              selectedTfamilyOptions[0].id
            );

            colorPrice = lensPackageSelected.price;
          } else {
            colorPrice = c(
              { color: color?.id },
              currentPackages.validRXPackages
            );
          }

          let truthValue = false;

          if (colorPrice) {
            Object.keys(colorPrice).forEach((e) => {
              truthValue =
                truthValue || (colorPrice[e] !== 0 && colorPrice[e] !== null);
            });
          }

          let spread = truthValue ? colorPrice : {};
          let dontSendPrice =
            selectedTfamilyOptions[0].thereAreNonIncludedOptions;

          let params = [
            {
              key: "photocromic",
              value: isPhotocromic,
            },
            {
              key: "familyFilter",
              value: {
                required: ["transition"],
                additional: [],
                excluded: [],
                nextStep: "TransitionColor",
              },
            },
            {
              key: "treatmentsFamily",
              value: selectedTfamilyOptions[0].title,
              price: dontSendPrice ? 0 : selectedTfamilyOptions[0].price,
              discount: dontSendPrice ? 0 : selectedTfamilyOptions[0].discount,
              insPrice: dontSendPrice
                ? 0
                : skipped && selectedTfamilyOptions[0].price == 0
                  ? 0
                  : selectedTfamilyOptions[0].insPrice,
            },
            {
              key: "color",
              value: color?.id,
            },
            {
              key: "transitionColor",
              value: color?.id,
            },
          ];

          workflow.goToStep("Thickness", params);
        } else {
          let dontSendPrice =
            selectedTfamilyOptions[0].thereAreNonIncludedOptions;
          workflow.goToStep(
            "Treatments",
            [
              {
                key: "familyFilter",
                value: {
                  required: ["transition"],
                  additional: [],
                  excluded: [],
                  nextStep: "TransitionColor",
                },
              },
              {
                key: "treatmentsFamily",
                value: selectedTfamilyOptions[0].title,
                price: dontSendPrice ? 0 : selectedTfamilyOptions[0].price,
                discount: dontSendPrice
                  ? 0
                  : selectedTfamilyOptions[0].discount,
              },
              {
                key: "photocromic",
                value: isPhotocromic,
              },
            ],
            skipped
          );
        }
      }
    } else if (idT === "blueLight") {
      reduxDispatch(colorActions.setClearClen("CLEAR"));
      checkLayoutConfigDesignType(config)
        ? workflow.goToStep(
          "DesignType",
          [
            {
              key: idT,
              value: valueT,
            },
            {
              key: "familyFilter",
              value: {
                required: ["blueLight"],
                excluded: ["transition"],
                additional: [],
                nextStep: "DesignType",
              },
            },
            {
              key: "treatmentsFamily",
              value: obj.title,
              price: obj.price,
              discount: obj.discount,
              insPrice: obj.insPrice,
              attributes: {
                hideTreatmentsInReview: true,
                skipped: skipped,
              },
            },
          ],
          skipped
        )
        : workflow.goToStep(
          "Thickness",
          [
            {
              key: idT,
              value: valueT,
            },
            {
              key: "familyFilter",
              value: {
                required: ["blueLight"],
                excluded: ["transition"],
                additional: [],
                nextStep: "Thickness",
              },
            },
            {
              key: "treatmentsFamily",
              value: obj.title,
              price: obj.price,
              discount: obj.discount,
              insPrice: obj.insPrice,
              attributes: {
                hideTreatmentsInReview: true,
                skipped: skipped,
              },
            },
          ],
          skipped
        );
    } else {
      reduxDispatch(colorActions.setClearClen("CLEAR"));
      checkLayoutConfigDesignType(config)
        ? workflow.goToStep(
          "DesignType",
          [
            {
              key: idT,
              value: valueT,
            },
            {
              key: "familyFilter",
              value: {
                required: [],
                excluded: ["blueLight", "transition", "color"],
                additional: [],
                nextStep: "DesignType",
              },
            },
            {
              key: "treatmentsFamily",
              value: obj.title,
              price: obj.price,
              discount: obj.discount,
              insPrice: obj.insPrice,
              attributes: {
                hideTreatmentsInReview: true,
                skipped: skipped,
              },
            },
          ],
          skipped
        )
        : workflow.goToStep(
          "Thickness",
          [
            {
              key: idT,
              value: valueT,
            },
            {
              key: "familyFilter",
              value: {
                required: [],
                excluded: ["blueLight", "transition", "color"],
                additional: [],
                nextStep: "Thickness",
              },
              price: obj.price,
              discount: obj.discount,
              insPrice: obj.insPrice,
              attributes: {
                hideTreatmentsInReview: true,
                skipped: skipped,
              },
            },
            {
              key: "treatmentsFamily",
              value: obj.title,
              price: obj.price,
              discount: obj.discount,
              insPrice: obj.insPrice,
              attributes: {
                hideTreatmentsInReview: true,
                skipped: skipped,
              },
            },
          ],
          skipped
        );
    }
  };

  const getKey = (title: string) =>
    "Treatmentsfamily_sc_" + title?.split(" ").join("_");

  const selectTreatmentWithColor = (c, color) => {
    setShowConfirmButton(true);
    setSelectedTreatment({ ...c, color: color });
  };

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.treatmentsFamily.title"),
        subtitle: ctxMessage,
        showGrayOutBanner:
          tfamilyOptions && tfamilyOptions.find((tFo) => tFo.disabled),
      }}
    >
      {learnMoreButton !== "steps.treatmentsFamily.contentIdentifier" &&
        learnMoreButton !== undefined &&
        learnMoreButton !== null &&
        delegatingLearnMore &&
        delegateLearnMoreContent && (
          <a
            className="learnMoreButton"
            onClick={() => delegateLearnMoreContent(learnMoreButton)}
            data-element-id={
              (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
              "_LensBundle-LearnMore"
            }
          >
            {moreInfoLabel !== "" &&
              moreInfoLabel !== undefined &&
              moreInfoLabel !== null &&
              moreInfoLabel !== "steps.treatmentsFamily.learnMoreCTA"
              ? moreInfoLabel
              : "More information"}
          </a>
        )}
      <div className={"TreatmentFamily__container"}>
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "TreatmentsFamily",
            onNext: (selected: any, skipped?: boolean) => {
              onTreatmentsSelected(selected?.id, skipped);
            },
            parameterPreselection: ["treatmentFamily"],
            onPreselect: (selected, skipped: boolean) => {
              let color = getSelectedLensPackage(selected?.treatmentFamily);
              onTreatmentsSelected(selected?.treatmentFamily, skipped, color);
            },
            ignoreSkip:
              tfamilyOptions.length === 1 &&
              tfamilyOptions[0].transitionId &&
              isNestedTransitionSwatchEnabled,
          }}
        >
          {tfamilyOptions &&
            tfamilyOptions.map((c: any) => (
              <BrandComponent
                key={getKey(c.title)}
                componentName="StepCard"
                parameter={{
                  ...c,
                  badge: c.textBadge
                    ? getStepBadgeType(c.textBadge)
                    : c.badge
                      ? getStepBadgeType(c.badge)
                      : null,
                  disabled:
                    c.disabled ||
                    c.badge == "coming soon" ||
                    c.textBadge == "coming soon",
                  onClick:
                    c.textBadge == "coming soon" ||
                      c.disabled ||
                      c.badge == "coming soon"
                      ? null
                      : (color) => {
                        if (!color?.id) {
                          onTreatmentsSelected(c.id, false);
                        } else {
                          selectTreatmentWithColor(c, color);
                        }
                      },
                  selected: c.id === selectedTreatment?.id,
                  sequence:
                    tfamilyOptions.length === 1 &&
                      tfamilyOptions[0].transitionId &&
                      isNestedTransitionSwatchEnabled
                      ? 1
                      : c.sequence,
                  hideSign: !bundlesEnabled,
                }}
              />
            ))}
        </BrandComponent>
        {showConfirmButton && selectedTreatment && (
          <div className="confirmButton__container">
            <GenericButton
              handleClick={() =>
                onTreatmentsSelected(
                  selectedTreatment.id,
                  false,
                  selectedTreatment.color
                )
              }
              className="confirmButton"
              noArrow
            >
              <span>{translate("confirmButton")}</span>
            </GenericButton>
          </div>
        )}
      </div>
      <ReferencedSection
        name={"ScrollView__" + workflow?.currentStep?.key}
        style={{ paddingTop: "0.2rem" }}
      />
    </BrandComponent>
  );
}
