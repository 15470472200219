import React, { useState, useEffect, useRef } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";

export type IncompatibleLensTypeProps = {
  type: string;
  origin?: string;
};

export const IncompatibleLensType = (props: IncompatibleLensTypeProps) => {
  let incompatibleLensTypeError = useTranslate(
    "steps.advancedPrescription.manual.incompatibleLensTypeError" + props.type
  );
  const incompatibleLensTypeStoreMessageProgressive = useTranslate(
    "steps.advancedPrescription.manual.incompatibleLensTypeStoreMessageProgressive"
  );
  const incompatibleLensTypeStoreMessageSingleVision = useTranslate(
    "steps.advancedPrescription.manual.incompatibleLensTypeStoreMessageSingleVision"
  );
  const incompatiblesLensesTypeRef = useRef(null);
  useEffect(() => {
    if (incompatiblesLensesTypeRef.current) {
      incompatiblesLensesTypeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div className="IncompatibleLensType" ref={incompatiblesLensesTypeRef}>
      <div
        className={"IncompatibleLensType__message"}
        dangerouslySetInnerHTML={
          props?.origin !== "STORE"
            ? { __html: incompatibleLensTypeError }
            : props?.type?.toLowerCase() === "progressive"
            ? { __html: incompatibleLensTypeStoreMessageProgressive }
            : { __html: incompatibleLensTypeStoreMessageSingleVision }
        }
      ></div>
    </div>
  );
};
