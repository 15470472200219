import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../../../../docFiles";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { workflowActions } from "../../../redux/slices/workflow";
import {
  callInsuranceService,
  checkGVP,
  filterExistingProperties,
  getCheapPackageFromList,
} from "../../ConfigLoader";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";
import {
  useAllCurrentPackages,
  useContextualMessage,
  useEnableBrandLastStep,
  useGetDiscount,
  usePriceStepItem,
  useProtectionPlan,
} from "@hooks";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import { StepComponentOptions } from "../StepComponent";
import { useBrands } from "@hooks";
import { useBundlesPrices } from "../../hooks";

export function Brand(props: StepComponentOptions) {
  const translate = useTranslation();
  const currentPackages = useAllCurrentPackages();
  const { brands } = useBrands();
  const ctxMessage = useSelector(
    (state: any) => state?.config?.ctxMessages?.brand
  );
  const dispatch = useDispatch();
  const { getDiscount, isLoading } = useGetDiscount();
  const getPriceStep = usePriceStepItem();
  const previousStep = useSelector(
    (state: any) => state?.workflow?.currentStep?.params?.[0]?.key
  );
  const brandLastStep = useEnableBrandLastStep();
  const EPPStep = useProtectionPlan();
  const config = useSelector((state: any) => state.config);
  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );
  const frame = useSelector((state: any) => state.config?.data?.frame);
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const bundlesEnabled = useBundlesPrices();

  const onBrandSelectedFromStep = (id, skipped) => {
    onBrandSelected({ id, ...getPriceStep({ brand: id }) }, skipped);
  };
  const chooseNextStep = (brand: any) => {
    let nextStep;
    if (brandLastStep) {
      if (EPPStep) {
        nextStep = "ProtectionPlan";
      } else {
        nextStep = "Review";
      }
    } else {
      if (
        checkGVP(
          currentPackages.validRXPackages.filter((p) =>
            filterExistingProperties(p, { brand: brand?.id })
          )
        )
      ) {
        dispatch(workflowActions.setPromoType("GVP"));
        const isIncorrectData = currentPackages.validRXPackages
          .concat(currentPackages.invalidRXPackages)
          .every((current) => {
            return current.lensPackage.treatmentGroup === null;
          });
        if (isIncorrectData) {
          console.error(
            `ATTENTION! For GVP packages the identifier of lens treatment group (treatmentGroup parameter in lensPackage) can't be null because is mandatory. \n The current filtered packages are:`,
            currentPackages
          );
        }
        nextStep = "GVPTreatment";
      } else if (bundlesEnabled) {
        nextStep = "LensBundle";
      } else {
        dispatch(workflowActions.setPromoType(null));
        nextStep = "TreatmentsFamily";
      }
    }
    return nextStep;
  };
  const onBrandSelected = (
    brand: { id; price; discount; insPrice },
    skipped: boolean
  ) => {
    let nextStep = chooseNextStep(brand);
    workflow.goToStep(
      nextStep,
      [
        ...(skipped ? workflow.currentStep.params : []),
        {
          key: "brand",
          value: brand.id,
          price: skipped ? 0 : brand.price,
          discount: brand.discount,
          insPrice: skipped && brand.price == 0 ? 0 : brand.insPrice,
        },
      ],
      skipped
    );
  };

  useEffect(() => {
    for (const brand of brands) {
      const { id } = brand;
      getDiscount(id, currentPackages.validRXPackages, { brand: id });
      if (isInsuranceEnabled) {
        if (!brand.insPrice || isNaN(brand.insPrice)) {
          let filteredPackages = currentPackages.validRXPackages.filter(
            (p) => p.lensPackage.brand === id
          );
          let cheapestPackages = null;
          if (filteredPackages.length > 1) {
            cheapestPackages = [getCheapPackageFromList(filteredPackages)];
          } else {
            cheapestPackages = filteredPackages;
          }

          let insurablePackages = cheapestPackages.filter(
            (pkg) => pkg.lensPackage.insurable
          );

          if (insurablePackages.length) {
            callInsuranceService(
              cheapestPackages,
              configInsurance,
              frame,
              dispatch
            );
          }
        }
      }
    }
  }, [brands]);
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const learnMoreButton = useTranslate("steps.brand.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.brand.learnMoreCTA");
  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.brand.title"),
        subtitle: ctxMessage,
        showGrayOutBanner: brands && brands.find((b) => b.disabled),
      }}
    >
      {learnMoreButton !== "steps.brand.contentIdentifier" &&
        learnMoreButton !== undefined &&
        learnMoreButton !== null &&
        delegatingLearnMore &&
        delegateLearnMoreContent && (
          <a
            className="learnMoreButton"
            onClick={() => delegateLearnMoreContent(learnMoreButton)}
            data-element-id="LensPanel_LensBrand-LearnMore"
          >
            {moreInfoLabel !== "" &&
            moreInfoLabel !== undefined &&
            moreInfoLabel !== null &&
            moreInfoLabel !== "steps.brand.learnMoreCTA"
              ? moreInfoLabel
              : "More information"}
          </a>
        )}
      <div className={"Brand__container"}>
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "Brand",
            onNext: (selected: any, skipped?: boolean) => {
              onBrandSelectedFromStep(selected?.id, skipped);
            },
            parameterPreselection: ["brand"],
            onPreselect: (selected, skipped: boolean) => {
              onBrandSelectedFromStep(selected.brand, skipped);
            },
          }}
        >
          {brands.map((brand, i) => (
            <BrandComponent
              key={i}
              componentName="StepCard"
              parameter={{
                ...brand,
                onClick:
                  brand.badge == "coming soon" || brand.disabled
                    ? null
                    : () => onBrandSelected(brand, false),
                discountLoading: isLoading(brand.id),
              }}
            />
          ))}
        </BrandComponent>
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
