import React, { useEffect } from "react";
import "./default.module.scss";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { useDispatch, useSelector } from "react-redux";
import useGetReviewObjectLensAdvisor from "../../configurator/hooks/useGetReviewObjectLensAdvisor";
import { useCookieLensAdvisor } from "../../configurator/hooks/useCookieLensAdvisor";
import { workflowActions } from "../../redux/slices/workflow";

export function LensAdvisorBanner() {
  const bannerLabel = useTranslate("steps.type.lensAdvisor.label");
  const bannerAction = useTranslate("steps.type.lensAdvisor.action");
  const config = useSelector((state: any) => state.config);
  const cookieLA = useCookieLensAdvisor();
  const dispatch = useDispatch();

  let newConfig = {
    ...config,
    useGetReviewObjectLensAdvisor,
  };

  let tempLAModule = { ...config.lensAdvisorModule };
  const openLensAdvisor = () => {
    dispatch(workflowActions.setIsLensAdvisorOpened(true));
    let laDiv = document.createElement("div");
    laDiv.id = "laApp"; //put la selector
    document.body.appendChild(laDiv);
    if (cookieLA?.laContent !== null && cookieLA?.laContent !== undefined) {
      tempLAModule = {
        ...tempLAModule,
        content: cookieLA?.laContent,
      };
    }
    if (cookieLA?.laStyle !== null && cookieLA?.laStyle !== undefined) {
      tempLAModule = {
        ...tempLAModule,
        style: cookieLA?.laStyle,
      };
    }
    if (cookieLA?.laSurvey !== null && cookieLA?.laSurvey !== undefined) {
      tempLAModule = {
        ...tempLAModule,
        surveyData: {
          ...tempLAModule.surveyData,
          survey: cookieLA?.laSurvey,
        },
      };
    }
    if (cookieLA?.laRules !== null && cookieLA?.laRules !== undefined) {
      tempLAModule = {
        ...tempLAModule,
        surveyData: {
          ...tempLAModule.surveyData,
          rules: cookieLA?.laRules,
        },
      };
    }
    newConfig = {
      ...newConfig,
      lensAdvisorModule: tempLAModule,
    };
    var laApp = window?.LensAdvisor?.widget.new(newConfig);
    laApp.init();
  };

  return (
    <div className="LensAdvisorBanner__container">
      {" "}
      <p className="LensAdvisorBanner__container__label">
        {bannerLabel}
        <a
          className="LensAdvisorBanner__container__label__action"
          onClick={() => openLensAdvisor()}
        >
          {bannerAction}
        </a>
      </p>
    </div>
  );
}
