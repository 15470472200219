import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function useCookieLensAdvisor() {
  const laContentCookie = getCookieByName("la-content", false);
  const laSurveyCookie = getCookieByName("la-survey", false);
  const laRulesCookie = getCookieByName("la-rules", false);
  const laStyleCookie = getCookieByName("la-style", false);

  const cookieReturn = {
    laContent: laContentCookie,
    laSurvey: laSurveyCookie,
    laRules: laRulesCookie,
    laStyle: laStyleCookie,
  };
  return cookieReturn;
}
