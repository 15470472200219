import React from "react";
import { useTranslate } from "../translations/TranslationHooks";

const useGetReviewObjectLensAdvisor = (
  config: any,
  confirmedTitlesMap: any, // confirmedTitle degli step
  lensObject: any,
  currentPrescription?: any
) => {
  const valuesToEvaluate = [
    "type",
    "brand",
    "treatmentFamily",
    "blueLight",
    "color",
    "transition",
    "designType",
    "thickness",
  ];
  const content = config?.lensesData?.content;
  const lensPackage = lensObject?.lensPackage;
  const reviewObject = {};

  // per ognuna delle chiavi di valuesToEvaluate controllare se lensPackage.valuesToEvaluate[x] esiste
  // se esiste prendere il valore e andare a cercare la corrispondenza in content

  valuesToEvaluate.map((value: string, index) => {
    if (
      lensPackage?.hasOwnProperty(value) &&
      lensPackage?.[value] !== null &&
      lensPackage?.[value] !== undefined
    ) {
      reviewObject[value] = {
        description: content?.[value]?.[lensPackage?.[value]]?.title,
        name: confirmedTitlesMap?.[value],
        sequence: index + 1,
      };
    }
    if (
      value === "treatmentFamily" &&
      (lensPackage?.[value] === null || lensPackage?.[value] === undefined)
    ) {
      reviewObject[value] = {
        description: "Clear lenses",
        name: confirmedTitlesMap?.[value],
        sequence: index + 1,
      };
    }
  });

  if (currentPrescription?.prescriptionFlow) {
    reviewObject["advancedPrescription"] = {
      description: "Entered manually",
      name: "Prescription:",
      sequence: 2,
    };
  } else {
    if (
      !currentPrescription ||
      (currentPrescription &&
        (!currentPrescription.prescriptionFlow ||
          currentPrescription.prescriptionFlow === "LATER" ||
          currentPrescription.prescriptionFlow === "later"))
    ) {
      reviewObject["advancedPrescription"] = {
        description: "We'll ask for it after the checkout",
        name: "Prescription:",
        sequence: 2,
      };
    }
  }
  if (
    lensPackage?.hasOwnProperty("antiReflective") &&
    lensPackage?.["antiReflective"]
  ) {
    reviewObject["finishings"] = {
      children: [
        {
          sequence: 0,
          name: content?.antiReflective?.[lensPackage?.antiReflective]?.title,
        },
      ],
      name: "Finishings",
      sequence: 9,
    };
  }
  if (
    lensPackage?.hasOwnProperty("antiReflective") &&
    lensPackage.hasOwnProperty("antiScratch")
  ) {
    reviewObject["finishings"]?.children.push({
      sequence: 1,
      name: "Scratch-resistant",
    });
  }

  if (
    lensPackage?.hasOwnProperty("uvProtection") &&
    lensPackage?.["uvProtection"]
  ) {
    reviewObject["finishings"]?.children.push({
      sequence: 2,
      name: "UV-Protection",
    });
  }

  if (
    lensPackage?.hasOwnProperty("antiReflective") &&
    lensPackage?.hasOwnProperty("blueLight") &&
    lensPackage?.["blueLight"]
  ) {
    reviewObject["finishings"]?.children.push({
      sequence: 3,
      name: content?.blueLight?.[lensPackage?.["blueLight"]].title,
    });
  }

  return reviewObject;
};

export default useGetReviewObjectLensAdvisor;
