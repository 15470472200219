import { getCheapPackageFromList, getFrameOnlyKey } from "../ConfigLoader";
import { useSelector } from "react-redux";
import { workflow } from "../models/Workflow";
import { useEffect } from "react";
import { useCurrentPackages } from "./useCurrentPackages";
import { useCurrentPrices } from "./useCurrentPrices";
import { useCurrentType } from "./useCurrentType";
import { usePrismPrice } from "./usePrismPrice";

export function useStepAnalytics() {
  const currentPackages = useCurrentPackages();
  const configFrame = useSelector((state: any) => state.config?.data?.frame);
  const currentFrame = useSelector(
    (state: any) => state.mixAndMatch?.selectedFrame
  );
  const configData = useSelector((state: any) => state.config?.data);
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const loadingStep = useSelector((state: any) => state.workflow?.loadingStep);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const isPrismPriceAdded = useSelector(
    (state: any) => state.prescription?.isPrismPriceAdded
  );
  const prismPrice = usePrismPrice();
  const currentPrices = useCurrentPrices();
  const getAliases = () => {
    let stepChain = workflow.stepChain;

    let stepNames = stepChain.map((step) => {
      return step.key;
    });
    let ret = {};
    stepNames.map((step) => {
      switch (step) {
        case "MixAndMatch":
          ret[step] = "Mixmatch";
          break;
        case "Type":
          ret[step] = "LensType";
          break;
        case "AdvancedPrescription":
          ret[step] = "LensPrescription";
          break;
        case "Brand":
          ret[step] = "LensBrand";
          break;
        case "Thickness":
          ret[step] = "LensMaterial";
          break;
        case "DesignType":
          ret[step] = "LensDesign";
          break;
        case "TransitionColor":
          ret[step] = "LensLight";
          break;
        case "LensColor":
          ret[step] = "LensColour";
          break;
        case "Treatments":
          ret[step] = "LensTreatmentDetail";
          break;
        case "TreatmentsFamily":
          ret[step] = "LensTreatment";
          break;
        case "GVPTreatment":
          ret[step] = "LensTreatmentDetail";
          break;
        case "Review":
          ret[step] = "LensReview";
          break;
        default:
          ret[step] = step;
          break;
      }
    });

    return ret;
  };

  const getStepNameForTealium = () => {
    let aliases = getAliases();
    return aliases[currentStep.key];
  };

  const pushAnalyticsData = () => {
    //if (process.env.DEV) return;
    let Order_PrescriptionMethod_placeholder: {};
    let Events_PrescriptionOk_placeholder: {};
    try {
      let stepChain = workflow.stepChain;
      let isAdvancedPrescriptions = stepChain?.filter(
        (step) => step.key == "AdvancedPrescription"
      );
      if (isAdvancedPrescriptions) {
        let nextIndex = isAdvancedPrescriptions[0]?.progressive + 1;
        let nextStep = stepChain.filter(
          (step) => step.progressive == nextIndex
        )[0];
        const params = nextStep?.params?.[0];
        if (params) {
          if (
            (params.key == "type" &&
              params.value !== "Single Vision" &&
              params.value !== "Progressive") ||
            (params.key == "advancedPrescription" &&
              !params.attributes?.sendingMethod)
          ) {
            Order_PrescriptionMethod_placeholder = {
              Order_PrescriptionMethod: "skipped",
            };
            Events_PrescriptionOk_placeholder = {
              Events_PrescriptionOk: 0,
            };
          } else {
            if (params.attributes?.sendingMethod) {
              Order_PrescriptionMethod_placeholder = {
                Order_PrescriptionMethod: params.attributes.sendingMethod,
              };
            }
            if (params.attributes?.sendingMethod === "account") {
              Order_PrescriptionMethod_placeholder = {
                Order_PrescriptionMethod: "myaccount",
              };
            }
            Events_PrescriptionOk_placeholder = {
              Events_PrescriptionOk: 1,
            };
          }
        }
      }
      const selections = workflow.getPrevCurrentParameterStep();
      const product = getFirstProduct() || ({} as Partial<Product>);

      if (selections) {
        if (
          !selections.length ||
          !selections.find((sel) => sel.key === "type")
        ) {
          product.Type = "STD";
        }
        selections.forEach((sel) => {
          switch (sel.key) {
            case "type":
              let configFrameOnly = getFrameOnlyKey(
                currentPackages,
                configData
              );
              if (sel.value === configFrameOnly) {
                product.Type = "STD";
                product.LensType = "PLANO";
              } else {
                product.Type = "RX";
                if (sel.value === "Non_prescription") {
                  product.LensType = "PLANO";
                } else {
                  product.LensType = "RX";
                }
              }
              break;
            case "brand":
              product.LensBrand = sel.value;
              product.Brand = sel.value;
              break;
            case "designType":
              product.LensDesign = sel.value;
              break;
            case "thickness":
              product.LensMaterial = sel.value;
              break;
            case "blueLight":
              product.LensAntiblue = sel.value;
              break;
            case "transition":
              product.LensTransition = sel.value;
              break;
            case "addOns":
              product.Addon = sel.value?.selection || null;
            case "color":
              if (sel?.optionalAttributes?.key === "brand") {
                product.LensBrand = sel?.optionalAttributes.value;
                product.Brand = sel?.optionalAttributes.value;
              }
              product.LensColor = sel.value;
          }
        });
        product.Price = (
          isPrismPriceAdded
            ? currentPrices.total.price +
            prismPrice -
            currentPrices.total.discount
            : currentPrices.total.price - currentPrices.total.discount
        ).toFixed(2);
        product.PriceFull = isPrismPriceAdded
          ? currentPrices.total.price.toFixed(2) + prismPrice
          : currentPrices.total.price.toFixed(2);
      }

      let currentPackage = getCheapPackageFromList(currentPackages);
      if (currentPackage) {
        product.LensUPC = currentPackage.lensPackage.upc;
      }
      console.log(`RXC: push analytics impression`);

      // @ts-ignore
      if (!window.tealium_data2track) return;
      // @ts-ignore
      window.tealium_data2track.push({
        id: "Impression",
        Rx_Release: useReleaseVersion(),
        Page_Type: "",
        Page_Section1:
          configFrame.category === "SUNGLASSES" ? "SunLensPanel" : "LensPanel",
        Page_Section2:
          currentStep?.key?.toLowerCase() === "protectionplan"
            ? "ProtectionPlan"
            : currentStep?.key?.toLowerCase() === "mixandmatch"
              ? "Mixmatch"
              : getStepNameForTealium(),
        Products: {
          [currentFrame?.upc ? currentFrame.upc : configFrame.upc]: product,
        },
        ...Order_PrescriptionMethod_placeholder,
        ...Events_PrescriptionOk_placeholder,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      currentPackages &&
      configFrame &&
      configData &&
      configContent &&
      !loadingStep
    ) {
      pushAnalyticsData();
    }
  }, [loadingStep /* configFrame, configData, configContent, loadingStep */]);
}

export function useReleaseVersion() {
  const scripts = Array?.from(document.getElementsByTagName("script"));
  const rxcScript = scripts?.find((script) => {
    return script?.src?.includes("rxc.js");
  });
  const splittedScript = rxcScript?.src.split(/[//]/);
  const version = splittedScript?.[4].split("v")[1];
  return version;
}

export function getFirstProduct(): UtagProduct | null {
  //@ts-ignore
  const products: { [id: string]: UtagProduct } = window.utag_data?.Products;

  if (!products) return null;

  const [first] = Object.keys(products);

  return products[first];
}

function getPageSection2(selections: { key: string; value: string }[]) {
  const brand = selections.find(({ key }) => key === "brand")?.value;
  switch (brand) {
    case "Ray-Ban":
    case "Ray-Ban Jr":
      return "RB";
    case "Osi":
    case "Oakley":
      return "OO";
    case "Costa Del Mar":
      return "CO";
    default:
      return "Others";
  }
}

type Product = {
  Type: string;
  LensType: string;
  LensBrand: string;
  Brand: string;
  LensDesign: string;
  LensMaterial: string;
  LensAntiblue: string;
  LensTransition: string;
  Addon: string;
  LensColor: string;
  LensUPC: string;
  Price: number | string;
  PriceFull: number | string;
};
export type UtagProduct = {
  BackOrder: string;
  Badges: string;
  Brand: string;
  Case: string;
  Category: string;
  Conf_Change: string;
  Conf_Conf: string;
  Conf_IsUpcSupported: string;
  DisplaySKU: string;
  Engraving: string;
  EngravingText: string;
  Fg_Badge: string;
  Fg_BestMatch: string;
  Frame: string;
  FrameColor: string;
  FrameTechnology: string;
  FrameType: string;
  Id: string;
  IdNormalized: string;
  Image: string;
  InsuranceBenefits: string;
  InsuranceCode: string;
  Lens: string;
  LensAntiblue: string;
  LensBrand: string;
  LensColor: string;
  LensTechnology: string;
  LensTransition: string;
  LensType: string;
  LensUPC: string;
  MoCo: string;
  ModelCode: string;
  ModelName: string;
  OosOptions: string;
  PartNumber: string;
  Preorder: string;
  Price: number;
  PriceFull: string;
  Return_Reason: string;
  Sg_Badge: string;
  Sg_BestMatch: string;
  Shape: string;
  ShippingTo: string;
  Size: string;
  Sku: string;
  Status: string;
  Stock: string;
  Tags: string;
  Type: string;
  Units: number;
  Url: string;
  ViewPdp: string;
  ViewPdp_Ecom: string;
  ViewPdp_EcomOos: string;
  ViewPdp_EcomOosEmailView: string;
  Visibility: string;
  Vm_Badge: string;
  LensDesign?: string;
  LensMaterial?: string;
  Addon?: string;
};
