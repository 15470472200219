import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import GenericButton from "../common/GenericButton";
import { workflow } from "../../configurator/models/Workflow";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import Image from "../common/Image";
import { useCurrentPrices, useImagery, useShowFramePlusLensesPrice, useBundlesPrices, useShowSavingsLabel } from "@hooks";
import { modalsActions } from "../../../src/redux/slices/modals";
import { useCurrentPackages } from "../../configurator/hooks";
import { isFunction } from "lodash";
import HtmlParser from "html-react-parser";

interface BottomBarProps { }

/**
 * The component handling the logic of the bottom bar in the desktop view. It uses the
 * ```useCurrentPrices()``` hook to access the current prices for the frame, lens, and total.
 */
export function BottomBar({ }: BottomBarProps) {
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.progressive
  );
  const currentStepName = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );
  const currentPrices = useCurrentPrices();
  const [prices, setPrices] = useState(null);

  const addToBagLabel = useTranslate("steps.treatments.addToBag");
  const insuranceSyncedLabel = useTranslate("insuranceSyncedLabel");
  const insuranceLegalCopy = useTranslate("insuranceLegalBanner");
  const addInsuranceLabel = useTranslate("insuranceButtonLabel");
  const removeInsuranceLabel = useTranslate("insuranceButtonLabelRemove");
  const insuranceLabelRemove = useTranslate("insuranceLabelRemove");
  const imageryObject = useImagery();
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const pricingMethodConfig = useSelector(
    (state: any) => state.insurance?.pricingMethodConfig
  );
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );

  const genericImage = useSelector(
    (state: any) => state.config?.baseURLs?.genericImage
  );

  const reviewObjectForCart = useSelector(
    (state: any) => state.workflow?.reviewObjectForCart
  );

  const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const lensBundleStep = workflow?.stepChain?.find(s => s.key === "LensBundle")?.progressive;

  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();

  const bundlesEnabled = useBundlesPrices();

  const showSavingsLabel = useShowSavingsLabel();

  const currentPackages = useCurrentPackages();

  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );

  const configActionLoadContent = useSelector(
    (state: any) => state.config?.actionsModule?.loadContent
  );

  const promoType = useSelector((state: any) => state.workflow?.promoType);

  const [promoInPackage, setPromoInPackage] = useState("");

  const [promoBanners, setPromoBanners] = useState({
    desktop: "",
    mobile: "",
  });

  useEffect(() => {
    if (
      currentPackages && configContent && configActionLoadContent
    ) {
      loadPromoBanner();
    }
  }, [currentPackages, configContent, configActionLoadContent]);

  const loadPromoBanner = () => {
    const packageWithPromo = currentPackages?.find((item) => {
      return item.lensPackage.promo !== undefined;
    });
    let promoKey = "";
    let promoInConfig = null;
    if (packageWithPromo) {
      if (packageWithPromo?.lensPackage?.promo) {
        promoKey = Object.keys(packageWithPromo?.lensPackage.promo)[0];
        if (promoKey) {
          setPromoInPackage(promoKey);
        }
      }
    }
    if (configContent?.promo) {
      promoInConfig = Object.keys(configContent?.promo);
    }
    if (promoInConfig && promoInConfig.find((promo) => promo === promoKey)) {
      let promoContentKey = promoInConfig.find((promo) => promo === promoKey);
      setPromoBanners({
        desktop: configContent?.promo[promoContentKey].title,
        mobile: configContent?.promo[promoContentKey].title,
      });
    } else if (isFunction(configActionLoadContent)) {
      const contentIds = ["DESK_PromoBanner", "MOB_PromoBanner"];
      let promises: Promise<string>[] = contentIds.map((ids) =>
        configActionLoadContent(ids)
      );
      Promise.all(promises).then((htmls: string[]) => {
        setPromoBanners({
          desktop: htmls[0],
          mobile: htmls[1],
        });
      });
    }
  };

  const isInsurable = () => {
    return !!configInsurance && !bundlesEnabled;
  };

  useEffect(() => {
    setIsInsuranceEnabled(insuranceEnabled);
  }, [insuranceEnabled]);

  useEffect(() => {
    setIsInsuranceLoading(insuranceLoading);
  }, [insuranceLoading]);

  useEffect(() => {
    setPrices(currentPrices);
  }, [currentPrices]);

  const handleInsuranceClick = () => {
    if (configInsurance) {
      if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
        configInsurance.openInsurancePanel();
      } else if (
        isInsuranceEnabled &&
        configInsurance.removeInsuranceBenefits
      ) {
        configInsurance.removeInsuranceBenefits();
      }
    }
  };

  const getDataElementIdForInsurance = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-UseInsurance";
  };

  const onAddToBag = () => {
    window.dispatchEvent(
      new CustomEvent("AddToCartEvent", {
        detail: {
          selectedWarranty:
            sessionWarranty?.id === "asIs" ? null : sessionWarranty,
          reviewObjectForCart: reviewObjectForCart,
          imagery: imageryObject,
        },
      })
    );
    setButtonLoading(true);
  };
  return (
    <div
      className={
        pricingMethodConfig &&
          (pricingMethodConfig === "QUICK" ||
            pricingMethodConfig === "STANDARD_REVIEW")
          ? "BottomBar__legalBanner"
          : (showFramePlusLensesPrice && currentStep < lensBundleStep && currentStepName !== "AdvancedPrescription")
            ? "BottomBar__hide"
            : "BottomBar"
      }
      style={{ boxShadow: currentStep === lensBundleStep ? "0px -5px 15px 0px rgba(0, 0, 0, 0.05)" : "", height: currentStep === lensBundleStep ? "90px" : "" }}
    >
      {currentStep !== lensBundleStep && <div
        className={
          "BottomBar__container" +
          (currentStep &&
            currentStep === workflow.getMaxStep(workflow.stepChain).progressive
            ? " BottomBar__container__containerLastPage"
            : "") +
          (!isInsurable() && !showSavingsLabel ? " BottomBar__container__noInsurance" : "")
        }
      >
        {isInsurable() && (
          <div className={"BottomBar__container__left"}>
            {currentStep &&
              currentStep !==
              workflow.getMaxStep(workflow.stepChain).progressive &&
              (isInsuranceLoading || isInsuranceEnabled ? (
                <div className="BottomBar__container__left__wrapper">
                  <div className="BottomBar__container__left__insuranceWrapper">
                    <div
                      className={"BottomBar__container__left__insuranceEnabled"}
                    >
                      {insuranceSyncedLabel}
                    </div>
                    <BrandComponent
                      componentName="InsuranceTooltip"
                      parameter={{ id: "tooltipDesktop" }}
                    />
                  </div>
                  <span
                    className="BottomBar__container__left__removeInsuranceLabel"
                    onClick={handleInsuranceClick}
                  >
                    {insuranceLabelRemove}
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className={"BottomBar__container__left__insurance"}
                  onClick={handleInsuranceClick}
                  data-element-id={"X_X_" + currentStepName + "_UseInsurance"}
                >
                  {addInsuranceLabel}
                </button>
              ))}
            {currentStep &&
              currentStep ===
              workflow.getMaxStep(workflow.stepChain).progressive && (
                <>
                  {isInsuranceEnabled &&
                    !configInsurance.removeInsuranceBenefits ? (
                    <div
                      className={"BottomBar__container__left__insuranceEnabled"}
                    >
                      {insuranceSyncedLabel}
                    </div>
                  ) : (
                    <span
                      className={
                        "BottomBar__container__left__addInsuranceButton"
                      }
                      title={addToBagLabel}
                      id="continueToReviewButton"
                      onClick={handleInsuranceClick}
                      data-element-id={getDataElementIdForInsurance()}
                    >
                      {isInsuranceEnabled
                        ? removeInsuranceLabel
                        : addInsuranceLabel}
                    </span>
                  )}
                </>
              )}
          </div>
        )}
        {!isInsurable() && showSavingsLabel && (
          <div className={"BottomBar__container__left"}>
            <div className="BottomBar__container__left__wrapper">
              <BrandComponent componentName="Savings" parameter={{ discount: prices?.total?.discount }}></BrandComponent>
            </div>
          </div>
        )}
        <div
          className={
            "BottomBar__container__right" +
            (!isInsurable() && !showSavingsLabel ? " BottomBar__container__right__noInsurance" : "")
          }
        >
          {prices && !workflow.isLastStep() && (
            <>
              <BrandComponent
                componentName="BottomBarTotalPrice"
                parameter={{
                  total: prices.total,
                }}
              />
            </>
          )}

          {currentStep && workflow.isLastStep() && (
            <GenericButton
              className={`BottomBar__container__right__addToBagButton ${buttonLoading ? "loading" : ""
                }`}
              title={addToBagLabel}
              id="continueToReviewButton"
              type="button"
              noArrow={true}
              disabled={buttonLoading}
              handleClick={onAddToBag}
              dataElementId="X_X_Prod_AddCart"
            >
              {buttonLoading ? (
                <Image url={genericImage} fileName="loader" />
              ) : (
                addToBagLabel
              )}
            </GenericButton>
          )}
          {pricingMethodConfig === "QUICK" ||
            (pricingMethodConfig === "STANDARD_REVIEW" && (
              <div className="BottomBar__container__right__legalBanner">
                {insuranceLegalCopy}
              </div>
            ))}
        </div>
      </div>}
      {currentStep === lensBundleStep && <div
        className={
          "BottomBar__container" +
          (currentStep &&
            currentStep === workflow.getMaxStep(workflow.stepChain).progressive
            ? " BottomBar__container__containerLastPage"
            : "") +
          (!isInsurable() && !showSavingsLabel ? " BottomBar__container__noInsurance" : "")
        }
        style={{ display: "flex", flexDirection: "column" }}
      >
        {isInsuranceEnabled === false &&
          promoBanners?.desktop &&
          (promoInPackage ||
            (promoType &&
              currentPackages[0] &&
              (promoType === currentPackages[0].frame.promoTypeDetail ||
                !currentPackages[0].frame.promoTypeDetail))) && (
            <div className={"BottomBar__container__lensBundle__topBadge"}>
              {HtmlParser(promoBanners.desktop)}
            </div>
          )}
        <div className={"BottomBar__container__content"}>
          {isInsurable() && (
            <div className={"BottomBar__container__left"}>
              {currentStep &&
                currentStep !==
                workflow.getMaxStep(workflow.stepChain).progressive &&
                (isInsuranceLoading || isInsuranceEnabled ? (
                  <div className="BottomBar__container__left__wrapper">
                    <div className="BottomBar__container__left__insuranceWrapper">
                      <div
                        className={"BottomBar__container__left__insuranceEnabled"}
                      >
                        {insuranceSyncedLabel}
                      </div>
                      <BrandComponent
                        componentName="InsuranceTooltip"
                        parameter={{ id: "tooltipDesktop" }}
                      />
                    </div>
                    <span
                      className="BottomBar__container__left__removeInsuranceLabel"
                      onClick={handleInsuranceClick}
                    >
                      {insuranceLabelRemove}
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className={"BottomBar__container__left__insurance"}
                    onClick={handleInsuranceClick}
                    data-element-id={"X_X_" + currentStepName + "_UseInsurance"}
                  >
                    {addInsuranceLabel}
                  </button>
                ))}
              {currentStep &&
                currentStep ===
                workflow.getMaxStep(workflow.stepChain).progressive && (
                  <>
                    {isInsuranceEnabled &&
                      !configInsurance.removeInsuranceBenefits ? (
                      <div
                        className={"BottomBar__container__left__insuranceEnabled"}
                      >
                        {insuranceSyncedLabel}
                      </div>
                    ) : (
                      <span
                        className={
                          "BottomBar__container__left__addInsuranceButton"
                        }
                        title={addToBagLabel}
                        id="continueToReviewButton"
                        onClick={handleInsuranceClick}
                        data-element-id={getDataElementIdForInsurance()}
                      >
                        {isInsuranceEnabled
                          ? removeInsuranceLabel
                          : addInsuranceLabel}
                      </span>
                    )}
                  </>
                )}
            </div>
          )}
          {!isInsurable() && showSavingsLabel && (
            <div className={"BottomBar__container__left"}>
              <div className="BottomBar__container__left__wrapper">
                <BrandComponent componentName="Savings" parameter={{ discount: prices?.total?.discount }}></BrandComponent>
              </div>
            </div>
          )}
          <div
            className={
              "BottomBar__container__right" +
              (!isInsurable() && !showSavingsLabel ? " BottomBar__container__right__noInsurance" : "")
            }
          >
            {prices && !workflow.isLastStep() && (
              <>
                <BrandComponent
                  componentName="BottomBarTotalPrice"
                  parameter={{
                    total: prices.total,
                  }}
                />
              </>
            )}

            {currentStep && workflow.isLastStep() && (
              <GenericButton
                className={`BottomBar__container__right__addToBagButton ${buttonLoading ? "loading" : ""
                  }`}
                title={addToBagLabel}
                id="continueToReviewButton"
                type="button"
                noArrow={true}
                disabled={buttonLoading}
                handleClick={onAddToBag}
                dataElementId="X_X_Prod_AddCart"
              >
                {buttonLoading ? (
                  <Image url={genericImage} fileName="loader" />
                ) : (
                  addToBagLabel
                )}
              </GenericButton>
            )}
            {pricingMethodConfig === "QUICK" ||
              (pricingMethodConfig === "STANDARD_REVIEW" && (
                <div className="BottomBar__container__right__legalBanner">
                  {insuranceLegalCopy}
                </div>
              ))}
          </div>
        </div>
      </div>}
    </div>
  );
}
