import React, { useEffect, useRef, useState, Fragment, useMemo } from "react";
import { isFunction, orderBy } from "lodash";
import { isDesktop, isMobile } from "react-device-detect";
import { BrandComponent } from "../../../components/common/BrandComponent";
import { useTranslation } from "../../translations/TranslationHooks";
import { workflow } from "../../models/Workflow";
import { useDispatch, useSelector } from "react-redux";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";
import {
  useAdvancedPrescriptionOptions,
  useCurrentPackages,
  usePrescriptionIssueDate,
  usePrescriptionsFromMyAccount,
  useMandatoryManualInput,
  useRetriveContextualMessage,
  useCurrentType,
  useCurrentBrand,
} from "@hooks";
import { prescriptionActions } from "../../../redux/slices/prescription";
import { workflowActions } from "../../../redux/slices/workflow";
import ReferencedSection from "../../../components/common/ReferencedSection";
import {
  callInsuranceService,
  getCheapPackageFromList,
  hasValidPrescription,
  isFramePrescriptionCompatible,
} from "../../../configurator/ConfigLoader";
import "./default.module.scss";
import { sendMonetateEvent } from "../../utils/sendMonetateEvent";

export enum PrescriptionModeEnum {
  MANUAL = "MANUAL",
  CMD = "CMD",
  UPLOAD = "UPLOAD",
  ACCOUNT = "ACCOUNT",
  NESTED = "NESTED",
  NESTED_CA = "NESTED_CA",
  LATER = "LATER",
}

import { StepBadgeType } from "../../../components/StepBadge/types/StepBadgeType";
import { left } from "fp-ts/lib/Separated";
import { colorActions } from "src/redux/slices/color";

export function AdvancedPrescription(props) {
  const [isDoctorSelected, setIsDoctorSelected] = useState(false);
  const [prescriptionMode, setPrescriptionMode] = useState(null);
  const [prescriptionObject, setPrescriptionObject] = useState(null);
  const [noPrescriptionsFound, setNoPrescriptionsFound] = useState(false);
  const [file, setFile] = useState(null);
  const [withPrescriptionsFromMyAccount, setWithPrescriptionsFromMyAccount] =
    useState(null);
  const fileInputRef = useRef(null);

  const prescriptionOptions = useAdvancedPrescriptionOptions();
  const currentPackages = useCurrentPackages();
  const translate = useTranslation();
  const { prescriptionsFromMyAccount, isLoggedIn, login } =
    usePrescriptionsFromMyAccount();
  const { validTimeInMonth } = usePrescriptionIssueDate();

  const prescriptionModule = useSelector(
    (state: any) => state.config?.prescriptionModule
  );
  const currentPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const notSkipGUI = useSelector(
    (state: any) => state.prescription?.isLoadedWithoutEdit
  );
  const selectedPrescriptionMode = useSelector(
    (state: any) => state.workflow?.selectedPrescriptionMode
  );
  const currentType = useCurrentType();
  const frame = useSelector((state: any) => state.config?.data?.frame);
  const ctxMessage = useRetriveContextualMessage();

  const isManualInput = prescriptionMode === PrescriptionModeEnum.MANUAL;
  const isCallMyDoctor = prescriptionMode === PrescriptionModeEnum.CMD;
  const isUpload = prescriptionMode === PrescriptionModeEnum.UPLOAD;
  const isFromMyAccount = prescriptionMode === PrescriptionModeEnum.ACCOUNT;

  const [isLoading, setIsLoading] = useState(true);

  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );

  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );

  const dispatch = useDispatch();

  const brand = useCurrentBrand();


  // useEffect(() => {
  //   if (isInsuranceEnabled) {
  //     let filteredPackages = currentPackages.filter((p) => {
  //       return p.lensPackage.type === props.step.params?.[0]?.value;
  //     });

  //     let cheapestPackages = null;
  //     if (filteredPackages.length > 1) {
  //       cheapestPackages = [getCheapPackageFromList(filteredPackages)];
  //     } else {
  //       cheapestPackages = filteredPackages;
  //     }

  //     let insurablePackages = cheapestPackages.filter(
  //       (pkg) => pkg.lensPackage.insurable
  //     );

  //     if (insurablePackages.length) {
  //       callInsuranceService(
  //         cheapestPackages,
  //         configInsurance,
  //         frame,
  //         dispatch
  //       );
  //     }
  //   }
  // }, [isInsuranceEnabled]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    dispatch(workflowActions.setSelectedPrescriptionMode(true));
    setPrescriptionMode(PrescriptionModeEnum.UPLOAD);
  };

  const getSupportedFileExtensions = () => {
    if (prescriptionModule && prescriptionModule.fileExtensions) {
      let parsedExt = [];
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext)
      );
      prescriptionModule.fileExtensions.forEach((ext) =>
        parsedExt.push("." + ext.toUpperCase())
      );
      return parsedExt.join();
    }
  };

  const formatUploadCardDescriptionLabel = (label) => {
    let supportedExtensions = getSupportedFileExtensions()
      .split(",")
      .join(", ");
    return label
      .replace("###FILE_FORMATS###", supportedExtensions)
      .replace("###FILE_SIZE###", prescriptionModule?.maxFileSize);
  };

  const closePrescriptionForm = () => {
    if (
      (isCallMyDoctor &&
        prescriptionObject?.prescriptionFlow == PrescriptionModeEnum.CMD) ||
      (isUpload &&
        prescriptionObject?.prescriptionFlow === PrescriptionModeEnum.UPLOAD) ||
      (isManualInput &&
        prescriptionObject?.prescriptionFlow === PrescriptionModeEnum.MANUAL)
    ) {
      reduxDispatch(prescriptionActions.setCurrentPrescription(null));
      setPrescriptionObject(null);
      prescriptionModule.clearExtendedPrescription();
    }
    dispatch(workflowActions.setSelectedPrescriptionMode(false));
    changeTitleCallMyDoctor(false);
    setPrescriptionMode(null);
  };
  const changeTitleCallMyDoctor = (val: boolean) => setIsDoctorSelected(val);

  const loadPreselectEnabled = useSelector(
    (state: any) => state.workflow?.loadPreselect
  );

  const reduxDispatch = useDispatch();

  // MONETATE HOOK EVENT
  // useEffect(() => {
  //   // send custom event only when prescriptionMode has been selected
  //   if (prescriptionMode) {
  //     sendMonetateEvent(props.step.key, prescriptionMode);
  //   } else {
  //     sendMonetateEvent(props.step.key);
  //   }
  // }, [prescriptionMode]);

  /* useEffect(() => {
    if (enableMandatory && !prescriptionMode) {
      setPrescriptionMode(PrescriptionModeEnum.MANUAL);
      dispatch(workflowActions.setSelectedPrescriptionMode(true));
    }
  }, [selectedPrescriptionMode]); */

  // useEffect(() => {
  //   setWithPrescriptionsFromMyAccount(false);
  // }, []);
  useEffect(() => {
    if (!selectedPrescriptionMode) {
      setPrescriptionMode(null);
    }
  }, [selectedPrescriptionMode]);

  useEffect(() => {
    if (prescriptionOptions) {
      if (
        !prescriptionOptions.prescriptionEnabled ||
        !prescriptionModule ||
        prescriptionModule.prescriptionType === "SIMPLE" ||
        loadPreselectEnabled
      ) {
        //skip advancePrescription step
        if (!loadPreselectEnabled) {
          workflow.goNextStep(workflow.getPrevCurrentParameterStep(), true);
        } else {
          let value = "";
          let sendingMethod = "";
          if (!currentPrescription) {
            value = translate("steps.advancedPrescription.later.review.title");
          } else if (
            currentPrescription.prescriptionFlow === PrescriptionModeEnum.MANUAL
          ) {
            value = translate("steps.advancedPrescription.manual.review.title");
            sendingMethod = "manual";
          } else if (
            currentPrescription.prescriptionFlow ===
            PrescriptionModeEnum.UPLOAD ||
            (currentPrescription.prescriptionFlow ===
              PrescriptionModeEnum.NESTED &&
              currentPrescription.fileName !== undefined)
          ) {
            value = translate("steps.advancedPrescription.upload.review.title");
            sendingMethod = "upload";
          } else if (
            currentPrescription.prescriptionFlow === PrescriptionModeEnum.CMD ||
            (currentPrescription.prescriptionFlow ===
              PrescriptionModeEnum.NESTED &&
              currentPrescription.name !== undefined)
          ) {
            value = translate("steps.advancedPrescription.doctor.review.title");
            sendingMethod = "calldoctor";
          } else if (
            currentPrescription.prescriptionFlow ===
            PrescriptionModeEnum.ACCOUNT
          ) {
            value = translate(
              "steps.advancedPrescription.account.review.title"
            );
            sendingMethod = "account";
          }
          workflow.goNextStep(
            [
              ...workflow.getPrevCurrentParameterStep(),
              {
                key: "advancedPrescription",
                value: value,
                attributes: {
                  showSeeDetails:
                    shouldShowSeeDetailsInReview(currentPrescription),
                  sendingMethod,
                },
              },
            ],
            prescriptionModule ? false : true
          );
        }
      } else {
        reduxDispatch(workflowActions.setLoadingStep(false));
        if (currentPrescription) {
          setPrescriptionObject(currentPrescription);
        }
        return;
      }
    }
  }, [
    prescriptionOptions,
    prescriptionModule,
    currentPrescription,
    loadPreselectEnabled,
  ]);

  useEffect(() => {
    if (!notSkipGUI && prescriptionObject) {
      switch (prescriptionObject.prescriptionFlow) {
        case PrescriptionModeEnum.MANUAL && prescriptionObject.myAccount:
          setPrescriptionMode(PrescriptionModeEnum.ACCOUNT);
          break;
        case PrescriptionModeEnum.MANUAL:
        case PrescriptionModeEnum.NESTED:
        case PrescriptionModeEnum.ACCOUNT:
          setPrescriptionMode(PrescriptionModeEnum.MANUAL);
          break;
        case PrescriptionModeEnum.UPLOAD:
          setPrescriptionMode(PrescriptionModeEnum.UPLOAD);
          break;
        case PrescriptionModeEnum.CMD:
          setPrescriptionMode(PrescriptionModeEnum.CMD);
          break;
      }
      //going back to advancedPrescription will always show prescription methods
      if (!selectedPrescriptionMode) {
        setPrescriptionMode(null);
      } else {
        dispatch(workflowActions.setSelectedPrescriptionMode(true));
        return;
      }
    }
  }, [prescriptionObject]);

  /**
   *  Skip advanced prescription intermediary step
   */
  useEffect(() => {
    if (!prescriptionOptions) return;
    // if (!prescriptionOptions?.prescriptionSendLaterEnabled?.value) {
    //   if (
    //     prescriptionOptions?.prescriptionDoctorEnabled?.value &&
    //     !prescriptionOptions.prescriptionFromMyAccountEnabled.value &&
    //     !prescriptionOptions.prescriptionManualEnabled.value &&
    //     !prescriptionOptions.prescriptionUploadEnabled.value
    //   ) {
    //     dispatch(workflowActions.setSelectedPrescriptionMode(true));
    //     setPrescriptionMode(PrescriptionModeEnum.CMD);
    //   }
    // if (
    //   prescriptionOptions?.prescriptionManualEnabled?.value &&
    //   !prescriptionOptions.prescriptionDoctorEnabled.value &&
    //   !prescriptionOptions.prescriptionFromMyAccountEnabled.value &&
    //   !prescriptionOptions.prescriptionUploadEnabled.value
    // ) {
    //   dispatch(workflowActions.setSelectedPrescriptionMode(true));
    //   setPrescriptionMode(PrescriptionModeEnum.MANUAL);
    // }
  }, [prescriptionOptions]);

  /*  useEffect(() => {
    if (!isLoggedIn()) return;
    if (
      prescriptionsFromMyAccount.length === 0 ||
      prescriptionsFromMyAccount.every((p) => p.expired)
    ) {
      setPrescriptionMode(PrescriptionModeEnum.MANUAL);
      setWithPrescriptionsFromMyAccount(false);
      return;
    }
  }, [prescriptionsFromMyAccount]); */

  useEffect(() => {
    if (isFromMyAccount && brand === "targetoptical") {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  }, [isFromMyAccount]);

  const rxType = useMemo(() => {
    if (!props.step.params) return "";
    if (
      props.step.params?.[0]?.optionalAttributes?.value?.toLowerCase() ===
      "reading"
    )
      return "READING";

    if (
      props.step.params?.[0]?.optionalAttributes?.value?.toLowerCase() ===
      "distance"
    )
      return "DISTANCE";

    if (props.step.params?.[0]?.value?.toLowerCase() === "progressive")
      return "PROGRESSIVE";
    if (props.step.params?.[0].value?.toLowerCase() === "bifocals") {
      return "BIFOCAL";
    }
    if (props.step.params?.[0].value?.toLowerCase() === "readers") {
      return "READER";
    }
    return "SINGLE_VISION";
  }, [props.step]);

  const configHasPrescription = (type: string) => {
    switch (type) {
      case PrescriptionModeEnum.MANUAL:
        return (
          prescriptionOptions &&
          prescriptionOptions.prescriptionManualEnabled.value
        );
      case PrescriptionModeEnum.ACCOUNT:
        return (
          prescriptionOptions &&
          prescriptionOptions.prescriptionFromMyAccountEnabled.value
        );
      case PrescriptionModeEnum.UPLOAD:
        return (
          prescriptionOptions &&
          prescriptionOptions.prescriptionUploadEnabled.value
        );
      case PrescriptionModeEnum.CMD:
        return (
          prescriptionOptions &&
          prescriptionOptions.prescriptionDoctorEnabled.value
        );
      case PrescriptionModeEnum.LATER:
        return prescriptionOptions?.prescriptionSendLaterEnabled?.value;

      default:
        return false;
    }
  };

  const shouldShowSeeDetailsInReview = (prescription: any) => {
    if (!prescription) {
      return false;
    }
    if (["UPLOAD", "NESTED"].includes(prescription.prescriptionFlow)) {
      const validImageExtensions = ["gif", "jpeg", "jpg", "pjpeg", "png"];
      let fileNameSplit = prescription.fileName.split(".");
      const prescriptionFileExtension =
        fileNameSplit[fileNameSplit.length - 1].toLowerCase();
      return validImageExtensions.includes(prescriptionFileExtension);
    } else {
      return true;
    }
  };

  const handleSuccessPrescription = (prescription) => {
    reduxDispatch(prescriptionActions.setCurrentPrescription(prescription));
    reduxDispatch(prescriptionActions.setLoadedWithoutEdit(false));
    let value = "";
    let sendingMethod = "";
    if (prescription) {
      if (prescription.prescriptionFlow === PrescriptionModeEnum.MANUAL) {
        value = translate("steps.advancedPrescription.manual.review.title");
        sendingMethod = "manual";
      } else if (
        prescription.prescriptionFlow === PrescriptionModeEnum.UPLOAD ||
        (prescription.fileName &&
          (prescription.prescriptionFlow === PrescriptionModeEnum.NESTED ||
            prescription.prescriptionFlow === PrescriptionModeEnum.NESTED_CA))
      ) {
        value = translate("steps.advancedPrescription.upload.review.title");
        sendingMethod = "upload";
      } else if (
        prescription.prescriptionFlow === PrescriptionModeEnum.CMD ||
        (prescription.name &&
          prescription.phone &&
          prescription.prescriptionFlow === PrescriptionModeEnum.NESTED)
      ) {
        value = translate("steps.advancedPrescription.doctor.review.title");
        sendingMethod = "calldoctor";
      } else if (
        prescription.prescriptionFlow === PrescriptionModeEnum.ACCOUNT ||
        (prescription.prescriptionFlow === PrescriptionModeEnum.MANUAL &&
          prescription.myAccount)
      ) {
        value = translate("steps.advancedPrescription.account.review.title");
        sendingMethod = "account";
      }
    }
    workflow.goNextStep([
      {
        key: "advancedPrescription",
        value: value,
        attributes: {
          showSeeDetails: shouldShowSeeDetailsInReview(prescription),
          sendingMethod,
        },
      },
    ]);
  };

  const replaceNullValuesForCylAndSph = (prescription) => {
    if (prescription?.CYL?.OD === null || prescription?.CYL?.OD === undefined) {
      prescription.CYL.OD = 0;
    }
    if (prescription?.CYL?.OS === null || prescription?.CYL?.OS === undefined) {
      prescription.CYL.OS = 0;
    }
    if (prescription?.SPH?.OD === null || prescription?.SPH?.OD === undefined) {
      prescription.SPH.OD = 0;
    }
    if (prescription?.SPH?.OS === null || prescription?.SPH?.OS === undefined) {
      prescription.SPH.OS = 0;
    }
  };

  const onEditPrescription = (prescription, edit) => {
    replaceNullValuesForCylAndSph(prescription);
    setPrescriptionObject({ ...prescription, myAccount: true });
    setPrescriptionMode(PrescriptionModeEnum.MANUAL);
    setWithPrescriptionsFromMyAccount(edit);
    dispatch(workflowActions.setSelectedPrescriptionMode(true));
    setNoPrescriptionsFound(false);
  };

  const onSelectPrescription = (prescription) => {
    //check on null and undefined cyl or sph
    replaceNullValuesForCylAndSph(prescription);
    //check on lens type and prescription type
    if (
      (workflow?.currentStep?.params?.[0].value
        ?.toLowerCase()
        .includes("progressive") ||
        workflow?.currentStep?.params?.[0].value
          ?.toLowerCase()
          .includes("bifocals") ||
        workflow?.currentStep?.params?.[0].optionalAttributes?.value
          ?.toLowerCase()
          .includes("reading")) &&
      (!prescription?.ADD ||
        ([0, null, NaN, undefined].includes(prescription.ADD?.OD) &&
          [0, null, NaN, undefined].includes(prescription.ADD?.OS)))
      // (prescription.ADD.OD === null && prescription.ADD.OS === null) ||
      // (prescription.ADD.OD === 0 && prescription.ADD.OS === 0)
    ) {
      onEditPrescription(prescription, false);
      return;
    } else if (
      (workflow?.currentStep?.params?.[0].value
        ?.toLowerCase()
        .indexOf("single") > -1 ||
        workflow?.currentStep?.params?.[0].value
          ?.toLowerCase()
          .indexOf("distance") > -1) &&
      prescription?.ADD &&
      ![0, null, NaN].includes(prescription.ADD?.OD) &&
      ![0, null, NaN].includes(prescription.ADD?.OS)
    ) {
      onEditPrescription(prescription, false);
      return;
    }
    if (
      !isFramePrescriptionCompatible(frame.rxValues, prescription) ||
      !currentPackages.filter((f) => hasValidPrescription(f, prescription))
        .length
    ) {
      //check frame and lenses compatibility, if not go to manual input
      onEditPrescription(prescription, true);
      return;
    }
    const myAccountPrescription = {
      ...prescription,
      prescriptionFlow: PrescriptionModeEnum.ACCOUNT,
    };

    if (!isFunction(prescriptionModule.saveExtendedPrescription)) {
      console.log(
        "prescriptionModule.SaveExtendedPrescription is not a valid function"
      );
    } else {
      prescriptionModule.saveExtendedPrescription(myAccountPrescription);
    }

    setPrescriptionMode(null);
    dispatch(workflowActions.setSelectedPrescriptionMode(false));
    handleSuccessPrescription(myAccountPrescription);
  };

  const onLoggedIn = () => {
    setPrescriptionMode(null);
    dispatch(workflowActions.setSelectedPrescriptionMode(false));
  };

  const onNewPrescription = () => {
    setPrescriptionObject(null);
    setPrescriptionMode(PrescriptionModeEnum.MANUAL);
    dispatch(workflowActions.setSelectedPrescriptionMode(true));
  };

  const onWithoutPrescriptions = () => {
    setPrescriptionMode(PrescriptionModeEnum.MANUAL);
    setWithPrescriptionsFromMyAccount(false);
    dispatch(workflowActions.setSelectedPrescriptionMode(true));
    setNoPrescriptionsFound(true);
  };

  const getBadgeExist = (type: string) => {
    let s: StepBadgeType = StepBadgeType.BEST_PRESCRIPTION;
    let c = translate(`steps.advancedPrescription.${type}.badge`);
    return c !== `steps.advancedPrescription.${type}.badge` ? s : null;
  };

  const getTextBadge = (type: string) => {
    let c = translate(`steps.advancedPrescription.${type}.badge`);
    return c !== `steps.advancedPrescription.${type}.badge` ? c : null;
  };

  const getManualInputCard = () => {
    return (
      configHasPrescription("MANUAL") && (
        <BrandComponent
          componentName="StepCard"
          key={"manualPrescriptionCard"}
          parameter={{
            title: translate("steps.advancedPrescription.manual.card.title"),
            description: translate(
              "steps.advancedPrescription.manual.card.description"
            ),
            badge: getBadgeExist("manual"),
            textBadge: getTextBadge("manual"),
            imageUrl: "",
            id: "manualPrescriptionCard",
            noImageCard: true,
            onClick: () => {
              /* if (prescriptionObject?.myAccount) {
                setPrescriptionObject(null);
              } */
              setWithPrescriptionsFromMyAccount(false);
              prescriptionObject &&
                prescriptionObject.prescriptionFlow === "MANUAL" &&
                setPrescriptionObject({
                  ...prescriptionObject,
                  myAccount: false,
                  prescriptionFlow: "MANUAL",
                });
              prescriptionObject &&
                prescriptionObject.prescriptionFlow !== "MANUAL" &&
                setPrescriptionObject({
                  myAccount: false,
                  prescriptionFlow: "MANUAL",
                });
              dispatch(workflowActions.setSelectedPrescriptionMode(true));
              setPrescriptionMode(PrescriptionModeEnum.MANUAL);
            },
          }}
        />
      )
    );
  };

  const getAccountCard = () => {
    return (
      configHasPrescription("ACCOUNT") && (
        <BrandComponent
          componentName="StepCard"
          key={"accountPrescriptionCard"}
          parameter={{
            title: translate("steps.advancedPrescription.account.card.title"),
            description: !isLoggedIn()
              ? translate("steps.advancedPrescription.account.card.description")
              : translate(
                "steps.advancedPrescription.fromMyAccount.card.descriptionLoggedIn"
              ),
            badge: getBadgeExist("account"),
            textBadge: getTextBadge("account"),
            imageUrl: "",
            id: "accountPrescriptionCard",
            noImageCard: true,
            onClick: () => {
              dispatch(workflowActions.setSelectedPrescriptionMode(true));
              setPrescriptionMode(PrescriptionModeEnum.ACCOUNT);
            },
          }}
        />
      )
    );
  };

  const getUploadCard = () => {
    return (
      configHasPrescription("UPLOAD") && (
        <Fragment key="uploadPrescriptionCard">
          <BrandComponent
            componentName="StepCard"
            parameter={{
              title: translate("steps.advancedPrescription.upload.card.title"),
              description: formatUploadCardDescriptionLabel(
                translate("steps.advancedPrescription.upload.card.description")
              ),
              badge: getBadgeExist("upload"),
              textBadge: getTextBadge("upload"),
              imageUrl: "",
              id: "uploadPrescriptionCard",
              noImageCard: true,
              onClick: () => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              },
            }}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept={getSupportedFileExtensions()}
            onChange={handleFileChange}
          />
        </Fragment>
      )
    );
  };

  const getCMDCard = () => {
    return (
      configHasPrescription("CMD") && (
        <BrandComponent
          componentName="StepCard"
          key={"doctorPrescriptionCard"}
          parameter={{
            title: translate("steps.advancedPrescription.doctor.card.title"),
            description: translate(
              "steps.advancedPrescription.doctor.card.description"
            ),
            badge: getBadgeExist("doctor"),
            textBadge: getTextBadge("doctor"),
            imageUrl: "",
            id: "doctorPrescriptionCard",
            noImageCard: true,
            onClick: () => {
              dispatch(workflowActions.setSelectedPrescriptionMode(true));
              setPrescriptionMode(PrescriptionModeEnum.CMD);
            },
          }}
        />
      )
    );
  };

  const getLaterCard = () => {
    if (prescriptionOptions.prescriptionSendLaterEnabled) {
      return (
        configHasPrescription("LATER") && (
          <BrandComponent
            componentName="StepCard"
            key={"laterPrescriptionCard"}
            parameter={{
              title: translate("steps.advancedPrescription.later.card.title"),
              description: translate(
                "steps.advancedPrescription.later.card.description"
              ),
              imageUrl: "",
              id: "laterPrescriptionCard",
              noImageCard: true,
              onClick: () => {
                if (
                  prescriptionModule &&
                  typeof prescriptionModule.clearExtendedPrescription ===
                  "function"
                ) {
                  prescriptionModule.clearExtendedPrescription();
                  dispatch(prescriptionActions.setCurrentPrescription(null));
                  dispatch(prescriptionActions.setLoadedWithoutEdit(false));
                }
                workflow.goNextStep([
                  {
                    key: "advancedPrescription",
                    value: translate(
                      "steps.advancedPrescription.later.review.title"
                    ),
                  },
                ]);
              },
            }}
          />
        )
      );
    }
  };

  const getCardsWithCorrectOrder = () => {
    let cards = [
      {
        card: getManualInputCard(),
        order: prescriptionOptions?.prescriptionManualEnabled.order
          ? prescriptionOptions?.prescriptionManualEnabled.order
          : 1,
      },
      {
        card: getAccountCard(),
        order: prescriptionOptions?.prescriptionFromMyAccountEnabled.order
          ? prescriptionOptions?.prescriptionFromMyAccountEnabled.order
          : 1,
      },
      {
        card: getUploadCard(),
        order: prescriptionOptions?.prescriptionUploadEnabled.order
          ? prescriptionOptions?.prescriptionUploadEnabled.order
          : 1,
      },
      {
        card: getCMDCard(),
        order: prescriptionOptions?.prescriptionDoctorEnabled.order
          ? prescriptionOptions?.prescriptionDoctorEnabled.order
          : 1,
      },
      {
        card: getLaterCard(),
        order: prescriptionOptions?.prescriptionSendLaterEnabled.order
          ? prescriptionOptions?.prescriptionSendLaterEnabled.order
          : 1,
      },
    ].filter((card) => card.card);

    return orderBy(cards, "order").map((card) => card.card);
  };

  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const learnMoreButton = useTranslate(
    "steps.advancedPrescription.contentIdentifier"
  );
  const moreInfoLabel = useTranslate("steps.advancedPrescription.learnMoreCTA");
  if (
    !selectedPrescriptionMode &&
    prescriptionOptions &&
    prescriptionOptions.prescriptionEnabled
  ) {
    return (
      <BrandComponent
        componentName="StepContainer"
        parameter={{
          title: translate("steps.advancedPrescription.title"),
          subtitle:
            translate("steps.advancedPrescription.doctorLocator") !==
              "steps.advancedPrescription.doctorLocator"
              ? translate("steps.advancedPrescription.doctorLocator")
              : ctxMessage
                ? ctxMessage
                : "",
        }}
      >
        {learnMoreButton !== "steps.advancedPrescription.contentIdentifier" &&
          learnMoreButton !== undefined &&
          learnMoreButton !== null &&
          delegatingLearnMore &&
          delegateLearnMoreContent && (
            <a
              className="learnMoreButton"
              onClick={() => delegateLearnMoreContent(learnMoreButton)}
              data-element-id={
                (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
                "_LensPrescription-LearnMore"
              }
            >
              {moreInfoLabel !== "" &&
                moreInfoLabel !== undefined &&
                moreInfoLabel !== null &&
                moreInfoLabel !== "steps.advancedPrescription.learnMoreCTA"
                ? moreInfoLabel
                : "More information"}
            </a>
          )}
        <div className="AdvancedPresctiption__container">
          {getCardsWithCorrectOrder().map((c) => c)}
          <ReferencedSection
            key="reference"
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          />
        </div>
      </BrandComponent>
    );
  }

  return (
    <div>
      {isManualInput && (
        <BrandComponent
          componentName="StepContainer"
          parameter={{
            title: translate(
              withPrescriptionsFromMyAccount
                ? "steps.advancedPrescription.manual.editTitle"
                : rxType === "READER"
                  ? "steps.advancedPrescription.manual.title.reader"
                  : "steps.advancedPrescription.manual.title"
            ),
            subtitle:
              rxType === "READER"
                ? translate("steps.advancedPrescription.manual.subtitle.reader")
                : "",
          }}
        >
          <BrandComponent
            componentName="FullPrescriptionForm"
            parameter={{
              hideMoreOptions: prescriptionModule?.hideMoreOptions,
              currentPackages: currentPackages,
              onSuccessPrescription: handleSuccessPrescription,
              prescriptionObject: prescriptionObject,
              userIsLoggedIn: isLoggedIn(),
              withIssueDate: !!validTimeInMonth,
              rxType,
              withPrescriptionsFromMyAccount,
              isEditMode: withPrescriptionsFromMyAccount,
              noPrescriptionsFound: noPrescriptionsFound,
            }}
          />
          <ReferencedSection
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          />
        </BrandComponent>
      )}

      {isCallMyDoctor && (
        <BrandComponent
          componentName="StepContainer"
          parameter={{
            title: isDoctorSelected
              ? translate(
                "steps.advancedPrescription.doctor.selectedDoctorPageTitle"
              )
              : translate("steps.advancedPrescription.doctor.title"),
          }}
        >
          <BrandComponent
            parameter={{
              closePrescriptionForm,
              onSuccessPrescription: handleSuccessPrescription,
              changeTitleCallMyDoctor,
              prescriptionObject: prescriptionObject,
            }}
            componentName="CallMyDoctorPrescriptionForm"
          />
          <ReferencedSection
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          />
        </BrandComponent>
      )}

      {isUpload && (
        <BrandComponent
          componentName="StepContainer"
          parameter={{
            title: translate("steps.advancedPrescription.upload.title"),
            prescriptionObject: prescriptionObject,
          }}
        >
          <BrandComponent
            componentName="PrescriptionUploadForm"
            parameter={{
              closePrescriptionForm: closePrescriptionForm,
              onSuccessPrescription: handleSuccessPrescription,
              fileInput: file,
              prescriptionObject: prescriptionObject,
            }}
          />
          <ReferencedSection
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={
              isDesktop ? { paddingBottom: "20px" } : { paddingBottom: "5px" }
            }
          />
        </BrandComponent>
      )}

      {isFromMyAccount && (
        <BrandComponent
          componentName="StepContainer"
          parameter={{
            title: brand === "targetoptical" && isLoading ? "" : translate("steps.advancedPrescription.fromMyAccount.title"),
          }}
        >
          {brand === "targetoptical" && isLoading && <BrandComponent componentName="Loader" parameter={{
            style: { width: 40, height: 40, marginLeft: isMobile ? '150px' : '400px', marginTop: isMobile ? '250px' : '350px' },
          }} />}
          {brand === "targetoptical" && !isLoading && <BrandComponent
            componentName="SelectFromMyAccount"
            parameter={{
              isLoggedIn,
              prescriptions: prescriptionsFromMyAccount,
              login,
              onEdit: onEditPrescription,
              onSelect: onSelectPrescription,
              onLoggedIn,
              onNewPrescription,
              onWithoutPrescriptions,
            }}
          />}
          {brand !== "targetoptical" && <BrandComponent
            componentName="SelectFromMyAccount"
            parameter={{
              isLoggedIn,
              prescriptions: prescriptionsFromMyAccount,
              login,
              onEdit: onEditPrescription,
              onSelect: onSelectPrescription,
              onLoggedIn,
              onNewPrescription,
              onWithoutPrescriptions,
            }}
          />}
          {/* <ReferencedSection
            name={"ScrollView__" + workflow?.currentStep?.key}
            style={{ paddingBottom: "5px" }}
          /> */}
        </BrandComponent>
      )
      }
    </div >
  );
}
