import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import "./default.module.scss";
import HtmlParser from "html-react-parser";
import { BrandComponent } from "../common/BrandComponent";
import { TabPanel } from "../Tabs/components/TabPanel";
import "./default.module.scss";
import { useFullPrescriptionForm } from "@hooks";
import { prescriptionActions } from "../../redux/slices/prescription";
import { advancedManualPrescriptionAdapter } from "../../configurator/adapters/AdvancedManualPrescriptionAdapter";
import { useTranslate } from "../../configurator/translations/TranslationHooks";


interface PrescriptionModalOpt { }

export function HowToReadModal({ }: PrescriptionModalOpt) {
  const currentPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const {
    prescriptionTableColumns,
    formValues,
    formErrors,
    isAddDisabled,
    selectPlaceholder
  } = useFullPrescriptionForm(currentPrescription)
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const reduxDispatch = useDispatch();


  const SPH = useTranslate("steps.advancedPrescription.manual.sphere");
  const CYL = useTranslate("steps.advancedPrescription.manual.cylinder");
  const axis = useTranslate("steps.advancedPrescription.manual.axis");
  const add = useTranslate("steps.advancedPrescription.manual.add");

  const title = useTranslate("steps.advancedPrescription.manual.howToReadModal.title")
  const subtitle = useTranslate("steps.advancedPrescription.manual.howToReadModal.subtitle")
  const rigthEye = useTranslate("steps.advancedPrescription.manual.howToReadModal.rightEye.name")
  const leftEye = useTranslate("steps.advancedPrescription.manual.howToReadModal.leftEye.name")
  const sphere = useTranslate("steps.advancedPrescription.manual.howToReadModal.sphere.name")
  const cylinder = useTranslate("steps.advancedPrescription.manual.howToReadModal.cylinder.name")
  const rigthEyeDescription = useTranslate("steps.advancedPrescription.manual.howToReadModal.rightEye.description")
  const leftEyeDescription = useTranslate("steps.advancedPrescription.manual.howToReadModal.leftEye.description")
  const sphereDescription = useTranslate("steps.advancedPrescription.manual.howToReadModal.sphere.description")
  const cylinderDescription = useTranslate("steps.advancedPrescription.manual.howToReadModal.cylinder.description")
  const axisDescription = useTranslate("steps.advancedPrescription.manual.howToReadModal.axis.description")
  const addDescription = useTranslate("steps.advancedPrescription.manual.howToReadModal.add.description")
  const infoText = useTranslate("steps.advancedPrescription.manual.howToReadModal.infoText")
  const customerService = useTranslate("steps.advancedPrescription.manual.howToReadModal.customerService")

  const closeModal = () => {
    let prescription = advancedManualPrescriptionAdapter.adapt({
      ...currentPrescription,
      SPH_OD: formValues.prescription.right_eye_sph.value,
      SPH_OS: formValues.prescription.left_eye_sph.value,
      CYL_OD: formValues.prescription.right_eye_cyl.value,
      CYL_OS: formValues.prescription.left_eye_cyl.value,
      AX_OD: formValues.prescription.right_eye_axis.value,
      AX_OS: formValues.prescription.left_eye_axis.value,
      ADD_OD: formValues.prescription.right_eye_add.value,
      ADD_OS: formValues.prescription.left_eye_add.value,
    });
    if (
      isAddDisabled() &&
      prescription &&
      prescription.ADD &&
      (prescription.ADD.OD || prescription.ADD.OS)
    ) {
      prescription.ADD.OD = null;
      prescription.ADD.OS = null;
    }


    console.log(prescription)

    reduxDispatch(prescriptionActions.setCurrentPrescription(prescription))
    reduxDispatch(modalsActions.setShowPrescriptionModalHowToRead(false))
  }

  const onTabChange = (index) => {
    setCurrentTabIndex(index)
  }

  useEffect(() => {
    const close = (e) => {
      if (e.target.closest('.HowToReadModal, .PrescriptionModal__container__closeContainer')) {
        return
      }
      closeModal()
    }
    document.addEventListener('click', close)
    return () => {
      document.removeEventListener('click', close)
    }
  }, []);

  const modalRef = useRef(null);

  useEffect(() => {
    if (!modalRef.current) return;

    if (modalRef.current) {
      modalRef.current.focus();
    }

    const focusableElements = modalRef.current.querySelectorAll(
      'button, a, input, [tabindex="0"]'
    );

    if (focusableElements.length === 0) return;
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    const handleKeyDown = (e) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      } else if (e.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    firstElement.focus();

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closeModal]);

  return (
    <>
      <div className="PrescriptionModal__desktop">
        <div className="PrescriptionModal__overlay" />
        <div className="PrescriptionModal__container HowToReadModal" ref={modalRef} tabIndex={-1}>
          <div className="PrescriptionModal__container__closeContainer"><a onClick={closeModal} className="PrescriptionModal__container__close"></a></div>
          <h1 id="tabs-title" className="PrescriptionModal__container__title">{title}</h1>
          <p className="PrescriptionModal__container__subtitle">{subtitle}</p>

          {/* {desktop} */}

          <div className="PrescriptionModal__container__tabs desktop" >
            <BrandComponent componentName="Tabs" parameter={{ initActiveTabKey: "tab1", onTabChange: onTabChange }}>
              <TabPanel tabKey="tab1" label={`${rigthEye} / ${leftEye}`}>
                <p className="bold">{rigthEye}</p>
                <p>{rigthEyeDescription}</p>
                <p className="bold">{leftEye}</p>
                <p>{leftEyeDescription}</p>
              </TabPanel>
              <TabPanel tabKey="tab2" label={SPH}>
                <p className="bold">{sphere}</p>
                <p>{sphereDescription}</p>
              </TabPanel>
              <TabPanel tabKey="tab3" label={CYL}>
                <p className="bold">{cylinder}</p>
                <p>{cylinderDescription}</p>
              </TabPanel>
              <TabPanel tabKey="tab4" label={axis}>
                <p className="bold">{axis}</p>
                <p>{axisDescription}</p>
              </TabPanel>

              <TabPanel tabKey="tab5" label={add} hide={isAddDisabled()}>
                <p className="bold">{add}</p>
                <p>{addDescription}</p>
              </TabPanel>

            </BrandComponent>
            <div className="table">
              <BrandComponent componentName="FormTable" parameter={{
                columns: prescriptionTableColumns,
                selector: "prescription",
                cylOD: formValues?.prescription?.right_eye_cyl?.value,
                cylOS: formValues?.prescription?.left_eye_cyl?.value,
                isAddDisabled: isAddDisabled,
                onSubmit: () => { },
                formErrors: formErrors,
                selectPlaceholder: selectPlaceholder,
                formValues: formValues,
                samePrescriptionBothEyes: currentPrescription?.samePrescriptionBothEyes,
                highlightedColumnIndex: currentTabIndex - 1
              }}>

              </BrandComponent>
            </div>
          </div>

          <div className="infoText">{infoText}</div>
          <div className="customerService"><div className="icon"></div><div>{HtmlParser(customerService)}</div></div>
        </div>
      </div>
      <div className="PrescriptionModal__mobile">
        <div className="PrescriptionModal__overlay" />
        <div className="PrescriptionModal__container HowToReadModal">
          <div className="PrescriptionModal__container__closeContainer"><a onClick={closeModal} className="PrescriptionModal__container__close"></a></div>
          <h1 id="tabs-title" className="PrescriptionModal__container__title">{title}</h1>
          <p className="PrescriptionModal__container__subtitle">{subtitle}</p>

          {/* {mobile} */}

          <div className="PrescriptionModal__container__tabs mobile">
            <BrandComponent componentName="Tabs" parameter={{ initActiveTabKey: "tab1", onTabChange: () => { } }}>
              <TabPanel tabKey="tab1" label={`${rigthEye} / ${leftEye}`}>
                <p className="bold">{rigthEye}</p>
                <p>{rigthEyeDescription}</p>
                <p className="bold">{leftEye}</p>
                <p>{leftEyeDescription}</p>
              </TabPanel>
            </BrandComponent>
            <BrandComponent componentName="Tabs" parameter={{ initActiveTabKey: "tab2", onTabChange: () => { } }}>
              <TabPanel tabKey="tab2" label={SPH}>
                <p className="bold">{sphere}</p>
                <p>{sphereDescription}</p>
              </TabPanel>
            </BrandComponent>
            <BrandComponent componentName="Tabs" parameter={{ initActiveTabKey: "tab3", onTabChange: () => { } }}>
              <TabPanel tabKey="tab3" label={CYL}>
                <p className="bold">{cylinder}</p>
                <p>{cylinderDescription}</p>
              </TabPanel>
            </BrandComponent>
            <BrandComponent componentName="Tabs" parameter={{ initActiveTabKey: "tab4", onTabChange: () => { } }}>
              <TabPanel tabKey="tab4" label={axis}>
                <p className="bold">{axis}</p>
                <p>{axisDescription}</p>
              </TabPanel>
            </BrandComponent>
            {!isAddDisabled() && (
              <BrandComponent componentName="Tabs" parameter={{ initActiveTabKey: "tab5", onTabChange: () => { } }}>
                <TabPanel tabKey="tab5" label={add} hide={isAddDisabled()}>
                  <p className="bold">{add}</p>
                  <p>{addDescription}</p>
                </TabPanel>
              </BrandComponent>
            )}
          </div>
          <div className="infoText">{infoText}</div>
          <div className="customerService"><div className="icon"></div><div>{HtmlParser(customerService)}</div></div>
        </div>
      </div>
    </>
  );
}



