import { string } from "fp-ts";
import React from "react";

interface SwitchControlOpt {
  label?: string;
  name?: string;
  onChange?: (selected: boolean) => void;
  id?: string;
  checked?: boolean;
}


export function SwitchControl(props: SwitchControlOpt) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) props.onChange(event.target.checked);
  };

  return (
    <>
      <div className="SwitchControl_container">
        <label className="SwitchControl_label">
          <input
            type="checkbox"
            name={props.name}
            id={props.id || "df-sliderId"}
            onChange={handleChange}
            checked={props.checked}
            data-analytics_available_call="0"
          />
          <span className="slider round" />
        </label>
        <span className="two-pd-label">{props.label}</span>
      </div>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{ position: "absolute", width: "1px", height: "1px", overflow: "hidden" }}
      >
        {props.checked ? "switch on" : "switch off"}
      </div>
    </>
  );
}
