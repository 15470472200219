import React, { useEffect, useRef } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import { useCurrentBrand } from "@hooks";

export const IncompatibleLenses = () => {
  const incompatibleLensesLabel = useTranslate(
    "steps.advancedPrescription.manual.incompatibleLenses"
  );
  const incompatiblesLensesRef = useRef(null);
  useEffect(() => {
    if (incompatiblesLensesRef.current) {
      incompatiblesLensesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const brand = useCurrentBrand();
  return (
    <div className="IncompatibleLenses" ref={incompatiblesLensesRef}>
      {brand === "targetoptical" && (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <g clip-path="url(#clip0_6700_588377)">
            <path d="M8.6657 5.33353H7.33237V10.6669H8.6657V5.33353Z" fill="#AB5820" />
            <path d="M8.6657 12.0002H7.33237V13.3335H8.6657V12.0002Z" fill="#AB5820" />
            <path d="M15.7624 12.9162L9.78137 1.16786C9.41037 0.439529 8.7047 0.0751953 7.99904 0.0751953C7.29337 0.0751953 6.5877 0.439529 6.2167 1.16786L0.235704 12.9162C-0.482963 14.3275 0.54237 16.0002 2.12637 16.0002H13.872C15.4557 16.0002 16.481 14.3275 15.7624 12.9162ZM14.5437 14.2905C14.4744 14.4039 14.27 14.6669 13.8717 14.6669H2.12637C1.72804 14.6669 1.5237 14.4039 1.45437 14.2905C1.38504 14.1775 1.24337 13.8759 1.42404 13.5212L7.40504 1.77286C7.57237 1.4442 7.87604 1.40853 7.99904 1.40853C8.12204 1.40853 8.4257 1.44386 8.59304 1.77286L14.574 13.5212C14.7547 13.8762 14.613 14.1775 14.5437 14.2905Z" fill="#AB5820" />
          </g>
          <defs>
            <clipPath id="clip0_6700_588377">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>)}
      <div
        className={"IncompatibleLenses__text"}
        dangerouslySetInnerHTML={{ __html: incompatibleLensesLabel }}
      ></div>
    </div>
  );
};
