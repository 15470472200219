import React, { useEffect, useRef } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import { useCurrentBrand, useCurrentType } from "@hooks";

export const IncompatibleFrame = (props: {
  withClearAll?: boolean;
  withSelectNewFrame?: string;
  onClick?: () => void;
}) => {
  const incompatibleFrameLabel = useTranslate(
    "steps.advancedPrescription.manual.incompatibleFrame"
  );
  const clearAllLabel = useTranslate(
    "steps.advancedPrescription.manual.clearAll"
  );
  const selectNewFrameLabel = useTranslate(
    "steps.advancedPrescription.manual.selectNewFrame"
  );
  const incompatiblesFrameRef = useRef(null);
  const brand = useCurrentBrand();
  useEffect(() => {
    if (incompatiblesFrameRef.current) {
      incompatiblesFrameRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div className="IncompatibleFrame" ref={incompatiblesFrameRef}>
      {brand === "targetoptical" &&
        (<div style={{ display: "flex", gap: "16px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M8.66668 5.33353H7.33335V10.6669H8.66668V5.33353Z" fill="#AB5820" />
            <path d="M8.66668 12.0002H7.33335V13.3335H8.66668V12.0002Z" fill="#AB5820" />
            <path d="M15.7633 12.9162L9.78235 1.16786C9.41135 0.439529 8.70568 0.0751953 8.00001 0.0751953C7.29435 0.0751953 6.58868 0.439529 6.21768 1.16786L0.23668 12.9162C-0.481986 14.3275 0.543347 16.0002 2.12735 16.0002H13.873C15.4567 16.0002 16.482 14.3275 15.7633 12.9162ZM14.5447 14.2905C14.4753 14.4039 14.271 14.6669 13.8727 14.6669H2.12735C1.72901 14.6669 1.52468 14.4039 1.45535 14.2905C1.38601 14.1775 1.24435 13.8759 1.42501 13.5212L7.40601 1.77286C7.57335 1.4442 7.87701 1.40853 8.00001 1.40853C8.12301 1.40853 8.42668 1.44386 8.59401 1.77286L14.575 13.5212C14.7557 13.8762 14.614 14.1775 14.5447 14.2905Z" fill="#AB5820" />
          </svg>
          <div
            className="IncompatibleFrame__text"
            dangerouslySetInnerHTML={{ __html: incompatibleFrameLabel }}
          ></div></div>)}
      {brand !== "targetoptical" && (<div
        className="IncompatibleFrame__text"
        dangerouslySetInnerHTML={{ __html: incompatibleFrameLabel }}
      ></div>)}
      {props.onClick && (
        <div className="IncompatibleFrame__button" onClick={props.onClick}>
          {props.withClearAll ? clearAllLabel : selectNewFrameLabel}
        </div>
      )}
    </div>
  );
};
