import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";
import { useCurrentBrand } from "@hooks";

export function useEnableZeroSphere() {
  const enableZeroSphereCookie = getCookieByName("enableZeroSphere", true);
  const enableZeroSphere = useSelector(
    (state: any) => state.config.prescriptionModule?.enableZeroSphere
  );
  const currentBrand = useCurrentBrand();

  if (enableZeroSphereCookie !== null) {
    return enableZeroSphereCookie as boolean;
  }

  if (enableZeroSphere !== null && enableZeroSphere !== undefined) {
    return enableZeroSphere as boolean;
  }

  if (currentBrand === "clearly") {
    return true;
  }

  return false;
}
