import React, { useEffect, useRef } from "react";
import "./default.module.scss";
import { ReactNode } from "react";
import { BrandComponent } from "../common/BrandComponent";
import { StepTitleProps } from "../StepTitle/component";
import { workflow } from "../../configurator/models/Workflow";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { useSelector } from "react-redux";
import { useCurrentType } from "../../configurator/hooks";

interface StepContainerProps extends StepTitleProps {
  children?: ReactNode;
  subtitleStyle?: any;
  paddingNeeded?: boolean;
  showStepTitle?: boolean;
}

/**
 * Wrapper component that contains a step. It accepts an input of type ```StepContainerProps```
 * extending the interface ```StepTitleProps``` with the following parameter:
 *  - ```children``` (_optional_): the step you want to display
 */
export function StepContainer({
  children,
  title,
  titleMobile,
  subtitle,
  subtitleStyle,
  slider,
  pills,
  pillsSubtitle,
  brandCallback,
  pillsCallback,
  pillSelected,
  showGrayOutBanner,
  paddingNeeded,
  showStepTitle,
}: StepContainerProps) {
  // const firstFocusableRef = useRef<HTMLDivElement>(null);
  // const lastFocusableRef = useRef<HTMLDivElement>(null);

  const currentStep = useSelector(
    (state: any) => state.workflow.currentStep
  );
  const getDataElementId = () => {
    let currentStepName = currentStep?.key;
    let analyticsStepName = getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName;
  };

  const currentType = useCurrentType();

  // useEffect(() => {
  //   const handleTabKey = (event: KeyboardEvent) => {
  //     if (event.key === "Tab") {
  //       if (event.shiftKey) {
  //         if (document.activeElement === firstFocusableRef.current) {
  //           event.preventDefault();
  //           lastFocusableRef.current?.focus();
  //         }
  //       } else {
  //         if (document.activeElement === lastFocusableRef.current) {
  //           event.preventDefault();
  //           firstFocusableRef.current?.focus();
  //         }
  //       }
  //     }
  //   };

  //   document.addEventListener("keydown", handleTabKey);
  //   return () => {
  //     document.removeEventListener("keydown", handleTabKey);
  //   };
  // }, []);

  return (
    <div
      className={"StepContainer__container"}
      id="step-container"
      data-element-id={getDataElementId()}
      style={paddingNeeded ? { paddingBottom: (currentType === "SUNGLASSES") ? "120px" : "220px" } : {}}
    // ref={firstFocusableRef}
    >
      {
        <BrandComponent
          componentName="StepTitle"
          parameter={{
            title,
            titleMobile,
            subtitle,
            subtitleStyle,
            slider,
            pills,
            pillsSubtitle,
            brandCallback,
            pillsCallback,
            pillSelected,
            showGrayOutBanner,
            showStepTitle,
          }}
        />
      }
      {children}
      {/* <div ref={lastFocusableRef} tabIndex={0} /> */}
    </div>
  );
}
