export const sendMonetateEvent = (
  currentStepKey: string,
  prescriptionFlow?: string
) => {
  let rxcEventObject = {
    detail: {
      name: currentStepKey,
    },
  };
  if (currentStepKey === "AdvancedPrescription" && prescriptionFlow) {
    rxcEventObject = {
      detail: {
        name: currentStepKey,
        flow: prescriptionFlow,
      },
    };
  }
  const rxcEvent = new CustomEvent("RXC-ActiveStep", rxcEventObject);

  //console.log("Monetate eventObject: ", rxcEventObject)

  window.dispatchEvent(rxcEvent);
};
