import getCookieByName from "../utils/getCookieByName";
import { useSelector } from "react-redux";

export function useShowPolarToggle() {
  const showPolarToggleCookie = getCookieByName("showPolarToggle", false);
  const showPolarToggle = useSelector(
    (state: any) => state.config.layoutSettings?.showPolarToggle
  );

  if (showPolarToggleCookie !== null && showPolarToggleCookie !== undefined) {
    return showPolarToggleCookie as string;
  }

  if (showPolarToggle !== null && showPolarToggle !== undefined) {
    return showPolarToggle as string;
  }

  return true;
}
