import React, { useEffect } from 'react'
import "./default.module.scss"
import Image from '../common/Image'
import { useDispatch, useSelector } from 'react-redux';
import { BrandComponent } from '../common/BrandComponent';
import { useTranslate, useTranslation } from '../../configurator/translations/TranslationHooks';
interface LensCard {
  lensPackage: any,
  selected: boolean,
  onClick: (lens) => void,
  discountLoading: boolean,
}

export function LensCard({ lensPackage, selected, onClick, discountLoading }: LensCard) {
  const colorSelected = useSelector((state: any) => state.color?.colorSelected);
  const polarized = useTranslate("steps.mixAndMatch.polarized");
  const configProjectImage = useSelector(
    (state: any) => state.config?.baseURLs?.projectImage
  );
  const handleClick = (lens) => {
    onClick?.(lens)
  }

  useEffect(() => {
    if (colorSelected === lensPackage.name) return
    if (selected) {
      handleClick(lensPackage)
    }
  }, [selected, lensPackage, colorSelected])

  const changePriceStyle = {
    color: "#222222",
    fontSize: "14px",
    fontWeight: "normal",
  };
  const changeDiscountStyle = {
    color: "#222222",
    fontSize: "12px",
    fontWeight: "normal",
  };

  const discountPercentage = Number((lensPackage.discount / lensPackage.price * 100).toFixed())

  return (
    <button
      className={`LensCard__container ${selected ? "selected" : ""}`}
      onClick={() => handleClick(lensPackage)}
    >
      {discountPercentage > 0 && <span className="LensCard__discount">{discountPercentage}% OFF</span>}
      <div className="LensCard__color">
        <Image
          imageClass="LensCard__color__img"
          url={configProjectImage}
          fileName={lensPackage.imageFileName ? lensPackage.imageFileName : lensPackage.value}
          alt={lensPackage.name}
          id={lensPackage.value}
        />
      </div>
      <span className="LensCard__name">{lensPackage?.title}
        {lensPackage.polar && (
          <span data-translation="steps.mixAndMatch.polarized" className='LensCard__polarized'>{polarized}</span>
        )}
      </span>
      <BrandComponent
        componentName='StepPrice'
        parameter={{
          price: lensPackage.price,
          discount: lensPackage.discount ? lensPackage.discount : null,
          insPrice: lensPackage.insPrice,
          discountLoading: discountLoading,
          priceStyle: changePriceStyle,
          strikePriceStyle: changeDiscountStyle,
        }}
      />
    </button>
  )
}
