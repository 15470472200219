import React from "react";
import { useSelector } from "react-redux";
import { useCurrentType } from "./useCurrentType";

const useLensAdvisor = () => {
  const lensAdvisorModule = useSelector(
    (state: any) => state.config?.lensAdvisorModule
  );
  const laExperience = useSelector(
    (state: any) => state.config?.lensAdvisorModule?.laExperience
  );
  const currentType = useCurrentType();

  if (
    lensAdvisorModule &&
    laExperience === true &&
    currentType !== "SUNGLASSES"
  ) {
    return true;
  }
};

export default useLensAdvisor;
