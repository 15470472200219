import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export function useDefaultLogoIcons() {
  const [logo, setLogo] = useState("");
  const brandModel = useSelector(
    (state: any) => state.config?.data?.frame?.model
  );
  const baseURLs = useSelector(
    (state: any) => state.config?.baseURLs?.assetsCDN
  );
  const getFrameCode = (frameModel: string) => {
    return frameModel?.substring(0, 3);
  };
  const getDefaultLogo = async (code: string, baseURLs: string) => {
    const url = baseURLs + "/extra/image/rxc/icons/logo/model2logo.json";
    try {
      const response = await axios.get(url);
      const brand = response.data[code];
      if (brand) {
        // try {
        //   let logoUrl = await axios.get(
        //     baseURLs + "/extra/image/rxc/icons/logo/" + brand + ".png"
        //   );
        //   if (logoUrl) {
        //     setLogo(baseURLs + "/extra/image/rxc/icons/logo/" + brand + ".png");
        //   }
        // } catch (error) {
        //   window.alert("CIAOOO" + error);
        // }
        setLogo(baseURLs + "/extra/image/rxc/icons/logo/" + brand + ".png");
      }
    } catch (error) {
      console.log(error);
    }
    //chiamata a https://<baseURLs.assetsCDN>/extra/image/rxc/icons/logo/model2logo.json
  };

  useEffect(() => {
    const frameCode = getFrameCode(brandModel);
    getDefaultLogo(frameCode, baseURLs);
  }, [brandModel, baseURLs]);

  return logo;
}
